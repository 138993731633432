import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import PanelHeading from "./PropertyReportsChildComponents/PanelHeading";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";
import PropertySearchComponent from "./PropertyReportsChildComponents/PropertySearchComponent";
import PreviousReportsComponent from "./PropertyReportsChildComponents/PreviousReportsComponent";
import apiAgent from "../../../app/api/apiAgent";
import PropertyReportsStore from "../../../app/stores/propertyReportsStore";
import { useStore } from "../../../app/stores/store";

const PropertyReportsComponent: React.FC = observer(() => {
  const [activeTabID, setActiveTabID] = useState("propertySearch");
  const [existingReports, setExistingReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [propertyReportsStore] = useState(() => new PropertyReportsStore());

  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  const tabContent = [
    {
      link: "#propertySearch",
      title: "Search",
    },
    {
      link: "#previousReports",
      title: "Purchased Reports",
      showSpinner: propertyReportsStore.isReportGenerating,
    },
  ];

  const setPropertiesInStore = (properties: any) => {
    propertyReportsStore.propertyReports = properties;
  };

  const fetchExistingReports = () => {
    setIsLoading(true);
    apiAgent.PropertyReports.getExistingReportsForUser()
      .then((response) => {
        setExistingReports(response);
        setPropertiesInStore(response);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchExistingReports();
  }, []);

  return (
    <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
      <PanelHeading />
      <div className="panel-body table-responsive center-align">
        <OMSTabBox id="propertyReportTabs" value={tabContent} setActiveTabId={setActiveTabID}></OMSTabBox>
        <div className="tab-content">
          <div id="propertySearch" className={activeTabID === "propertySearch" ? "tab-pane fade in active" : "tab-pane fade"}>
            <PropertySearchComponent propertyReportsStore={propertyReportsStore} />
          </div>
          <div id="previousReports" className={activeTabID === "previousReports" ? "tab-pane fade in active" : "tab-pane fade"}>
            <PreviousReportsComponent isLoading={isLoading} propertyReportsStore={propertyReportsStore} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PropertyReportsComponent;
