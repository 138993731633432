import React, { useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";
import HeaderWrapper from "../../header/HeaderWrapper";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";

export default function P24RefUpdate() {

    const [webRef, setWebRef] = useState();
    const [P24Ref, setP24Ref] = useState("");
    const [processing, setprocessing] = useState(false);

    let history = useHistory();

    const onCancelClick = (e) => {
        history.push("/Settings");
      };

      const onPerformClick = (e) => {
        if (webRef) {
          console.log(webRef);
          setprocessing(true);
          apiAgent.Admin.changeP24ReferenceAjax(webRef, P24Ref)
          .then((data) => {
              toast.info(data.message);
              console.log("data", data)
          })
          .catch((err) => {
              console.log(err);
          });
      
          setprocessing(false);
        } else {
          toast.info("Please enter WebRef");
        }
      };

  return (
    <>
      <HeaderWrapper />

      <div className="container-fluid">
        <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Change P24 Reference" },
            ]}
          />
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-sm-8">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <label>Change P24 Reference</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="WebRef">
                    WebRef
                    </label>
                    <input
                      type="number"
                      name="WebRef"
                      className="form-control required-control text-350"
                      value={webRef}
                      onChange={(e) => setWebRef(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="P24Ref">P24Ref</label>
                    <input
                      type="text"
                      name="P24Ref"
                      className="form-control text-350"
                      value={P24Ref}
                      onChange={(e) => setP24Ref(e.target.value)}
                    />
                  </div>

                  <div className="form-group bg-warning">
                    <p style={{padding: "5px"}}>
                      <b>Note : </b>This utility will update or remove `p24Ref` entries from the `Base` table and the `SyncPortalListingRef` table. 
                      <br/>
                      If `p24Ref` is empty, it will unlink the existing `p24Ref` entries from the listing and also remove them from the `SyncPortalListingRef` table.
                    </p>
                  </div>


                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={false}
                        onClick={onCancelClick}
                      />
                      <OMSButtonStyleL
                        type="button"
                        ui_icon="fa-check"
                        ui_type="save"
                        ui_text="Change"
                        ui_processing_text="Changing.."
                        processing={processing}
                        disabled={processing}
                        onClick={onPerformClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  )
}
