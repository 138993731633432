import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "cropperjs/dist/cropper";
//import Cropper  from 'cropperjs';
//import { ReactCropper } from "./react-cropper";
import defaultImage from "../../../assets/images/img-upload-default.png";
import Compressor from 'compressorjs';
//const Compressor = require("compressorjs").Compressor;

export class OMSImageUpload extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    onCropSubmit: PropTypes.func,
    cropBoxResizable: PropTypes.bool,
  };

  state = {
    Src: this.props.src,
    cropperElement: null,
    CropSrc: null,
    IsCropper: "0",
    mPoint: 3,
  };

  onImageUploadChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    var uploadedFile = files[0];
    var fileSize = uploadedFile.size / 1024; // in Kb
    var quality = 1;

    if (fileSize <= 400) quality = 1;
    else if (fileSize > 400 && fileSize <= 600) quality = 0.85;
    else if (fileSize > 600 && fileSize <= 800) quality = 0.78;
    else if (fileSize > 800 && fileSize <= 1000) quality = 0.71;
    else if (fileSize > 1000 && fileSize <= 1800) quality = 0.65;
    else if (fileSize > 1800 && fileSize <= 2500) quality = 0.6;
    else quality = 0.5;

    if (this.props.compressor && quality < 1) {
      new Compressor(uploadedFile, {
        quality: quality,
        convertSize: 2500000,
        success: function (result) {
          fileSize = result.size / 1024; // in Kb
          var reader = new FileReader();
          reader.onload = this.OnImageUploadFileReaderLoad;
          reader.readAsDataURL(result);
        }.bind(this),
        error: function (err) {
          //console.log(err.message);
          var reader = new FileReader();
          reader.onload = this.OnImageUploadFileReaderLoad;
          reader.readAsDataURL(uploadedFile);
        }.bind(this),
      });
    } else {
      var reader = new FileReader();
      reader.onload = this.OnImageUploadFileReaderLoad;
      reader.readAsDataURL(uploadedFile);
    }
  };

  OnImageUploadFileReaderLoad = (e) => {
    var mPoint = 3;
    if (e.total !== undefined) {
      var f = e.total / (1024 * 1024);
      if (isNaN(f) || f < 1) mPoint = 3;
      else if (f < 2) mPoint = 4;
      else if (f < 3) mPoint = 5;
      else if (f < 4) mPoint = 6;
      else mPoint = 10;
    }

    window.$("#imageUploadModal").modal("show");
    window
      .$("#imageUploadModal")
      .off("shown.bs.modal")
      .on(
        "shown.bs.modal",
        function () {
          this.setState({
            CropSrc: e.target.result,
            IsCropper: "1",
            mPoint: mPoint,
          });
        }.bind(this)
      );
  };

  OnCropImageReady = (e) => {
    if (typeof this.cropper === "undefined") {
      return;
    }
    this.cropper.setCropBoxData({
      width: this.props.width,
      height: this.props.height,
      maxWidth: this.props.width,
      maxHeight: this.props.height,
    });
  };

  OnCropImage = (e) => {
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    var mPoint = this.state.mPoint;
    if (isNaN(mPoint)) mPoint = 2;

    //var myDataURL = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    //var myDataURL = this.cropper.getCroppedCanvas({ width: 350, height: 100, fillColor: '#FFFFFF'}).toDataURL('image/png',0.9);
    var width = this.props.width * mPoint;
    var height = this.props.height * mPoint;
    if (this.props.cropBoxResizable) {
      var cropBoxSize = this.cropper.getCropBoxData();
      if (cropBoxSize) {
        width = cropBoxSize.width;
        height = cropBoxSize.height;
      }
    }

    var myDataURL = this.cropper
      .getCroppedCanvas({
        width: width,
        height: height,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high",
        fillColor: "#FFFFFF",
      })
      .toDataURL("image/jpeg", 1);

    this.setState({ Src: myDataURL, CropSrc: null, IsCropper: "0" });

    window.$("#imageUploadModal").modal("hide");
    if (this.props.onCropSubmit !== undefined) this.props.onCropSubmit(myDataURL);
  };

  onCropZoom = (e) => {
    if (this.cropper === undefined) {
      return;
    }

    var zoom = e.target.getAttribute("data-item");
    if (zoom !== undefined && zoom != null) {
      this.cropper.zoom(zoom);
    }
  };

  onCropMove = (e) => {
    if (this.cropper === undefined) {
      return;
    }
    var X = e.target.getAttribute("data-x");
    var Y = e.target.getAttribute("data-y");
    if (X !== undefined && X != null && Y !== undefined && Y != null) {
      this.cropper.move(X, Y);
    }
  };

  handleChangePhoto = (e) => {
    window.$("#uploadFile").click();
  };

  render() {
    var myCropperHtml;
    if (this.state.IsCropper === "1") {
      myCropperHtml = (
        <Cropper
          src={this.state.CropSrc}
          guides={false}
          viewMode={0}
          dragMode={"move"}
          restore={false}
          highlight={false}
          cropBoxMovable={true}
          cropBoxResizable={this.props.cropBoxResizable === undefined ? false : this.props.cropBoxResizable}
          ready={this.OnCropImageReady}
          onInitialized={(cropper) => {
            this.cropper = cropper;
          }}
        />
      );
    }

    return (
      <div>
        <div
          className="img-upload-container"
          style={{
            maxWidth: this.props.width,
            maxHeight: this.props.height + 8,
            border: "1px solid #ddd",
          }}
        >
          <img
            src={this.state.Src != "" ? this.state.Src : defaultImage}
            className={this.state.Src != "" ? "img-thumbnail img-upload" : "img-thumbnail img-upload-empty"}
            alt=""
            style={{
              maxWidth: this.props.width,
              maxHeight: this.props.height,
              width: "100%",
              objectFit: "contain",
            }}
          />
          <div className={this.state.Src != "" ? "img-upload-middle" : "img-upload-middle-empty"}>
            <div className="img-upload-middle-text" onClick={this.handleChangePhoto}>
              {this.state.Src != "" ? "Change Photo" : "Upload Photo"}
            </div>
          </div>
        </div>
        {/* <img src={this.state.Src} className="img-thumbnail" alt="" style={{ width: this.props.width, height: this.props.height }} />   <br />
                <br /> */}
        <input
          type="file"
          id="uploadFile"
          name="uploadFile"
          accept="image/gif, image/jpeg, image/png"
          style={{ display: "none" }}
          onChange={this.onImageUploadChange}
        />
        <div className="modal fade" id="imageUploadModal" tabIndex={-1} role="dialog" aria-labelledby="imageUploadModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="imageUploadModalLabel">
                  Crop Picture
                </h4>
              </div>
              <div className="modal-body">
                <br />
                <div className="cropper-parent-container">{myCropperHtml}</div>
                <br />
              </div>
              <div className="modal-footer">
                <div className="col-md-8 col-sm-12">
                  <div className="btn-group pull-left" style={{ marginRight: "10px" }}>
                    <button
                      type="button"
                      className="btn btn-primary btn-image-upload"
                      title="Zoom In"
                      data-item="0.1"
                      style={{ fontSize: "14px" }}
                      onClick={this.onCropZoom}
                    >
                      <span className="fa fa-search-plus" data-item="0.1"></span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-image-upload"
                      title="Zoom Out"
                      data-item="-0.1"
                      style={{ fontSize: "14px" }}
                      onClick={this.onCropZoom}
                    >
                      <span className="fa fa-search-minus" data-item="-0.1"></span>
                    </button>
                  </div>
                  <div className="btn-group pull-left" style={{ marginRight: "10px" }}>
                    <button
                      type="button"
                      className="btn btn-primary btn-image-upload"
                      title="Move Left"
                      style={{ fontSize: "14px" }}
                      data-x="-10"
                      data-y="0"
                      onClick={this.onCropMove}
                    >
                      <span className="fa fa-arrow-left" data-x="-10" data-y="0"></span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-image-upload"
                      title="Move Right"
                      style={{ fontSize: "14px" }}
                      data-x="10"
                      data-y="0"
                      onClick={this.onCropMove}
                    >
                      <span className="fa fa-arrow-right" data-x="10" data-y="0"></span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-image-upload"
                      title="Move Up"
                      style={{ fontSize: "14px" }}
                      data-x="0"
                      data-y="-10"
                      onClick={this.onCropMove}
                    >
                      <span className="fa fa-arrow-up" data-x="0" data-y="-10"></span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-image-upload"
                      title="Move Down"
                      style={{ fontSize: "14px" }}
                      data-x="0"
                      data-y="10"
                      onClick={this.onCropMove}
                    >
                      <span className="fa fa-arrow-down" data-x="0" data-y="10"></span>
                    </button>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 pull-right">
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={this.OnCropImage}>
                    Crop
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
