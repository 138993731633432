import React from "react";
import PropTypes from "prop-types";
import { OMSTwitterContactAutocomplete } from "../../../../app/common/common-components/OMSAutocomplete";
import { OMSTagDropdown } from "../../../../app/common/common-components/OMSMultiselectTagDropdown";
import { OMSSaveContact } from "../../../../app/common/common-components/OMSSaveContact";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default class DealContactsStepView extends React.Component {
  static propTypes = {
    DealID: PropTypes.string,
    OfficeID: PropTypes.string,
    UserID: PropTypes.string,
  };

  static defaultProps = {
    DealID: "",
  };

  constructor(props) {
    super(props);
    this.saveContactModalRef = React.createRef();
  }

  getStateFromData = (data) => {
    //console.log(data);
    return;
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.Contacts !== prevProps.Contacts) {
      this.setState({ Contacts: this.props.Contacts });
    }
  }

  doSetFormData = (data) => {
    // data.append('Contacts.PropertyID', this.state.PropertyID);
    // data.append('Contacts.MandateID', this.state.MandateID);

    // for (var i = 0; i < this.state.Contacts.length; i++) {
    //     var contactItem = this.state.Contacts[i];
    //     data.append('Contacts.Contacts[' + i + '].ID', contactItem.id);
    //     data.append('Contacts.Contacts[' + i + '].Type', contactItem.type);
    //     data.append('Contacts.Contacts[' + i + '].ContactType', contactItem.contactType);
    //     data.append('Contacts.Contacts[' + i + '].ContactTypeColor', contactItem.contactTypeColor);
    //     data.append('Contacts.Contacts[' + i + '].DisplayName', contactItem.displayName);
    // }

    const contactsData = {
      MandateID: this.state.MandateID,
      PropertyID: this.state.PropertyID,
      Contacts: this.state.Contacts.map((contactItem, i) => {
        return {
          ID: contactItem.id,
          Type: contactItem.type,
          ContactType: contactItem.contactType,
          ContactTypeColor: contactItem.contactTypeColor,
          DisplayName: contactItem.displayName,
        };
      }),
    };

    data.append("ContactsJson", JSON.stringify(contactsData));
    //data.append('Contacts', contactsData);
  };

  doCheckFormData = (data) => {
    var result = { status: "error", message: "Fields are required." };
    var resultSuccess = { status: "success", message: "" };
    if (this.props.ListingSellerRequired) {
      var contacts = this.state.Contacts;
      //console.log("inside doCheckFormData, contacts=", contacts);
      var index = -1;
      for (var i = 0; i < contacts.length; i++) {
        if (contacts[i].type == "2") {
          //&& contacts[i].ContactType == "seller"
          index = i;
          break;
        }
      }
      if (index == -1) {
        result.message = "Seller details required for mandate";
        return result;
      }
    }

    return resultSuccess;
  };

  doSetState = (data) => {
    this.setState(this.getStateFromData(data));
  };

  doGetState = () => {
    return this.state;
  };

  ToString = (value) => {
    if (value === undefined || value == null) return "";
    else return value;
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onContactChange = (e) => {
    // this.state.SelectedContact !== undefined && this.state.SelectedContact.Type == 1
    //console.log("inside onContactChange, e=", e);
    //console.log("inside onContactChange, this.state.SelectedContact=", this.state.SelectedContact);

    this.setState({ SelectedContact: e, SelectedContactTag: "" });
  };

  onContactTagChange = (e) => {
    //console.log("inside onContactTagChange, e=", e);
    this.setState({ SelectedContactTag: e });
  };

  onAddContactClick = (e) => {
    //console.log("inside onAddContactClick");
    //console.log("inside onAddContactClick, this.state.Contacts=", this.state.Contacts);
    //console.log("inside onAddContactClick, this.state.SelectedContact=", this.state.SelectedContact);

    if (this.state.SelectedContact && this.state.SelectedContactTag) {
      var contactItem = {
        id: this.state.SelectedContact.id,
        type: this.state.SelectedContact.type,
        displayName: this.state.SelectedContact.displayName,
        contactType: this.state.SelectedContactTag,
        contactTypeColor: "",
      };

      //console.log("inside onAddContactClick, this.state.SelectedContact=", this.state.SelectedContact);
      if (this.state.SelectedContact.type == 1) {
        for (var i = 0; i < this.props.AgentTypes.length; i++) {
          if (this.props.AgentTypes[i].name == contactItem.contactType) {
            contactItem.contactTypeColor = this.props.AgentTypes[i].color;
            break;
          }
        }
      } else {
        for (var i = 0; i < this.props.OfficeContactTags.length; i++) {
          if (this.props.OfficeContactTags[i].name == contactItem.contactType) {
            contactItem.contactTypeColor = this.props.OfficeContactTags[
              i
            ].color;
            break;
          }
        }
      }
      var contacts = this.state.Contacts;
      const isContactExist = contacts.findIndex((curContact) => {
        return (
          curContact.id == this.state.SelectedContact.id &&
          curContact.contactType == this.state.SelectedContactTag
        );
      });
      //console.log(isContactExist);
      if (isContactExist != -1) {
        return toast.info(
          `This contact with tag:"${this.state.SelectedContactTag}" already exist`
        );
      }
      contacts.push(contactItem);
      this.setState({ Contacts: contacts });
      if (this.props.onChange !== undefined) this.props.onChange(contacts);
    }
  };

  onRemoveContactClick = (id, type) => {
    var contacts = this.state.Contacts;
    var index = -1;
    for (var i = 0; i < contacts.length; i++) {
      if (contacts[i].id == id && contacts[i].type == type) {
        index = i;
        break;
      }
    }
    if (index > -1) {
      contacts.splice(index, 1);
      this.setState({ Contacts: contacts });
      this.props.onChange(contacts);
    }
  };

  onAddNewContactClick = (e) => {
    //console.log("inside onAddNewContactClick");
    this.saveContactModalRef.current.onOpenContact();
  };

  onSuccessContact = (e) => {
    //console.log("inside onSuccessContact, e=", e);
    if (e.contactID && e.contactName && e.ContactTag) {
      var contactItem = {
        id: e.contactID,
        type: 2,
        displayName: e.contactName,
        contactType: e.ContactTag,
        contactTypeColor: "",
      };
      for (var i = 0; i < this.props.OfficeContactTags.length; i++) {
        if (this.props.OfficeContactTags[i].name == e.ContactTag) {
          contactItem.contactTypeColor = this.props.OfficeContactTags[i].color;
          break;
        }
      }
      var contacts = this.state.Contacts;
      contacts.push(contactItem);
      this.setState({ Contacts: contacts });
    }
  };

  state = { Contacts: [] };

  render() {
    var self = this;
    var accessType = this.props.accessType;
    var AgentTypes = this.props.AgentTypes;
    var OfficeContactTags = this.props.OfficeContactTags;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <br />
            {this.state.Contacts?.map(function (item, index) {
              let contactTagColor = "gray";
              if (item.type == 1) {
                AgentTypes.map((curEle) => {
                  if (curEle.name == item.contactType && curEle.color) {
                    contactTagColor = curEle.color;
                  }
                });
              } else if (item.type == 2) {
                OfficeContactTags.map((curEle) => {
                  if (curEle.name == item.contactType && curEle.color) {
                    contactTagColor = curEle.color;
                  }
                });
              }
              return (
                <div key={index} className="form-group">
                  {accessType == 1 && (
                    <span
                      onClick={() =>
                        self.onRemoveContactClick(item.id, item.type)
                      }
                      style={{ marginRight: 5, cursor: "pointer" }}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </span>
                  )}

                  <span
                    className="btn btn-my-tag-grid-label"
                    style={{ backgroundColor: contactTagColor }}
                  >
                    {item.contactType}
                  </span>
                  {/* <a
                    href={item.type == "1" ? "/Settings/SaveUser?ID=" + item.id : "/Contacts/SaveContact?ID=" + item.id}
                    target="_blank"
                    style={{ textDecoration: "underline", marginLeft: 5, cursor: "pointer" }}
                  >
                    {item.displayName}
              </a> */}
                  <Link
                    to={
                      item.type == "1"
                        ? `/Settings/SaveUser?ID=${item.id}`
                        : `/Contacts/SaveContact?ID=${item.id}`
                    }
                    target="_blank"
                    style={{
                      textDecoration: "underline",
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                  >
                    {item.displayName}
                  </Link>
                </div>
              );
            })}
          </div>
          {accessType == 1 && (
            <div className="col-md-12">
              <br />
              <div className="form-group">
                <label>
                  Link existing contact Or{" "}
                  <a onClick={this.onAddNewContactClick}>add new contact</a>
                </label>
                <div>
                  <div className="form-inline">
                    <div className="form-group" style={{ marginRight: 10 }}>
                      <OMSTwitterContactAutocomplete
                        officeid={this.props.OfficeID}
                        id="searchContact"
                        name="searchContact"
                        style={{ width: 250 }}
                        value={""}
                        onChange={this.onContactChange}
                      />
                    </div>
                    <div
                      className="form-group tag-dropdown"
                      style={{ marginRight: 10 }}
                    >
                      {this.state.SelectedContact !== undefined &&
                      this.state.SelectedContact.type == 1 ? (
                        <OMSTagDropdown
                          id="agentTypes"
                          name="agentTypes"
                          array1={[
                            ...this.props.AgentTypes,
                            ...this.props.OfficeContactTags,
                          ]}
                          array2={[]}
                          value={this.state.SelectedContactTag}
                          onChange={this.onContactTagChange}
                        />
                      ) : (
                        <OMSTagDropdown
                          id="tags"
                          name="tags"
                          array1={this.props.OfficeContactTags}
                          array2={[]}
                          value={this.state.SelectedContactTag}
                          onChange={this.onContactTagChange}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <button
                        className={
                          this.state.SelectedContact &&
                          this.state.SelectedContactTag
                            ? "btn btn-success-oms"
                            : "btn btn-success-oms disabled"
                        }
                        onClick={this.onAddContactClick}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <OMSSaveContact
                ref={this.saveContactModalRef}
                ID="Listing"
                userID={this.props.UserID}
                OfficeDefaultTags={this.props.OfficeContactTags}
                UserDefinedTags={this.props.UserContactTags}
                ContactSourceTypes={this.props.ContactSourceTypes}
                onSuccess={this.onSuccessContact}
                DialingCode={this.props.DialingCode}
                DialingCodeDD={this.props.DialingCodeDD}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
