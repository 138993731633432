import React from 'react';
import PropTypes from 'prop-types';
import apiAgent from '../../api/apiAgent';

export class OMSListingsInteractiveMapping extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    loadMap: PropTypes.bool,
    refreshMap: PropTypes.bool,
    data: PropTypes.array,
    Currency: PropTypes.string,
  };

  state = {
    refresh: true,
    Properties: [],
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.loadMap && nextProps.loadMap) {
      this.loadMap(nextProps.data);
    } else if (nextProps.refreshMap) {
      this.loadMap(nextProps.data);
    }
  }

  fetchImageData = async (propertyID) => {
    try {
      const response = await apiAgent.Property.FetchPropertyImage(propertyID);
      const data = response;
      if (data.base64String) {
        return `data:image/png;base64,${data.base64String}`;
      }
    } catch (error) {
      return "/Assets/images/img-upload-default.png";
    }
  };

  loadMap = (data) => {
    if (this.platform === undefined) {
      const H = window.H;
      const mapContainer = document.getElementById("map");

      var apiKey = 'QJF2sIKVcgIx_0Au3uj74g262vyhqKaE7UwbXSC9jUk';

      var platform = new H.service.Platform({
        apikey: apiKey,
      });

      var defaultLayers = platform.createDefaultLayers();
      var map = new H.Map(mapContainer, defaultLayers.vector.normal.map, {
        center: { lat: 52.516, lng: 13.3779 },
        zoom: 3,
      });

      window.addEventListener('resize', () => map.getViewPort().resize());

      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      var ui = H.ui.UI.createDefault(map, defaultLayers);

      this.platform = platform;
      this.ui = ui;
      this.map = map;
      this.behavior = behavior;

      this.startClustering(this.map, this.ui, this.getBubbleContent, data);
    } else {
      this.startClustering(this.map, this.ui, this.getBubbleContent, data);
    }
  };

  startClustering = (map, ui, getBubbleContent, data) => {
    const H = window.H;

    var dataPoints = data.map(function (item) {
      return new H.clustering.DataPoint(item.positionLat, item.positionLong, null, item);
    });

    if (this.clusteredDataProvider === undefined) {
      var defaultTheme = new H.clustering.Provider(dataPoints, {
        min: 3,
        max: 10,
        clusteringOptions: {
          eps: 32,
          minWeight: 3,
        },
      }).getTheme();

      var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
        clusteringOptions: {
          eps: 32,
          minWeight: 3,
        },
        theme: {
          getClusterPresentation: function (cluster) {
            var clusterMarker = defaultTheme.getClusterPresentation.call(defaultTheme, cluster);
            var dataPoints = [];
            cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));
            var clusterData = dataPoints.map((item) => item.getData());

            clusterMarker.setData(clusterData);
            clusterMarker.addEventListener("tap", this.onCMarkerClick);
            return clusterMarker;
          }.bind(this),
          getNoisePresentation: function (noisePoint) {
            var colorName = "#7b5548";
            var infoText = "";
            var radius = 8;

            var noiseSvgTemplate =
              '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="45" font-family="Arial">' +
              '<circle cx="25px" cy="12px" r="8px" stroke="white" stroke-width="2" fill="{colorName}" />' +
              '<rect x="0" y="24px" width="100%" height="18px" fill="#FFFFFF"/>' +
              '<text x="25px" y="38px" text-anchor="middle" fill="#140F0F" font-weight="bold" font-size="12px">{infoText}</text>' +
              "</svg>";

            var data = noisePoint.getData();
            if (data.saleType === "For sale") {
              if (data.status === "Sold - Registered") colorName = "#ff5608";
              else if (data.status === "Active") colorName = "#cb1e0a";
              else colorName = "#fe9900";
            } else if (data.saleType === "For rent") {
              if (data.status === "Rented") colorName = "#3f4db8";
              else if (data.status === "Active") colorName = "#9d1db2";
              else colorName = "#6834bc";
            }
            infoText = this.props.Currency + " " + data.price2;

            var noiseSvg = noiseSvgTemplate
              .replace(/\{radius\}/g, radius)
              .replace(/\{infoText\}/g, infoText)
              .replace(/\{colorName\}/g, colorName);

            var noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
              min: noisePoint.getMinZoom(),
              icon: new H.map.Icon(noiseSvg, {
                size: { w: 50, h: 40 },
                anchor: { x: 10, y: 10 },
              }),
            });

            noiseMarker.setData(data);
            noiseMarker.addEventListener("tap", this.onMarkerClick);

            return noiseMarker;
          }.bind(this),
        },
      });

      var layer = new H.map.layer.ObjectLayer(clusteredDataProvider);
      map.addLayer(layer);
      this.clusteredDataProvider = clusteredDataProvider;
    } else {
      this.clusteredDataProvider.setDataPoints(dataPoints);
    }

    if (data.length !== 0) {
      var points = data.map(function (item) {
        return new H.geo.Point(item.positionLat, item.positionLong);
      });
      var boundingBox = new H.geo.Rect.coverPoints(points);
      map.getViewModel().setLookAtData({ bounds: boundingBox });
    }
  };

  onMarkerClick = async (e) => {
    const H = window.H;
    if (e.target && e.target.data) {
      const position = e.target.getGeometry();
      const data = e.target.data;

      const bubbleContent = await this.getBubbleContent(data);
      let bubble = this.bubble;

      if (!bubble) {
        bubble = new H.ui.InfoBubble(position, {
          content: bubbleContent,
        });
        this.ui.addBubble(bubble);
        bubble.open();
        this.bubble = bubble;
      } else {
        bubble.setPosition(position);
        bubble.setContent(bubbleContent);
        bubble.open();
      }
    }
  };

  onCMarkerClick = async (e) => {
    const H = window.H;

    if (e.target && e.target.data) {
      const position = e.target.getGeometry();
      const data = e.target.data;

      if (Array.isArray(data) && data.length !== 0) {
        let max = 3;
        if (data.length < 3) max = data.length;

        let bubbleContent = "";
        for (let i = 0; i < max; i++) {
          bubbleContent += await this.getBubbleContent(data[i]);
          if (i !== max - 1) bubbleContent += '<hr style="margin:10px 0px 10px 0px; color: black;"/>';
        }

        let bubble = this.bubble;

        if (!bubble) {
          bubble = new H.ui.InfoBubble(position, {
            content: bubbleContent,
          });
          this.ui.addBubble(bubble);
          bubble.open();
          this.bubble = bubble;
        } else {
          bubble.setPosition(position);
          bubble.setContent(bubbleContent);
          bubble.open();
        }
      }
    }
  };

  getBubbleContent = async (data) => {
    const photoUrl = await this.fetchImageData(data.propertyID);

    return [
      '<div class="bubble">',
      '<span class="bubble-desc main-content-h">',
      data.titleH,
      "</span>",
      '<a class="bubble-image" ',
      `style="background-image: url(${photoUrl})" `,
      'href="/Property/SaveProperty?ID=' + data.propertyID + '" target="_blank">',
      '<span class="bubble-pricestring">' + this.props.Currency + " " + data.price + "</span>",
      "</a>",
      "<hr/>",
      "<span>",
      '<span class="bubble-desc main-content">',
      data.title,
      "</span>",
      "<br />",
      '<span class="bubble-desc">',
      data.beds ? '<span class="bubble-icon"><i class="fa fa-bed"></i>' + data.beds + " beds</span>" : "",
      data.baths ? '<span class="bubble-icon"><i class="fa fa-bath"></i>' + data.baths + " baths</span>" : "",
      data.buildingSize ? '<span class="bubble-icon"><i class="fa fa-bank"></i>' + data.buildingSize + "</span>" : "",
      "</span>",
      "</span>",
      "</div>",
    ].join("");
  };

  render() {
    return (
      <div>
        <div style={{ marginTop: 5 }}>
          <div className="info-content-icon" style={{ backgroundColor: "#cb1e0a" }}></div>
          <div className="info-content-text">
            <b>Active</b> for sale
          </div>
          <div className="info-content-icon" style={{ backgroundColor: "#9d1db2", marginLeft: 15 }}></div>
          <div className="info-content-text">
            <b>Active</b> for rent
          </div>
        </div>
        <div style={{ margin: 10 }}></div>
        <div id="map" style={{ height: 800, width: "100%" }}></div>
      </div>
    );
  }
}
