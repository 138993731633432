import React, { useEffect, useRef, useState } from "react";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import apiAgent from "../../../app/api/apiAgent";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import TaskModel from "../common/TaskModel";
import { useStore } from "../../../app/stores/store";
import { ToPriceStringWithoutDecimal } from "../../../app/common/common-functions/functions";
import Chart from "../common/Chart";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { useClickButtonOutside } from "../../../app/common/hooks/useClickButtonOutside";
import { DEAL_CLASS_NAME, DEAL_VIEW_TYPE, DEAL_VIEW_TYPE_LABEL } from "../common/constants";

const DealDashboard = ({ pipelineDD, workspaceDD, currencySymbol, accessType, actions, filterData, loopFilterOpen, loopFilterChanged }) => {
  const taskModelRef = useRef();
  const [dashBoardData, setDashBoardData] = useState();
  const [dashboardDealGridModel, setDashboardDealGridModel] = useState([]);
  const [dashboardAverageOfferPrice, setDashboardAverageOfferPrice] = useState();
  const [dashboardAverageNettCommission, setDashboardAverageNettCommission] = useState();
  const [upcomingAndOverdueTasks, setUpcomingAndOverdueTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentTask, setCurrentTask] = useState({});

  const [CurrentTaskId, setCurrentTaskId] = useState("");
  const [taskDropdownOpen, setTaskDropdownOpen] = useState(false);
  const [currentTaskStatus, setCurrentTaskStatus] = useState();

  const [taskStatusIndex, setTaskStatusIndex] = useState();
  const [workspaceId, setWorkspaceId] = useState();

  const [assignedRolesDD, setAssignedRolesDD] = useState([
    {
      text: "",
      value: "",
    },
  ]);
  const [stageDD, setStageDD] = useState([
    {
      text: "",
      value: "",
    },
  ]);
  const handleClickOutside = (event) => {
    setTaskDropdownOpen(false);
  };

  const refButton = useRef();


  const clicked = useClickButtonOutside(refButton, handleClickOutside);
  const [viewType, setViewType] = useState(1);

  const [dealGraphData, setDealGraphData] = useState([]);
  const [dealCommissionGraphGraphData, setDealCommissionGraphGraphData] = useState([]);
  const defaultConfirmationModalProps = () => {
    return {
      showModal: false,
      isConfirmed: (result) => { },
      title: "",
      body: "",
    }
  }
  const [confirmationModalProps, setConfirmationModalProps] = useState(defaultConfirmationModalProps());

  const {
    userStore: { userSession },
  } = useStore();


  const onUpdateTaskModelOpen = (curTask) => {
    let stages = curTask.stageDD.map((curEle) => {
      return { text: curEle, value: curEle };
    });
    setStageDD(stages);
    if (taskModelRef.current && typeof taskModelRef.current.onModelClick === 'function') {
      taskModelRef.current.onModelClick(stages, curTask);
    } else {
    }
  };

  const onTaskSave = (taskModel, isNewTask, preStageId, curStageId, documentModel) => {
    var mTaskData = [...upcomingAndOverdueTasks];
    if (isNewTask) {
      // Do Nothing...
    }
    else {
      const mTaskIndex = mTaskData.findIndex((e) => e.taskId == taskModel.taskId);
      if (mTaskIndex != -1) {
        taskModel.stageDD = mTaskData[mTaskIndex].stageDD;
        mTaskData.splice(mTaskIndex, 1);
        mTaskData.splice(mTaskIndex, 0, taskModel);
      }
    }

    setUpcomingAndOverdueTasks(mTaskData);
  };

  const handleTaskStatusDD = (mTaskIndex, mStatus) => {
    console.log("mTask Index :: ", mTaskIndex);



    var mTaskRequestModel = { ...upcomingAndOverdueTasks[mTaskIndex] };

    mTaskRequestModel.status = mStatus;

    if (mStatus == 5) // Mark as Deleted 
    {
      var md = {
        showModal: true,
        isConfirmed: (result) => {
          if (result) {
            setConfirmationModalProps(defaultConfirmationModalProps());
            excuteTaskSaveAjax(mTaskIndex, mTaskRequestModel);
          }
          else {
            setConfirmationModalProps(defaultConfirmationModalProps());
          }
        },
        title: "Delete Task",
        body: "Are you sure you want to delete this Task? This cannot be undone.",
      };
      setConfirmationModalProps(md);
    }
    else {
      setCurrentTaskStatus(mStatus);
      let commentSmgValue = getTaskMilestoneStatusDD(mStatus).find((curStatus) => {
        return curStatus.value == mStatus;
      });
      let commentSmg = userSession?.displayName + " marked this task as " + commentSmgValue.text;
      let eleComment = {
        commentID: "",
        userID: mTaskRequestModel.userID,
        comment: commentSmg,
        isStatusChanged: false,
        createdOn: displayDateAndTime(),
        updatedOn: "",
        displayName: userSession?.displayName,
        fileID: "",
        fileName: "",
        documentName: "",
        url: "",
        size: 0,
        displaySize: ""
      }
      mTaskRequestModel.comments.push(eleComment);
      setTaskDropdownOpen(false);

      excuteTaskSaveAjax(mTaskIndex, mTaskRequestModel);
    }
  };

  const excuteTaskSaveAjax = async (mTaskIndex, mTaskModel) => {
    try {
      const mTaskData = structuredClone(upcomingAndOverdueTasks);
      const taskIndex = mTaskData.findIndex(task => task.taskId === CurrentTaskId);
      await apiAgent.Deal.saveTaskAjax(mTaskModel)
        .then((res) => {
          let taskModel = res.taskModel;
          if (taskIndex != -1) {

            if (mTaskModel.status == 5) {
              toast.info("Task deleted successfully.");
              mTaskData.splice(taskIndex, 1);
            }
            else {
              toast.info("Task update successfully.");
              taskModel.stageDD = mTaskModel.stageDD;
              mTaskData.splice(taskIndex, 1, taskModel);
            }
            setUpcomingAndOverdueTasks(mTaskData);
          }
        });
    }
    catch (error) {
      console.error(error);
    }
  };

  const getTaskMilestoneStatusDD = (value) => {
    return [
      {
        text: "Not Started",
        value: 1,
      },
      {
        text: value == 2 ? "In Progress" : "Mark As In Progress",
        value: 2,
      },
      {
        text: value == 3 ? "Issue" : "Mark As Issue",
        value: 3,
      },
      {
        text: value == 4 ? "Completed" : "Mark As Completed",
        value: 4,
      },
      {
        text: "Delete Task",
        value: 5,
      },
    ];
  };

  const dateFormater = (inputDateStr) => {
    const parts = inputDateStr.split("-");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const inputDate = new Date(year, month - 1, day);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dayOfMonth = inputDate.getDate();
    const abbreviatedMonth = monthNames[inputDate.getMonth()];
    const fullYear = inputDate.getFullYear();
    const formattedDateStr = `${dayOfMonth} ${abbreviatedMonth} ${fullYear}`;
    return formattedDateStr;
  };
  const displayDateAndTime = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const now = new Date();

    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    let hours = now.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateAndTime = `${day} ${month} ${year} at ${hours}:${minutes} ${ampm}`;

    return formattedDateAndTime;
  };

  useEffect(() => {
    setIsLoading(true);
    if (filterData.WorkspacePipelineId) {

      apiAgent.Deal.dealDashboardInfoAjax(filterData.WorkspaceAgentId, filterData.WorkspacePipelineId ?? "", filterData.NeedUpdate, filterData.View).then((res) => {
        setDashBoardData(res);
        setUpcomingAndOverdueTasks(res.upcomingAndOverdueTasks);
        let roles = res.assignedRolesDD.map((curRole) => {
          return { text: curRole, value: curRole };
        });
        setAssignedRolesDD(roles);
        setDealGraphData(res.dealGraph);
        setDashboardDealGridModel(res.dashboardDealGridModel);
        setDashboardAverageOfferPrice(res.dashboardAverageOfferPrice);
        setDashboardAverageNettCommission(res.dashboardAverageNettCommission);
        setDealCommissionGraphGraphData(res.dealCommissionGraph);
        setIsLoading(false);
        setWorkspaceId(filterData.WorkspaceAgentId);
        setViewType(filterData.View)

      });
    }
  }, [filterData]);

  const handleWorkspaceDDChange = (e) => {
    const wId = e.newValue;
    const wName = e.newText;

    setWorkspaceId(wId);
    var mFilterData = filterData.getCopy();
    mFilterData.WorkspaceAgentId = wId;
    mFilterData.WorkspaceAgentName = wName;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };

  const handlePipelineChange = (pId, pName) => {
    var mFilterData = filterData.getCopy();
    mFilterData.WorkspacePipelineId = pId;
    mFilterData.WorkspacePipeline = pName;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };


  const onClickTaskOption = (index, status, taskId) => {
    setTaskStatusIndex(index);
    setCurrentTaskStatus(status);
    setCurrentTaskId(taskId);
    setTaskDropdownOpen(true);


  };
  const getTaskDropdownClass = (value) => {
    switch (value) {
      case 0:
      case 1:
        return "fa fa-check-circle not-stated";
      case 2:
        return "fa fa-clock-o task-process";
      case 3:
        return "fa fa-exclamation-circle task-issue";
      case 4:
        return "fa fa-check-circle task-completed";
      default:
        return "";
    }
  };

  const getArrowClass = (isDownArrow) => {
    return isDownArrow && taskDropdownOpen ? " up " : " down ";
  };

  const getTaskStatus = (value) => {

    return [
      value == 1 || value == 0
        ? {
          text: "Not Started",
          isCurrent: true,
          value: 1,
          icon: "fa fa-check-circle",
          className: "not-stated task-list",
        }
        : {
          text: "Not Started",
          isCurrent: false,
          value: 1,
          icon: "fa fa-check-circle",
          className: "not-stated task-list",
        },
      value == 2
        ? {
          text: "In Progress",
          isCurrent: true,
          value: 2,
          icon: "fa fa-clock-o",
          className: "task-process task-list",
        }
        : {
          text: "In Progress",
          isCurrent: false,
          value: 2,
          icon: "fa fa-clock-o",
          className: "task-process task-list",
        },
      value == 3
        ? {
          text: "Issue",
          isCurrent: true,
          value: 3,
          icon: "fa fa-exclamation-circle",
          className: "task-issue task-list",
        }
        : {
          text: "Issue",
          isCurrent: false,
          value: 3,
          icon: "fa fa-exclamation-circle",
          className: "task-issue task-list",
        },
      value == 4
        ? {
          text: "Completed",
          isCurrent: true,
          value: 4,
          icon: "fa fa-check-circle",
          className: "task-completed task-list",
        }
        : {
          text: "Completed",
          isCurrent: false,
          value: 4,
          icon: "fa fa-check-circle",
          className: "task-completed task-list",
        },
      value == 5
        ? {
          text: "Delete Task",
          isCurrent: true,
          value: 5,
          icon: "fa fa-trash",
          className: "task-list",
        }
        : {
          text: "Delete Task",
          isCurrent: false,
          value: 5,
          icon: "fa fa-trash",
          className: "task-list",
        },
    ];
  };
  const taskList = getTaskStatus(currentTaskStatus);

  const getRoleBg = (tag) => {
    // const tagOBJ = props.allTags?.find((curTag) => {
    //   return curTag.name == tag;
    // });
    // if (tagOBJ) {
    //   return tagOBJ.color;
    // }
    return "#4d4d4d";
  };
  const handleViewTypeChange = (viewType) => {
    var mFilterData = filterData.getCopy();
    mFilterData.View = viewType;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };

  if (isLoading) {
    return <AjaxLoader />;
  }




  return (
    <div className="dashboard_component">
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <div>
              <OMSDropdown
                id="AgentDashboardDD"
                options={workspaceDD}
                valueField="Value"
                labelField="Text"
                value={workspaceId}
                onChange={handleWorkspaceDDChange}
              />
            </div>

            <div className="dropdown mx-3">
              <span className="dropdown-toggle text-light cursor-pointer" data-toggle="dropdown">
                {" "} {DEAL_VIEW_TYPE_LABEL.VIEW_LABEL} {" "}
                {viewType == DEAL_VIEW_TYPE.PREVIOUS_YEAR && DEAL_VIEW_TYPE_LABEL.PREVIOUS_YEAR}
                {viewType == DEAL_VIEW_TYPE.CURRENT_YEAR && DEAL_VIEW_TYPE_LABEL.CURRENT_YEAR}
                {viewType == DEAL_VIEW_TYPE.NEXT_YEAR && DEAL_VIEW_TYPE_LABEL.NEXT_YEAR}
                <b className="caret"></b>
              </span>
              <ul className="dropdown-menu">
                <li
                  className={viewType == DEAL_VIEW_TYPE.PREVIOUS_YEAR ? DEAL_CLASS_NAME.DEAL_VIEW_TYPE_ACTIVE : ""}
                  onClick={() => handleViewTypeChange(DEAL_VIEW_TYPE.PREVIOUS_YEAR)}>
                  <a> {DEAL_VIEW_TYPE_LABEL.PREVIOUS_YEAR} </a>
                </li>
                <li
                  className={viewType == DEAL_VIEW_TYPE.CURRENT_YEAR ? DEAL_CLASS_NAME.DEAL_VIEW_TYPE_ACTIVE : ""}
                  onClick={() => handleViewTypeChange(DEAL_VIEW_TYPE.CURRENT_YEAR)}>
                  <a> {DEAL_VIEW_TYPE_LABEL.CURRENT_YEAR} </a>
                </li>
                <li
                  className={viewType == DEAL_VIEW_TYPE.NEXT_YEAR ? DEAL_CLASS_NAME.DEAL_VIEW_TYPE_ACTIVE : ""}
                  onClick={() => handleViewTypeChange(DEAL_VIEW_TYPE.NEXT_YEAR)}>
                  <a> {DEAL_VIEW_TYPE_LABEL.NEXT_YEAR} </a>
                </li>
              </ul>
            </div>
            <div className="dropdown">
              <span className="dropdown-toggle text-light cursor-pointer" data-toggle="dropdown">
                {" "}
                {DEAL_VIEW_TYPE_LABEL.PIPELINE_LABEL} {filterData.WorkspacePipeline}
                <b className="caret"></b>
              </span>
              <ul className="dropdown-menu">
                {pipelineDD?.map((curElem, i) => {
                  return (
                    <li
                      key={curElem.Value}
                      className={filterData.WorkspacePipelineId == curElem.Value ? DEAL_CLASS_NAME.DEAL_VIEW_TYPE_ACTIVE : ""}
                      onClick={() => handlePipelineChange(curElem.Value, curElem.Text)}
                    >
                      <a >{curElem.Text}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-7 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2">Deals</h6>
              <div className="">
                <table className="dashboard_table">
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ borderLeft: "1px dotted gray" }}>Count</th>
                      <th style={{ borderLeft: "1px dotted gray" }}>Deal value</th>
                      {filterData.WorkspaceAgentId == "C" ? (
                        <th style={{ borderLeft: "1px dotted gray" }}>Gross Commission Deal Total</th>
                      ) : (
                        <th style={{ borderLeft: "1px dotted gray" }}>Gross Commission Your Split</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardDealGridModel?.map((curElem, i) => {
                      return (
                        <tr key={i} style={{ borderTop: "1px dotted gray" }}>
                          <td>{curElem.stageName}</td>
                          <td style={{ borderLeft: "1px dotted gray" }}>{curElem.dealCount}</td>
                          <td style={{ borderLeft: "1px dotted gray" }}>{`${currencySymbol}${ToPriceStringWithoutDecimal(curElem.dealValue)}`}</td>
                          {filterData.WorkspaceAgentId == "C" ? (
                            <td style={{ borderLeft: "1px dotted gray" }}>{`${currencySymbol}${ToPriceStringWithoutDecimal(
                              curElem.grossCommissionOfDeals
                            )}`}</td>
                          ) : (
                            <td style={{ borderLeft: "1px dotted gray" }}>{`${currencySymbol}${ToPriceStringWithoutDecimal(
                              curElem.grossCommissionOfSplits
                            )}`}</td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle text-center mb-2">Average offer price</h6>
              <h2 className="card-subtitle text-center mb-2">{`${currencySymbol}${ToPriceStringWithoutDecimal(
                dashboardAverageOfferPrice?.averageOfferPrice
              )}`}</h2>
              <p className="text-center">You achieved an average {dashboardAverageOfferPrice?.achievedOfferPricePercentage}% of the asking price.</p>
              <div className="d-flex align-items-center justify-content-center">
                <h3 className="tag1_lg px-3 bg-oms1">
                  <p>{`${currencySymbol}${ToPriceStringWithoutDecimal(dashboardAverageOfferPrice?.highestOfferAmount)}`}</p>
                  <p className="m-0">highest offer</p>
                </h3>
                <h3 className="tag2_lg px-3 bg-oms">
                  <p>{`${currencySymbol}${ToPriceStringWithoutDecimal(dashboardAverageOfferPrice?.lowestOfferAmount)}`}</p>
                  <p className="m-0">lowest offer</p>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-7 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2">Gross Commission Pipeline</h6>
              <Chart data={dealCommissionGraphGraphData} dataKey={"monthName"} barKey={"commission"} currencySymbol={currencySymbol} />
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-center">Average nett commission</h6>
              <h2 className="card-subtitle text-center mb-2">{dashboardAverageNettCommission?.averageNettCommission}%</h2>
              <div className="d-flex align-items-center justify-content-center">
                <h3 className="tag1_lg px-3 bg-oms1">
                  <p>{dashboardAverageNettCommission?.highestOfferPercentage}%</p>
                  <p className="m-0">highest offer</p>
                </h3>
                <h3 className="tag2_lg px-3 bg-oms">
                  <p>{dashboardAverageNettCommission?.lowestOfferPercentage}%</p>
                  <p className="m-0">lowest offer</p>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-7 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2">Your Upcoming & Overdue Tasks</h6>
              <div className="row">
                <div className="col-md-12">
                  <div>
                    {upcomingAndOverdueTasks?.map((curTask, taskIndex) => {


                      return (
                        <>
                          <div className="task-list-container" key={taskIndex}>

                            <div className="inner-task-dropdown">
                              <div className="d-flex-row">
                                <div
                                  onClick={() =>
                                    onClickTaskOption(
                                      taskIndex,
                                      curTask.status,
                                      curTask.taskId
                                    )
                                  }
                                  onTouchStart={() =>
                                    onClickTaskOption(
                                      taskIndex,
                                      curTask.status,
                                      curTask.taskId
                                    )
                                  }
                                  className="task-status-container dropdown-list"
                                >
                                  <i
                                    ref={refButton}
                                    onClick={() =>
                                      onClickTaskOption(
                                        taskIndex,
                                        curTask.status,
                                        curTask.taskId
                                      )
                                    }
                                    onTouchStart={() =>
                                      onClickTaskOption(
                                        taskIndex,
                                        curTask.status,
                                        curTask.taskId
                                      )
                                    }
                                    className={
                                      getTaskDropdownClass(
                                        curTask.status
                                      ) + " task-list"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                  <div
                                    className={
                                      getArrowClass(
                                        taskIndex == taskStatusIndex
                                      ) + "dropdown-list"
                                    }
                                    ref={refButton}
                                    onClick={() =>
                                      onClickTaskOption(
                                        taskIndex,
                                        curTask.status,
                                        curTask.taskId
                                      )
                                    }
                                    onTouchStart={() =>
                                      onClickTaskOption(
                                        taskIndex,
                                        curTask.status,
                                        curTask.taskId
                                      )
                                    }
                                  ></div>
                                </div>
                                {taskDropdownOpen && (
                                  <div
                                    //ref={dropdownRef}
                                    className={
                                      taskIndex == taskStatusIndex &&
                                        curTask.taskId === CurrentTaskId
                                        ? "row"
                                        : "row d-none"
                                    }
                                    key={"drop-down-" + taskIndex}
                                  >
                                    <div className="col-md-12">
                                      <div className="dropdown-container-task">
                                        <ul className="drop-down-task" >
                                          {taskList.map((item, ind) => {

                                            return (
                                              <>
                                                {
                                                  taskIndex == taskStatusIndex &&
                                                  <li
                                                    key={ind}
                                                    className="dropdown-list"
                                                  >
                                                    <div
                                                      style={{
                                                        padding: "5px 5px",
                                                      }}
                                                      className="d-flex-row"
                                                    >
                                                      <i
                                                        className={
                                                          item.icon +
                                                          " " +
                                                          item.className
                                                        }
                                                      ></i>
                                                      <a
                                                        onClick={() => {
                                                          handleTaskStatusDD(
                                                            taskIndex,
                                                            item.value
                                                          );
                                                        }}
                                                        onTouchStart={() => {
                                                          handleTaskStatusDD(
                                                            taskIndex,
                                                            item.value
                                                          );
                                                        }}
                                                      >
                                                        {item.isCurrent ? (
                                                          <b>{item.text}</b>
                                                        ) : (
                                                          <span>
                                                            {item.text}
                                                          </span>
                                                        )}
                                                      </a>
                                                    </div>
                                                  </li>
                                                }

                                              </>
                                            )
                                          }

                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                            </div>
                            <div className="inner-task-info">
                              <div className="task-name-info">

                                <span
                                  onClick={() => onUpdateTaskModelOpen(curTask)}
                                  class="sub_headers">{`${curTask.taskName}`}</span>

                              </div>

                              <div className="task-date-info">
                                <span class="badge task-date">{`Expected ${curTask.stageExpected ? dateFormater(curTask.stageExpected) : ""}`}</span>
                                {
                                  curTask?.propertyAddress && <span class="badge task-date " style={{ marginLeft: "5px" }}>{`${curTask.propertyAddress ? curTask?.propertyAddress : ""}`}</span>
                                }

                              </div>
                            </div>
                          </div>
                        </>

                      );
                    })}
                  </div>
                  <TaskModel
                    modelId="dashboardTaskModel"
                    ref={taskModelRef}
                    isBreadCrumb={true}
                    isTaskEdit={true}
                    tasksStageDD={stageDD}
                    contacts={[]}
                    assignedRolesDD={assignedRolesDD}
                    onSave={onTaskSave}
                    accessType={accessType}
                    getRoleBg={getRoleBg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModalPopup {...confirmationModalProps} />
    </div >
  );
};

export default DealDashboard;