import React, { useState } from "react";
import { Field, Formik } from "formik";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSNumericBox } from "../../../app/common/common-components/OMSComponent";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSFavourite } from "../../../app/common/common-components/OMSFavourite";
import { OMSImageUpload } from "../../../app/common/common-components/OMSImageUpload";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";
import { OMSMultiselectDropdown } from "../../../app/common/common-components/OMSMultiselectDropdown";
import { OMSGeoLocation } from "../../../app/common/common-components/OMSGeoLocation";
import bootbox from "bootbox";
import { OfficeModel } from "../../../app/models/OfficeModel";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface Props {
  data?: OfficeModel;
  iscreate: boolean;
  setConfirmationModalProps: (confirmationModalProps: ConfirmationModalProps) => void;
}

function OfficeBox(props: Props) {
  const [officeID, setOfficeID] = useState(props.data!.officeID);
  const [name, setName] = useState(props.data!.name);
  const [type, setType] = useState(props.data!.type);
  const [status, setStatus] = useState(props.data!.status);
  const [emailAddress, setEmailAddress] = useState(props.data!.emailAddress);
  const [website, setWebsite] = useState(props.data!.website);
  const [phone, setPhone] = useState(props.data!.phone);
  const [dialingCode, setDialingCode] = useState(props.data!.dialingCode);
  const [faxNumber, setFaxNumber] = useState(props.data!.faxNumber);
  const [faxDialingCode, setFaxDialingCode] = useState(props.data!.faxDialingCode);
  const [physicalAddress, setPhysicalAddress] = useState(props.data!.physicalAddress);
  const [country, setCountry] = useState(props.data!.country);
  const [locationLat, setLocationLat] = useState(props.data!.locationLat);
  const [locationLong, setLocationLong] = useState(props.data!.locationLong);
  const [postalAddress, setPostalAddress] = useState(props.data!.postalAddress);
  const [profile, setProfile] = useState(props.data!.profile);
  const [logo, setLogo] = useState(props.data!.logo);
  const [logoUrl, setLogoUrl] = useState(props.data!.logoUrl);
  const [logoUpload, setLogoUpload] = useState('');
  const [groups, setGroups] = useState(props.data!.groups);
  const [mStatus, setmStatus] = useState(props.data!.status);
  const [formData, setFormData] = useState(props.data!);
  const [buttonStatus, setButtonStatus] = useState({
    disabled: false,
    processing_save: false,
    processing_delete: false,
  });
  const [loading, setloading] = useState(false);
  const [processing_keys, setprocessing_keys] = useState(false);
  const [activeTabID, setActiveTabID] = useState("account");

  let history = useHistory();

  const officeLocationRef = React.createRef<any>();

  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>({
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  });

  const openCloseDeleteModal = (isOpen: boolean) => {
    let varConfirmationModalProps = confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const showPopupAndDeleteOnConfirmation = (title: string, body: string, callback: Function, isDeactivate: boolean, values: any) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed: boolean) => {
        if (isConfirmed) {
          //console.log("confirmed");
          if (isDeactivate) {
            callback(values);
          } else callback();
        }
        openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    setConfirmationModalProps(varConfirmationModalProps);
  };

  var tabContent = [
    { link: "#account", title: "Office detail" },
    { link: "#contactinfo", title: "Contact info" },
    { link: "#profile", title: "Profile" },
  ];

  const handleDialingCodeChange = (e: any) => {
    //console.log("dialing code", e.newValue);
    setDialingCode(e.newValue);
  };

  const handlePhoneChange = (name: any, value: any) => {
    setPhone(value);
  };

  const handleFaxDialingCodeChange = (e: any) => {
    setFaxDialingCode(e.newValue);
  };

  const handleLocationLatChange = (name: any, value: any) => {
    setLocationLat(value);
  };

  const handleLocationLongChange = (name: any, value: any) => {
    setLocationLong(value);
  };

  const handleStatusChanged = (e: any) => {
    var Status = status === 1 ? 2 : 1;
    //if (status == 1) Status = 2;
    setStatus(Status);
  };

  const handleCountryChange = (e: any) => {
    setCountry(e.newValue);
  };

  const handleGroupsChange = (selected: any) => {
    setGroups(selected);
  };

  const handleGroupIDChange = (e: any) => {
    setGroups(e.newValue);
  };

  const handleOfficeTypeChange = (e: any) => {
    setType(e.newValue);
  };

  const OnCropSubmit = (d: any) => {
    //console.log("OnCropSubmit", d);
    setLogoUpload(d);
  };

  const onLocationSubmit = (lat: any, long: any) => {
    setLocationLat(lat);
    setLocationLong(long);
  };

  const onCancelClick = () => {
    history.push("/Settings/Offices");
  };

  const onUsersClick = () => {
    history.push('/Settings/Users?OfficeID=' + officeID);
  };

  const onCustomizeClick = () => {
    history.push("/Settings/Customize");
  };

  const onIntegrationsClick = () => {
    history.push('/Settings/Integrations?OfficeID=' + officeID);
  };

  const onHistoryClick = () => {
    history.push('/Settings/AuditTrail?ModuleID=2&ObjectID=' + officeID);
  };

  const executeDeleteClick = () => {
    setButtonStatus({
      disabled: true,
      processing_save: false,
      processing_delete: true,
    });

    apiAgent.Setting.deleteOfficeAjax(officeID)
      .then((response) => {
        history.push("/settings/offices");
      })
      .catch((error) => {
        toast.info("Fail");
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const onDeleteClick = () => {
    let title = "Delete office?";
    let body = "Do you want to delete office now? This cannot be undone.";
    let callback = executeDeleteClick;
    showPopupAndDeleteOnConfirmation(title, body, callback, false, null);
  };

  const doValidate = (values: OfficeModel) => {
    let { name, emailAddress, website } = values;
  
    const RegEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    const RegWebsite = /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,63}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)?)$/;
    
    if (!name.trim()) {
      toast.info("Please enter office name");
      return false;
    }
  
    if (!emailAddress.trim() || !RegEmail.test(emailAddress)) {
      toast.info("Please enter a valid email address");
      return false;
    }
  
    if (!website.trim()) {
      toast.info("Please enter website");
      return false;
    }
  
    if (!website.startsWith('http://') && !website.startsWith('https://')) {
      toast.info("Website must start with http:// or https://");
      return false;
    }
  
    if (!RegWebsite.test(website)) {
      toast.info("Please enter a valid website URL (e.g., https://www.example.com)");
      return false;
    }
  
    return true;
  };
  
  
  const handleSubmit = async (values: OfficeModel) => {
    values.country = country;
    values.type = type;
    values.dialingCode = dialingCode;
    values.faxDialingCode = faxDialingCode;
    values.phone = phone;
    values.locationLat = locationLat;
    values.locationLong = locationLong;
    values.status = status;
    values.logoUpload = logoUpload;
  
    await setFormData(values);
  
    const isValid = doValidate(values);
    if (!isValid) {
      return;
    }
  
    if (mStatus == 1 && status == 2) {
      let title = "Deactivate office?";
      let body = "Do you want to deactivate the office? It will remove all listings and agent profiles from sync. This cannot be undone.";
      let callback = executeSubmitClick;
      showPopupAndDeleteOnConfirmation(title, body, callback, true, values);
    } else {
      executeSubmitClick(values);
    }
  };

  const executeSubmitClick = (values: any) => {
    //let values = formData;
    //console.log("inside executeSubmitClick office model", values);
    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    //console.log("before sending call to api", values);

    apiAgent.Setting.saveOfficeAjax(values)
      .then((response) => {
        history.push("/settings/offices");
      })
      .catch((error) => {
        toast.info("Fail");
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const onViewLocationClick = (e: any) => {
    officeLocationRef.current.funcViewLocationOnMap(locationLat, locationLong);
  };

  return (
    <Formik
      initialValues={{
        officeID: props.data!.officeID,
        name: props.data!.name,
        logo: props.data!.logo,
        logoUpload: props.data!.logoUpload,
        logoUrl: props.data!.logoUrl,
        country: props.data!.country,
        type: props.data!.type,
        status: 0,
        website: props.data!.website,
        phone: props.data!.phone,
        dialingCode: props.data!.dialingCode,
        faxDialingCode: props.data!.faxDialingCode,
        faxNumber: props.data!.faxNumber,
        physicalAddress: props.data!.physicalAddress,
        postalAddress: props.data!.postalAddress,
        locationLat: props.data!.locationLat,
        locationLong: props.data!.locationLong,
        profile: props.data!.profile,
        emailAddress: props.data!.emailAddress,
        groups: props.data!.groups,
        primaryUserID: props.data!.primaryUserID,
        actions: props.data!.actions,
        super_access: props.data!.super_access,
        officeTypeDD: props.data!.officeTypeDD,
        countryDD: props.data!.countryDD,
        dialingCodeDD: props.data!.dialingCodeDD,
        groupDD: props.data!.groupDD,
        primaryUserDD: props.data!.primaryUserDD,
        favourite: props.data!.favourite,
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, errors, values }) => (
        <form className="SaveOfficeForm" onSubmit={handleSubmit}>
          <ConfirmationModalPopup {...confirmationModalProps} />
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <OMSFavourite favourite={props.data!.favourite} /> &nbsp;
                    <label>{props.iscreate ? "CREATE" : name}</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="Name">
                    Office name
                  </label>
                  <div className="controls">
                    <Field type="text" name="name" className="form-control required-control" placeholder="Name" />
                  </div>
                </div>
                <div className="col-md-12">
                  <OMSTabBox id="officeTabs" value={tabContent} setActiveTabId={setActiveTabID}></OMSTabBox>
                  <div className="tab-content">
                    <div id="account" className={activeTabID === "account" ? "tab-pane fade in active" : "tab-pane fade"}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="Country">Country</label>
                            <OMSDropdown
                              id="country"
                              name="country"
                              options={props.data!.countryDD}
                              valueField="value"
                              labelField="text"
                              value={country}
                              onChange={handleCountryChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="Type">Office type</label>
                            {props.data!.actions.indexOf("officetype-read") < 0 ? (
                              <OMSDropdown
                                id="type"
                                name="type"
                                options={props.data!.officeTypeDD}
                                valueField="value"
                                labelField="text"
                                value={type}
                                onChange={handleOfficeTypeChange}
                              />
                            ) : (
                              <Field type="text" name="type" value={props.data!.officeTypeDD[0].text} className="form-control" readOnly />
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="Website">Website</label>
                            <Field type="text" name="website" className="form-control required-control" placeholder="Website" />
                          </div>
                          <div className="form-group groupDD">
                            <label htmlFor="Groups">Groups</label>
                            {props.data!.actions.indexOf("group-read") < 0
                              ? null
                              : props.data!.groupDD.map(function (option) {
                                  return <Field type="text" name="groups" value={option.text} className="form-control" readOnly />;
                                })}
                            {props.data!.actions.indexOf("group") < 0 ? null : (
                              <OMSMultiselectDropdown
                                id="Groups"
                                name="groups"
                                options={props.data!.groupDD}
                                valueField="value"
                                labelField="text"
                                value={groups}
                                onChange={handleGroupsChange}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            {/*this.props.super_access ?
                              <OMSImageUpload src={this.state.LogoUrl} width={300} height={225} onCropSubmit={this.OnCropSubmit} cropBoxResizable={true} />
                              : null
                            */}
                            <OMSImageUpload src={logoUrl} width={300} height={100} compressor={true} onCropSubmit={OnCropSubmit} />
                          </div>
                          <div className="form-group">
                            <label className="switch">
                              <Field type="checkbox" name="status" checked={status === 1} onChange={handleStatusChanged} />
                              <div className="slider round" />
                              <span
                                style={{
                                  marginLeft: 70,
                                  display: "block",
                                  width: 120,
                                  marginTop: 7,
                                }}
                              >
                                Status: {status === 1 ? "Active" : "Inactive"}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="contactinfo" className={activeTabID === "contactinfo" ? "tab-pane fade in active" : "tab-pane fade"}>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="EmailAddress">Email address</label>
                            <Field type="text" name="emailAddress" className="form-control required-control" placeholder="Email Address" />
                          </div>
                          <div className="form-group cellphones">
                            <label htmlFor="Phone">Telephone number</label>
                            <br />
                            <div className="dialingcode">
                              <OMSDropdown
                                id="dialingCode"
                                name="dialingCode"
                                options={props.data!.dialingCodeDD}
                                valueField="value"
                                labelField="text"
                                value={dialingCode}
                                onChange={handleDialingCodeChange}
                              />
                            </div>
                            <div className="phonenumber">
                              <OMSNumericBox id="phone" name="phone" maxLength={10} value={phone} onChange={handlePhoneChange} />
                            </div>
                          </div>
                          <div className="form-group cellphones">
                            <label htmlFor="FaxNumber">Fax number</label>
                            <br />
                            <div className="dialingcode">
                              <OMSDropdown
                                id="faxDialingCode"
                                name="faxDialingCode"
                                options={props.data!.dialingCodeDD}
                                valueField="value"
                                labelField="text"
                                value={faxDialingCode}
                                onChange={handleFaxDialingCodeChange}
                              />
                            </div>
                            <div className="phonenumber">
                              <Field type="text" name="faxNumber" className="form-control" placeholder="Fax Number" />
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="PhysicalAddress">Physical address</label>
                            <Field as="textarea" rows={4} name="physicalAddress" className="form-control" placeholder="Physical Address" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="twitter">Physical address location</label>
                            <div className="form-inline">
                              <OMSNumericBox
                                isDecimal={true}
                                id="locationLat"
                                name="locationLat"
                                maxLength={20}
                                style={{ width: "150px" }}
                                value={locationLat}
                                placeholder="latitude"
                                onChange={handleLocationLatChange}
                              />
                              <OMSNumericBox
                                isDecimal={true}
                                id="locationLong"
                                name="locationLong"
                                maxLength={20}
                                style={{ width: "150px", marginLeft: "10px" }}
                                value={locationLong}
                                placeholder="longitude"
                                onChange={handleLocationLongChange}
                              />
                              <OMSGeoLocation id="officeLocation" ref={officeLocationRef} onLocationSubmit={onLocationSubmit} Address={physicalAddress} />
                              <Link to="#" onClick={onViewLocationClick} style={{marginTop: 4, marginLeft: 2, display: "inline-block",}}>
                                View location on map
                              </Link>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="PostalAddress">Postal address</label>
                            <Field as="textarea" rows={4} name="postalAddress" className="form-control" placeholder="Postal Address" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="profile" className={activeTabID === "profile" ? "tab-pane fade in active" : "tab-pane fade"}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="Profile">Public profile description</label>
                            <Field as="textarea" rows={3} name="profile" className="form-control" placeholder="Public Profile Description" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-left">
                      {props.iscreate ? null : (
                        <div className="dropup">
                          <button className="btn btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                            More..
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu" style={{ width: "auto" }}>
                            <li>
                              <OMSButtonStyleL
                                style={{ marginRight: 10 }}
                                type="button"
                                ui_icon="fa-user-o"
                                ui_type="cancel"
                                ui_text="Users"
                                ui_processing_text=""
                                processing={false}
                                disabled={buttonStatus.disabled}
                                onClick={onUsersClick}
                              />
                            </li>
                            {props.data!.actions.indexOf("save") < 0 ? null : (
                              <li>
                                <OMSButtonStyleL
                                  style={{ marginRight: 10 }}
                                  type="button"
                                  ui_icon="fa-cog"
                                  ui_type="cancel"
                                  ui_text="Customize"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={buttonStatus.disabled}
                                  onClick={onCustomizeClick}
                                />
                              </li>
                            )}
                            {props.data!.actions.indexOf("save") < 0 ? null : (
                              <li>
                                <OMSButtonStyleL
                                  style={{ marginRight: 10 }}
                                  type="button"
                                  ui_icon="fa-plug"
                                  ui_type="cancel"
                                  ui_text="Integrations"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={buttonStatus.disabled}
                                  onClick={onIntegrationsClick}
                                />
                              </li>
                            )}
                            {props.data!.actions.indexOf("save") < 0 ? null : (
                              <li>
                                <OMSButtonStyleL
                                  style={{ marginRight: 10 }}
                                  type="button"
                                  ui_icon="fa-history"
                                  ui_type="cancel"
                                  ui_text="History"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={buttonStatus.disabled}
                                  onClick={onHistoryClick}
                                />
                              </li>
                            )}
                            {props.data!.actions.indexOf("delete") < 0 ? null : (
                              <li>
                                <OMSButtonStyleL
                                  type="button"
                                  ui_icon="fa-trash-o"
                                  ui_type="delete"
                                  ui_text="Delete office"
                                  ui_processing_text="Deleting.."
                                  processing={buttonStatus.processing_delete}
                                  disabled={buttonStatus.disabled}
                                  onClick={onDeleteClick}
                                />
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={buttonStatus.disabled}
                        onClick={onCancelClick}
                      />
                      {props.data!.actions.indexOf("save") < 0 ? null : (
                        <OMSButtonStyleL
                          type="submit"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Save"
                          ui_processing_text="Saving.."
                          processing={buttonStatus.processing_save}
                          disabled={buttonStatus.disabled}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
export default OfficeBox;
