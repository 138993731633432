import { observer } from "mobx-react";
import HeaderWrapper from "../../header/HeaderWrapper";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { Redirect, useHistory } from "react-router";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";
import { Link } from "react-router-dom";

export default observer(function ErrorPage() {
  const {
    userStore: { userSession, logout, isLoggedIn },
    commonStore: { error },
  } = useStore();
  //console.log("Uncatch error : ", error);
  const history = useHistory();

  document.title = "Base - Something Went Wrong";

  useEffect(() => {
    const redirectIfNotLoggedIn = async () => {
      if (!isLoggedIn) {
        return <Redirect to="/login" />;
      }
    };
    redirectIfNotLoggedIn();
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <HeaderWrapper />

      <div className="container-fluid">
        <Breadcrumb
          breadcrumbs={[{ text: "Home", path: "/" }, { text: "Uncatch Error" }]}
        />
        <div className="row">
          <div id="content" className="col-md-12">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="text-center">
                <h1 style={{ fontSize: "95px" }}>Something Went Wrong</h1>
                <br />
                <br />
                <h2>Something went wrong, we couldn't complete your request.</h2>
                <br />
                <h4>
                We have logged this error and will review it as soon as possible.
                If the issue persists, please file a support ticket and include a screenshot of the error.
                </h4>
                <br />
                <h4 style={{ color: "red", fontWeight: "bold" }}>
                  {error && error.toString() && error.toString().split("\n")[0]}
                </h4>

                <br />
                <br />
                {/*  <a href="/" className="btn btn-success-oms">Take Me Home</a> */}
                <button
                  className="btn btn-success-oms"
                  onClick={() => history.push("/")}
                >
                  Take Me Home
                </button>
                <br />
                <br />
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    </>
  );
});
