import React from 'react';
import Autosuggest from 'react-autosuggest';

interface AutoSuggestProps {
    suggestions: string[];
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    onSuggestionsFetchRequested?: (value: string) => void;
    getSuggestionValue?: (suggestion: any) => string;
    renderSuggestion?: (suggestion: any) => React.ReactNode;
    className?: string;
}

const AutoSuggestInput: React.FC<AutoSuggestProps> = ({
    suggestions,
    value,
    onChange,
    placeholder = 'Type to search',
    onSuggestionsFetchRequested,
    getSuggestionValue = (suggestion) => suggestion,
    renderSuggestion = (suggestion) => <div>{suggestion}</div>,
    className = 'form-control'
}) => {

    const handleSuggestionsFetchRequested = ({ value }: { value: string }) => {
        if (onSuggestionsFetchRequested) {
            onSuggestionsFetchRequested(value);
        }
    };

    const handleSuggestionsClearRequested = () => {
        // Optional: Handle clear suggestions
    };

    const inputProps = {
        placeholder,
        value,
        onChange: (event: React.FormEvent<any>, { newValue }: { newValue: string }) => {
            onChange(newValue);
        },
        className
    };

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={{
                container: 'react-autosuggest__container',
                containerOpen: 'react-autosuggest__container--open',
                input: 'react-autosuggest__input',
                suggestionsContainer: 'react-autosuggest__suggestions-container',
                suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                suggestionsList: 'react-autosuggest__suggestions-list',
                suggestion: 'react-autosuggest__suggestion',
                suggestionHighlighted: 'react-autosuggest__suggestion--highlighted'
            }}
        />
    );
};

export default AutoSuggestInput;