import { makeAutoObservable, runInAction } from "mobx";
import apiAgent from "../api/apiAgent";
import { PropertyReportModel } from "../models/PropertyReportModel";

export default class PropertyReportsStore {
  private static readonly CACHE_KEY = 'provinceSuburbsMap';
  private static readonly PROVINCE_KEY = 'selectedProvinceId';
  private static readonly CACHE_DURATION = 7 * 24 * 60 * 60 * 1000;
  
  private _newReportsCount: number = 0;
  private _isReportGenerating: boolean = false;
  private _propertyReports: PropertyReportModel[] = [];
  private _searchedPropertyReports: PropertyReportModel[] = [];
  private _provinceId: string;
  private _provinceSuburbsMap: Record<number, string> = {};
  private _isLoadingProvinces: boolean = false;

  constructor() {
    const savedProvinceId = localStorage.getItem(PropertyReportsStore.PROVINCE_KEY);
    this._provinceId = savedProvinceId || "3";
    makeAutoObservable(this);
  }

  get provinceId(): string {
    return this._provinceId;
  }

  set provinceId(value: string) {
    runInAction(() => {
      this._provinceId = value;
      localStorage.setItem(PropertyReportsStore.PROVINCE_KEY, value);
    });
  }

  get provinceSuburbsMap(): Record<number, string> {
    return this._provinceSuburbsMap;
  }

  get isLoadingProvinces(): boolean {
    return this._isLoadingProvinces;
  }

  get newReportsCount(): number {
    return this._newReportsCount;
  }

  set newReportsCount(value: number) {
    this._newReportsCount = value;
  }

  get isReportGenerating(): boolean {
    return this._isReportGenerating;
  }

  set isReportGenerating(value: boolean) {
    this._isReportGenerating = value;
  }

  get propertyReports(): PropertyReportModel[] {
    const sortedReports = this.sortReportsByMostRecentDate([...this._propertyReports]);
    return sortedReports;
  }

  set propertyReports(value: PropertyReportModel[]) {
    runInAction(() => {
      this._propertyReports = this.sortReportsByMostRecentDate(value);
    });
  }

  get searchedPropertyReports(): PropertyReportModel[] {
    return [...this._searchedPropertyReports];
  }

  set searchedPropertyReports(value: PropertyReportModel[]) {
    runInAction(() => {
      this._searchedPropertyReports = this.sortReportsByMostRecentDate(value);
    });
  }

  private sortReportsByMostRecentDate(reports: PropertyReportModel[]): PropertyReportModel[] {
    return reports.sort((a, b) => {
      const dateA = this.getMostRecentDate(a);
      const dateB = this.getMostRecentDate(b);
      return dateB.getTime() - dateA.getTime();
    });
  }

  private getMostRecentDate(report: PropertyReportModel): Date {
    const reportDate = new Date(report.reportGenerationDate || 0);
    const ownerDate = new Date(report.ownerDetailsGenerationDate || 0);
    const mostRecent = reportDate > ownerDate ? reportDate : ownerDate;
    return mostRecent;
  }

  updateOrAddPropertyReport(newReport: PropertyReportModel) {
    runInAction(() => {
      const index = this._propertyReports.findIndex(report => report.propertyId === newReport.propertyId);
      
      if (index !== -1) {
        this._propertyReports[index] = {
          ...this._propertyReports[index],
          ...newReport,
          isNewReport: true,
        };
      } else {
        this._propertyReports.push(newReport);
      }

      this._propertyReports = this.sortReportsByMostRecentDate(this._propertyReports);
    });
  }

  setSearchedPropertyReports(reports: PropertyReportModel[]) {
    runInAction(() => {
      this._searchedPropertyReports = this.sortReportsByMostRecentDate(reports);
    });
  }

  private loadFromCache(): boolean {
    const cachedData = localStorage.getItem(PropertyReportsStore.CACHE_KEY);
    if (cachedData) {
      try {
        const { data, timestamp } = JSON.parse(cachedData);
        const now = Date.now();
        if (now - timestamp < PropertyReportsStore.CACHE_DURATION) {
          runInAction(() => {
            this._provinceSuburbsMap = data;
          });
          return true;
        }
      } catch (error) {
        localStorage.removeItem(PropertyReportsStore.CACHE_KEY);
      }
    }
    return false;
  }

  private saveToCache(data: Record<number, string>): void {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    localStorage.setItem(PropertyReportsStore.CACHE_KEY, JSON.stringify(cacheData));
  }

  async loadProvinceSuburbs() {
    if (this._isLoadingProvinces) return;

    try {
      runInAction(() => {
        this._isLoadingProvinces = true;
      });

      const response = await apiAgent.PropertyReports.getAllSuburbs();
      
      runInAction(() => {
        this._provinceSuburbsMap = response;
        this._isLoadingProvinces = false;
      });

      this.saveToCache(response);
    } catch (error) {
      runInAction(() => {
        this._isLoadingProvinces = false;
      });
      throw error;
    }
  }

  get hasProvinces(): boolean {
    return Object.keys(this._provinceSuburbsMap).length > 0;
  }

  async ensureProvinces(): Promise<void> {
    if (!this.hasProvinces) {
      const loadedFromCache = this.loadFromCache();
      if (!loadedFromCache) {
        await this.loadProvinceSuburbs();
      }
    }
  }
}