import React from "react";
import PropTypes from "prop-types";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { toast } from "react-toastify";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import apiAgent from "../../../app/api/apiAgent";
import { withRouter } from "react-router-dom";
import BrochureList from "./ReportStepChildComponents/BrochureList";
import MatchingClientsModal from "./ReportStepChildComponents/MatchingClientsModal";
import ListingLeadsModal from "./ReportStepChildComponents/ListingLeadsModal";
import moment from "moment";

class ReportStepView extends React.Component {
  static propTypes = {
    PropertyID: PropTypes.string,
    UserID: PropTypes.string,
    Mandate_ExpiryDate: PropTypes.string,
    Mandate_StartDate: PropTypes.string,
  };

  constructor(props) {
    super(props);

    var dateRange = this.getDateRange();

    this.state = {
      processing: false,
      mPropertyType: true,
      mSaleType: true,
      mPriceRange: true,
      mAreas: true,
      mBuildsize: true,
      mLandsize: true,
      mBeds: true,
      mBaths: true,
      mGarages: true,
      mParkingBays: true,
      mMatchingClients: [],
      mProcessing: false,
      SelectedAgentID: props.SelectedAgentID,
      mProcessingLeads: false,
      PortalLeadsViews: null,
      SummaryLeadsViews: null,
      LeadsDetailsForGrid: [],
      mViewReportFromLeads: dateRange.mViewReportFromLeads.toDate(),
      mViewReportToLeads: dateRange.mViewReportToLeads.toDate(),
    };
  }

  getDateRange = () => {
    let mViewReportFrom, mViewReportTo;

    if (this.props.Mandate_StartDate && this.props.Mandate_ExpiryDate) {
      mViewReportFrom = moment(this.props.Mandate_StartDate, "DD-MM-YYYY");
      mViewReportTo = moment(this.props.Mandate_ExpiryDate, "DD-MM-YYYY");
      
      if (mViewReportTo > moment()) {
        mViewReportTo = moment();
      }
    } else {
      mViewReportTo = moment();
      mViewReportFrom = moment().add(-1, "months");
    }
    
    return { 
      mViewReportFromLeads: mViewReportFrom,
      mViewReportToLeads: mViewReportTo
    };
  };

  formatDate = (fullDateString) => {
    const date = new Date(fullDateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${day}-${month}-${year}`;
  };

  handleFilterOptionClick = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  handleDropdownFieldChange = (e) => {
    this.setState({ SelectedAgentID: e.newValue });
  };

  handleGenerateBrochureDynamicClick = (BrochureID, IsCrystalReport, exportType) => {
    var mPropertyID = this.props.PropertyID;
    var mShowAddress = window.$("#ShowAddress").is(":checked");
    var mSelectedAgentID = this.state.SelectedAgentID;

    if (IsCrystalReport) {
      this.setState({ processing: true });
      apiAgent.Property.generateBrochureCRAjax(mPropertyID, BrochureID, mSelectedAgentID, mShowAddress)
        .then((response) => {
          var binaryData = [];
          binaryData.push(response.data);
          const url = window.URL.createObjectURL(new Blob(binaryData, { type: exportType == "PDF" ? "application/pdf" : "image/png" }));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", exportType == "PDF" ? "Brochure.pdf" : "Brochure.png");

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          //console.log("error inside listing Report", error);
          toast.error(error.message, "Error", "error");
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    } else {
      this.setState({ processing: true });
      apiAgent.Property.generateBrochureDynamicAjax(mPropertyID, BrochureID, mSelectedAgentID, mShowAddress)
        .then((response) => {
          if (response.status == "success") {
            const a = document.createElement("a");
            a.href = response.message;
            a.setAttribute("download", exportType == "PDF" ? "Brochure.pdf" : "Brochure.png");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((error) => {
          toast.error(error.message, "Error", "error");
          //console.log("error inside listing Report", error);
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
  };

  handleMatchingClientsClick = (e) => {
    this.setState({
      mPropertyType: true,
      mSaleType: true,
      mPriceRange: true,
      mAreas: true,
      mBuildsize: true,
      mLandsize: true,
      mBeds: true,
      mBaths: true,
      mGarages: true,
      mParkingBays: true,
      mMatchingClients: [],
      mProcessing: false,
    });
    window.$("#matchingReportModel").modal("show");
  };

  handleMatchingClientsRunClick = (e) => {
    this.setState({ mProcessing: true, mMatchingClients: [] });
    apiAgent.Contact.requirementsClientMatchAjax(
      this.props.PropertyID,
      this.state.mPriceRange,
      this.state.mAreas,
      this.state.mBuildsize,
      this.state.mLandsize,
      this.state.mBeds,
      this.state.mBaths,
      this.state.mGarages,
      this.state.mParkingBays
    )
      .then((response) => {
        this.setState({ mMatchingClients: response, mProcessing: false });
        if (response.length == 0) {
          toast.info("No match found");
        }
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  handleLeadsDateChange = (dateType, newDate) => {
    
    
    let fromDate, toDate;
    
    if (dateType === 'from') {
      fromDate = newDate;
      toDate = newDate > this.state.mViewReportToLeads ? newDate : this.state.mViewReportToLeads;
    } else {
      toDate = newDate;
      fromDate = newDate < this.state.mViewReportFromLeads ? newDate : this.state.mViewReportFromLeads;
    }

    
    this.setState({
      mViewReportFromLeads: fromDate,
      mViewReportToLeads: toDate
    }, () => {
      //console.log("inside handleLeadsDateChange", moment(fromDate).format("DD-MM-YYYY") );
      this.executeListingLeadsRun(
        moment(fromDate).format("DD-MM-YYYY"),
        moment(toDate).format("DD-MM-YYYY")
      );
    });
  };

  handleListingLeadsRunClick = (e) => {
    
    const { mViewReportFromLeads, mViewReportToLeads } = this.state;
    const from = mViewReportFromLeads ? moment(mViewReportFromLeads).format("DD-MM-YYYY") : "";
    const to = mViewReportToLeads ? moment(mViewReportToLeads).format("DD-MM-YYYY") : "";
    //console.log("inside handleListingLeadsRunClick", from);
    this.executeListingLeadsRun(from, to);
  };

  executeListingLeadsRun = (from, to) => {
    //console.log("inside executeListingLeadsRun",from,to);
    this.setState({ mProcessingLeads: true });
    apiAgent.PropertyLeadsViews.getListingLeadsViewsReportAjax(this.props.PropertyID, from, to)
      .then((response) => {
        //console.log("ListingLeadsViewsReportAjax response", response);
        if (response.summaryLeadsViews == null || response.summaryLeadsViews.length == 0) {
          toast.info("No results found.");
        } else {
          this.setState({
            PortalLeadsViews: response.portalLeadsViews,
            SummaryLeadsViews: response.summaryLeadsViews,
            LeadsDetailsForGrid: response.leadsDetails,
          });
        }
      })
      .catch((error) => {
        //console.log("error inside executeListingLeadsRun", error);
        toast.info("Fail");
      })
      .finally(() => {
        this.setState({ mProcessingLeads: false });
      });
  };

  handleListingLeadsClick = (e) => {
    //console.log("inside handleListingLeadsClick");
    const dateRange = this.getDateRange();
    this.setState({
      mViewReportFromLeads: dateRange.mViewReportFromLeads.toDate(),
      mViewReportToLeads: dateRange.mViewReportToLeads.toDate()
    }, () => {
      const { mViewReportFromLeads, mViewReportToLeads } = this.state;
      this.executeListingLeadsRun(
        moment(mViewReportFromLeads).format("DD-MM-YYYY"),
        moment(mViewReportToLeads).format("DD-MM-YYYY")
      );
    });
    
    window.$("#listingLeadReportModal").modal("show");
    document.getElementById("PageLeadChart").innerHTML = "";
    document.getElementById("LeadsPerPortals").checked = true;
    document.getElementById("spanLeadReportViewText").innerHTML = "Leads per portal";
  };

  handleLeadsCloseModal = (e) => {
    const dateRange = this.getDateRange();
    this.setState({
      mViewReportFromLeads: dateRange.mViewReportFromLeads.toDate(),
      mViewReportToLeads: dateRange.mViewReportToLeads.toDate()
    });
  };

  getReportIcon = (value, tooltip) => {
    if (value === "True") {
      return (
        <span>
          <i className="fa fa-check-circle" title="requirements matched" style={{ color: "green" }}></i>
        </span>
      );
    } else if (value === "False") {
      return (
        <span>
          <i className="fa fa-times-circle" title={"requirements not matched: " + tooltip} style={{ color: "red" }}></i>
        </span>
      );
    } else {
      return (
        <span>
          <i className="fa fa-minus-circle" title="requirement not specified" style={{ color: "grey" }}></i>
        </span>
      );
    }
  };

  getFormattedDate = (date) => {
    if (!date) return "";
    return moment(date).format("DD-MM-yyyy");
  };

  render() {
    let showLeadsReports = true;

    const filters = [
      [
        { name: "mPriceRange", value: this.state.mPriceRange, label: "Price range" },
        { name: "mAreas", value: this.state.mAreas, label: "Areas" },
        { name: "mBuildsize", value: this.state.mBuildsize, label: "Floor size" },
        { name: "mLandsize", value: this.state.mLandsize, label: "Land size" },
      ],
      [
        { name: "mBeds", value: this.state.mBeds, label: "Beds" },
        { name: "mBaths", value: this.state.mBaths, label: "Baths" },
        { name: "mGarages", value: this.state.mGarages, label: "Garages" },
        { name: "mParkingBays", value: this.state.mParkingBays, label: "Parking bays" },
      ],
      [
        { name: "mSaleType", value: this.state.mSaleType, label: "Sale type" },
        { name: "mPropertyType", value: this.state.mPropertyType, label: "Property type" },
      ],
    ];

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading">Brochures</div>
              <div className="panel-body">
                <div className="form-group">
                  <span style={{ marginLeft: 12 }}>
                    Show address on brochure&nbsp;
                    <input type="checkbox" id="ShowAddress" style={{ position: "absolute", marginLeft: 8 }} name="ShowAddress" defaultChecked={false} />
                  </span>
                </div>
                <div className="form-group form-inline">
                  <span style={{ marginLeft: 12, marginRight: 8 }}>Agent name on brochure&nbsp;</span>
                  <OMSDropdown
                    id="AgentNameOnBrochure"
                    name="AgentNameOnBrochure"
                    options={this.props.AgentDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.SelectedAgentID}
                    onChange={this.handleDropdownFieldChange}
                  />
                </div>
                <BrochureList
                  brochures={this.props.Brochures}
                  handleGenerateBrochureDynamicClick={this.handleGenerateBrochureDynamicClick}
                  processing={this.state.processing}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading">Reports</div>
              <div className="panel-body">
                {showLeadsReports &&
                  (this.props.ShowChart ? (
                    <div className="form-group">
                      <OMSButtonStyleL
                        type="button"
                        ui_icon="fa-bar-chart"
                        ui_type="cancel"
                        ui_text="Listing Views & Leads"
                        ui_processing_text="Processing.."
                        processing={this.state.processingLeads}
                        disabled={this.state.processingLeads}
                        onClick={this.handleListingLeadsClick}
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <a href="javascript:void(0)" style={{ cursor: "not-allowed", color: "#333" }} title="Not enabled for office" className="btn">
                      <span className="fa fa-bar-chart"></span> Listing Views & Leads
                    </a>
                  </div>
                ))}

              <div className="form-group">
                <OMSButtonStyleL
                  type="button"
                  ui_icon="fa-file-text-o"
                  ui_type="cancel"
                  ui_text=" Matching Clients"
                  ui_processing_text="Generating.."
                  processing={this.state.processing}
                  disabled={this.state.processing}
                  onClick={this.handleMatchingClientsClick}
                />
              </div>
            </div>
          </div>
          <MatchingClientsModal
            mMatchingClients={this.state.mMatchingClients}
            mProcessing={this.state.mProcessing}
            handleMatchingClientsRunClick={this.handleMatchingClientsRunClick}
            getReportIcon={this.getReportIcon}
            handleFilterOptionClick={this.handleFilterOptionClick}
            filters={filters}
          />
          <ListingLeadsModal
            mViewReportFrom={this.state.mViewReportFromLeads}
            mViewReportTo={this.state.mViewReportToLeads}
            mProcessing={this.state.mProcessingLeads}
            handleListingLeadsRunClick={this.handleListingLeadsRunClick}
            setViewReportFrom={(date) => this.handleLeadsDateChange('from', date)}
            setViewReportTo={(date) => this.handleLeadsDateChange('to', date)}
            handleLeadsCloseModal={this.handleLeadsCloseModal}
            portalLeadsViews={this.state.PortalLeadsViews}
            summaryLeadsViews={this.state.SummaryLeadsViews}
            leadsDetails={this.state.LeadsDetailsForGrid}
            webRef={this.props.WebRef}
            officeName={this.props.userSession.officeName}
          />
        </div>
      </div>
    </div>
  );
}
}

export default withRouter(ReportStepView);