import React, { useEffect, useState } from "react";
import HeaderWrapper from "../header/HeaderWrapper";
import SideMenu from "./dealComponents/SideMenu";
import DealDashboard from "./dealComponents/DealDashboard";
import "../../assets/styles/loop.css";
import apiAgent from "../../app/api/apiAgent";
import PipelineSales from "./dealComponents/pipelines/PipelineSales";
import DragState from "./DragStore/DragState";
import DealReports from "./dealComponents/DealReports";
import DealGrid from "./dealPages/DealGrid";
import TaskGrid from "./dealPages/TaskGrid";
import { useHistory } from "react-router";
import { useStore } from "../../app/stores/store";
import LoopFilterComponent from "./LoopFilterComponent";
import PiplelineDashboardFilterModel from "../../app/models/PiplelineDashboardFilterModel";
import HistoricDeals from "./dealComponents/HistoricDeals";


const DealsPage = () => {
  const defaultPipelineModel = new PiplelineDashboardFilterModel();
  const [filterData, setFilterData] = useState(defaultPipelineModel);
  const [sidebarStateVal, setSidebarStateVal] = useState("Dashboard");

  const [pipelinesDD, setPipelinesDD] = useState([]);
  const [workspaceDD, setWorkspaceDD] = useState([]);
  const [filterPipelineDD, setFilterPipelineDD] = useState([]);
  const [actions, setactions] = useState([]);
  const [OwnershipTypes, setOwnershipTypes] = useState([]);
  const [PropertyTypes, setPropertyTypes] = useState([]);
  const [MandateTypes, setMandateTypes] = useState([]);
  const [Agents, setAgents] = useState([]);
  const [OfficeDD, setOfficeDD] = useState([]);
  const [PropertyTypesRptDD, setPropertyTypesRptDD] = useState([]);
  const [pipelineStagesDD, setPipelineStagesDD] = useState([]);
  const [InternalDealTagsDD, setInternalDealTagsDD] = useState([]);

  const [currencySymbol, setcurrencySymbol] = useState("");
  const [accessType, setAccessType] = useState(null);

  const history = useHistory();
  const {
    userStore: { userSession },
  } = useStore();
  const [isReportGrid, setReportGrid] = useState(true);

  useEffect(() => {
    apiAgent.Deal.loopFilterInfoAjax().then((res) => {
      if (!res.access) {
        history.push("/");
      }
      let mMandateTypesDD = [];
      let mPropertyTypesRptDD = [];
      let mPipelinesDD = [];
      let mPipelinesStageDD = [];
      let mInternalDealTagsDD = [];

      if (res.InternalDealTags.length != 0) {
        mInternalDealTagsDD = res.InternalDealTags;
      }

      if (res.MandateTypes.length != 0) {
        mMandateTypesDD = res.MandateTypes.map((curMandate) => {
          return { Text: curMandate, Value: curMandate };
        });
      }
      mMandateTypesDD.unshift({ Text: "MandateTypes", Value: "" });

      if (res.PropertyTypes.length != 0) {
        res.PropertyTypes.map((propert) => {
          propert.Types?.map((curType) => {
            mPropertyTypesRptDD.push({ Text: curType, Value: curType });
          });
        });
      }
      mPropertyTypesRptDD.unshift({ Text: "PropertyTypes", Value: "" });

      if (res.PipelinesDD.length != 0) {
        mPipelinesDD = res.PipelinesDD.map((c) => {
          return { Text: c.Text, Value: c.Value };
        });
      }

      mPipelinesDD.unshift({ Text: "Pipelines", Value: "" });

      if (res.PipelineStageDD) {
        mPipelinesStageDD = res.PipelineStageDD.map((s) => {
          return { Stages: s.Stages, PipelineID: s.PipelineID };
        });
      }

      setAccessType(res.AccessType);
      setMandateTypes(mMandateTypesDD);
      setactions(res.actions);
      setAgents(res.Agents);
      setOwnershipTypes(res.OwnershipTypes);
      setPropertyTypes(res.PropertyTypes);
      setPropertyTypesRptDD(mPropertyTypesRptDD);
      setOfficeDD(res.OfficeDD);
      setWorkspaceDD(res.WorkspaceAgents);
      setPipelinesDD(res.PipelinesDD);
      setFilterPipelineDD(mPipelinesDD);
      setPipelineStagesDD(mPipelinesStageDD);
      setInternalDealTagsDD(mInternalDealTagsDD);


      //console.log("res.PipelineStageDD", mPipelinesStageDD)

      var mFilterData = filterData.getCopyWithData(res.FilterCache);

      //console.log("Cache Filter Data : ", mFilterData);
      //console.log("mPipelinesStageDD", mPipelinesStageDD);


      let selectedPipeline = mPipelinesStageDD.find(p => p.PipelineID === mFilterData.PipelineId);
      //console.log(selectedPipeline);

      if (selectedPipeline) {
        let selectedStage = selectedPipeline.Stages.find(s => s.StageID === mFilterData.StageId);
        if (selectedStage) {
          //console.log("Stage Name Data : ", selectedStage.StageName);
          mFilterData.Stage = selectedStage.StageName;
        }
      }
      setFilterData(mFilterData);
    });
    if (localStorage.getItem("dealSideMenuLink")) {
      setSidebarStateVal(localStorage.getItem("dealSideMenuLink"));
      localStorage.removeItem("dealSideMenuLink");
    }

  }, []);
  const loopFilterOpen = (e) => {
    window.$("#pipelineFilterModel").modal("show");
  };

  const loopFilterChanged = (data) => {
    //console.log("loopFilterChanged -> " + JSON.stringify(data));
    setFilterData(data);
  };
  const getisAccessPipelinesMenu = () => {
    return sidebarStateVal === "Pipelines";
  }

  document.title = "Base - Deals";

  return (
    <>
      <HeaderWrapper />
      <div className={sidebarStateVal === "Pipelines" ? "pipeline_div" : "dashboard_div"}>
        <SideMenu
          sidebarStateVal={sidebarStateVal}
          setSidebarStateVal={setSidebarStateVal}
          userSession={userSession}
          setReportGrid={setReportGrid}
        />

        {sidebarStateVal === "Pipelines" && (
          <div className="col-lg-11 col-md-10 component_pipeline_div">
            <DragState>
              {sidebarStateVal === "Pipelines" && (
                <PipelineSales
                  accessType={accessType}
                  actions={actions}
                  currencySymbol={currencySymbol}
                  filterData={filterData}
                  loopFilterChanged={loopFilterChanged}
                  loopFilterOpen={loopFilterOpen}
                  menuName={sidebarStateVal}
                  pipelineDD={pipelinesDD}
                  workspaceDD={workspaceDD}
                />
              )}
            </DragState>
          </div>
        )}

        <div className="col-lg-11 col-md-10 component_div">
          <DragState>
            {sidebarStateVal === "Dashboard" && (
              <DealDashboard
                accessType={accessType}
                currencySymbol={currencySymbol}
                filterData={filterData}
                loopFilterChanged={loopFilterChanged}
                loopFilterOpen={loopFilterOpen}
                menuName={sidebarStateVal}
                pipelineDD={pipelinesDD}
                workspaceDD={workspaceDD}
              />
            )}

            {sidebarStateVal === "Deals" && (
              <DealGrid
                actions={actions}
                filterData={filterData}
                loopFilterOpen={loopFilterOpen}
                menuName={sidebarStateVal}
              />
            )}
            {sidebarStateVal === "Tasks" && (
              <TaskGrid
                actions={actions}
                filterData={filterData}
                loopFilterOpen={loopFilterOpen}
                menuName={sidebarStateVal}
              />
            )}
            {sidebarStateVal === "Reports" && (
              <DealReports
                ref={null}
                ID="dealReports"
                Category="deals"
                super_admin={true}
                MandateTypesRptDD={MandateTypes}
                PropertyTypesRptDD={PropertyTypesRptDD}
                AgentsRptDD={Agents}
                ShowOfficeDD={false}
                OfficeDD={OfficeDD}
                pipelineDD={filterPipelineDD}
                pipelineStagesDD={pipelineStagesDD}
                currencySymbol={currencySymbol}
                isReportGridFun={setReportGrid}
                isReportGrid={isReportGrid}
                InternalDealTagsDD={InternalDealTagsDD}
              />
            )}
            {sidebarStateVal === "Imported" && <HistoricDeals />}
          </DragState>
        </div>
      </div>
      <LoopFilterComponent
        PipelinesDD={filterPipelineDD}
        PropertyTypesDD={PropertyTypes}
        AgentsDD={Agents}
        Data={filterData}
        isAccessPipelinesMenu={getisAccessPipelinesMenu()}
        loopFilterChanged={loopFilterChanged}
        PipelineStagesDD={pipelineStagesDD}
        InternalDealTagsDD={InternalDealTagsDD}
      />
    </>
  );
};

export default DealsPage;
