import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import { OMSButtonStyleL } from "./OMSButton";
import apiAgent from "../../api/apiAgent";
import { usePaystackPayment } from 'react-paystack';

export class OMSPurchaseCredit extends React.Component {
  static propTypes = {
    ID: PropTypes.string.isRequired,
  };

  state = {
    sData: [],
    sPackageID: 60,
    sPurchaseVia: 2,
    sSending: false,
    sUserID: "",
    sStep: 0,
    isLoading: false,
  };

  componentDidMount() { }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onOpen = (userID) => {
    this.setState({
      sPackageID: 60,
      sUserID: userID,
      sData: [],
      sStep: 0,
      sSending: false,
    });
    window.$("#purchaseModel-" + this.props.ID).modal("show");
  };
   
  executePlaceOrderCC = (e) => {
    this.setState({ sSending: true, isLoading: true });
    apiAgent.Contact.placeCreditOrderCreditCardAjax(this.state.sUserID, this.state.sPackageID)
      .then((response) => {
        //console.log("placeCreditOrderCreditCardAjax", response);
        this.StartPaystackPayment(response.result);
      })
      .catch((error) => {
        // toast.error(error.response.data);
        toast.error(error.response.data);
      })
      .finally(() => {
        this.setState({ sSending: false, isLoading: false });
      });
  };

  onCancelClick = (e) => {
    window.$("#purchaseModel-" + this.props.ID).modal("hide");
  };

  // you can call this function anything
  onSuccess = (data) => {
    // Implementation for whatever you want to do with reference and after success call.
    //console.log(data);
    var payOrderId = data.reference;
    this.setState({ sSending: true, isLoading: true });
    apiAgent.Contact.placeCreditOrderCreditCardFinishAjax(payOrderId)
      .then((response) => {
        //console.log("placeCreditOrderCreditCardFinishAjax => ", response);
        if (response.status == "success") {
          window.$("#purchaseModel-" + this.props.ID).modal("hide");
          toast.success(response.message);
          setTimeout(
            function () {
              window.location.reload();
            }, 1500);
        }
        else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        // toast.error(error.response.data);
        toast.error(error.response.data);
      })
      .finally(() => {
        this.setState({ sSending: false, isLoading: false });
      });
  };

  // you can call this function anything
  onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    //console.log('closed');
  }

  StartPaystackPayment = (data) => {

    this.setState({ PayData: data });

    var id = data.entity.id;
    var name = data.entity.name;
    var email = data.entity.email;
    var userId = data.entity.extra.userId;
    var officeId = data.entity.extra.officeId;
    var productTitle = data.productTitle;
    var productId = data.entity.productId;
    var productPrice = data.productPrice;

    var config = {
      metadata: {
        et_meta: {
          app: 'base',
          name: name,
          email: email,
          userId: userId,
          productTitle: productTitle,
          productId: productId
        }
      },
      reference: id,
      email: email,
      amount: parseInt(productPrice) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
      publicKey: 'pk_live_fe8dc8138744638e4dce1f1bb820c55a61e2d212', //'pk_test_41ed9ac5ea01073b3b5ebd864b921eecf2b15461',
      language: 'en',
      currency: 'ZAR',
    };

    //console.log(config);

    const initializePayment = usePaystackPayment(config);
    initializePayment({ onSuccess: this.onSuccess, onClose: this.onClose });

  };


  getPackageCost = () => {
    if (this.state.sPackageID == 60)
      return "R60.00";
    else if (this.state.sPackageID == 250)
      return "R245.00";
    else if (this.state.sPackageID == 500)
      return "R490.00";
    else if (this.state.sPackageID == 1000)
      return "R980.00";
    else if (this.state.sPackageID == 5000)
      return "R4,950.00";
    else
      return "R60.00";
  }

  render() {
    var self = this;
    return (
      <div>
        <div className="modal fade" id={"purchaseModel-" + this.props.ID} tabIndex={-1} aria-labelledby="purchaseModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="purchaseModelLabel">
                  Purchase Credit
                </h4>
              </div>
              <div className="modal-body">
                {this.state.sStep == 0 ? (
                  <>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="sPackageID">Number of credit to purchase (VAT included)</label>
                          <select
                            id="sPackageID"
                            name="sPackageID"
                            className="form-control"
                            style={{ maxWidth: "200px" }}
                            value={this.state.sPackageID}
                            onChange={this.handleChange}
                          >
                            <option value="60">60 credits (R60)</option>
                            <option value="250">250 credits (R245)</option>
                            <option value="500">500 credits (R490)</option>
                            <option value="1000">1000 credits (R980)</option>
                            <option value="5000">5000 credits (R4,950)</option>
                          </select>
                        </div>
                      </div>

                       

                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <span>
                            Credits:{" "}
                            <b>
                              {this.state.sPackageID}
                            </b>
                          </span>
                          <br />
                          <span>
                            Purchase Amount:{" "}
                            <b>
                              {this.getPackageCost()}
                            </b>
                          </span>

                          <p style={{ fontSize: 14, marginTop: 10 }}>
                            You will be prompted to complete the payment through Entegral's secure payment process. <br/>
                            Once the payment is processed, credits will automatically be applied to your account.
                          </p>

                        </div>
                        <div className="form-group">
                          <div className="text-center" style={{ marginTop: 30 }}>

                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <a href="https://help.entegral.net/en/articles/9901704-credits-overview" target="_blank" title="Overview of credits and costs" >Overview of credits and costs</a>
                        </div>

                      </div>
                    </div>
                  </>
                ) : null}

              </div>
              {this.state.sStep == 0 ? (
                <div className="modal-footer">
                  <div className="col-md-12">
                    <OMSButtonStyleL
                      type="button"
                      ui_icon="fa-check"
                      ui_type="save"
                      ui_text="Buy credits"
                      ui_processing_text="Processing Order.."
                      onClick={this.executePlaceOrderCC}
                      processing={this.state.sSending}
                      disabled={this.state.sSending}
                    />
                    <OMSButtonStyleL
                      style={{ marginLeft: 10 }}
                      type="button"
                      ui_icon="fa-times"
                      ui_type="cancel"
                      ui_text="Cancel"
                      ui_processing_text=""
                      processing={false}
                      disabled={false}
                      onClick={this.onCancelClick}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}