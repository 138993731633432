import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSTwitterGeoAutocomplete } from "../../../app/common/common-components/OMSGeoAutocomplete";
import { OMSHereMapLocation } from "../../../app/common/common-components/OMSHereMapLocation";
import { OMSTwitterAutocomplete } from "../../../app/common/common-components/OMSAutocomplete";
import { OMSNumericBox } from "../../../app/common/common-components/OMSComponent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";

export class LocationStepView extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    loadMap: PropTypes.bool,
    IsExternalListing: PropTypes.bool,
    handleIsExternalListingChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.hereMapRef = React.createRef();
  }

  getStateFromData = (data) => {
    //console.log("inside getStateFromData, data: ", data);
    return {
      Step: "Listing capture: Location",
      IsEditMode: data.propertyID ? true : false,
      PropertyID: data.propertyID,
      UserID: data.userID,
      WebRef: data.webRef,
      LocationLat: data.locationLat,
      LocationLong: data.locationLong,

      Country: data.country,
      StateOrProvince: data.stateOrProvince,
      CityOrTown: data.cityOrTown,
      DistrictOrSuburb: data.districtOrSuburb,
      PostalCode: data.postalCode,

      StreetNumber: data.streetNumber,
      StreetName: data.streetName,
      UnitNumber: data.unitNumber,
      ComplexName: data.complexName,

      Area: this.getAreaText(data.country, data.stateOrProvince, data.cityOrTown, data.districtOrSuburb),
      IsMakeLocationAvailable: data.isMakeLocationAvailable,
      //IsExternalListing: data.isExternalListing,
      ExternalListingLink: data.externalListingLink,
      ErfNumber: data.erfNumber,
      TitleDeedNo: data.titleDeedNo,
      NewDevelopent: data.newDevelopent,

      CountrySelected: data.country,
      confirmationModalProps: {
        showModal: false,
        isConfirmed: (result) => {},
        title: "",
        body: "",
      },
    };
  };

  componentDidMount() {
    if (this.props.loadMap) {
      this.hereMapRef.current.handleLocationOnlyChange(this.state.LocationLat, this.state.LocationLong);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loadMap) {
      this.hereMapRef.current.handleLocationOnlyChange(this.state.LocationLat, this.state.LocationLong);
    }
  }

  doSetFormData = (data) => {
    const locationData = {
      PropertyID: this.state.PropertyID,
      UserID: this.state.UserID,
      WebRef: this.state.WebRef,
      LocationLat: this.state.LocationLat,
      LocationLong: this.state.LocationLong,
      Country: this.state.Country,
      StateOrProvince: this.state.StateOrProvince,
      CityOrTown: this.state.CityOrTown,
      DistrictOrSuburb: this.state.DistrictOrSuburb,
      PostalCode: this.state.PostalCode,
      StreetNumber: this.state.StreetNumber,
      StreetName: this.state.StreetName,
      UnitNumber: this.state.UnitNumber,
      ComplexName: this.state.ComplexName,
      NewDevelopent: this.state.NewDevelopent,
      Area: this.state.Area,
      IsMakeLocationAvailable: this.state.IsMakeLocationAvailable,
      Block: this.state.Block,
      ErfNumber: this.state.ErfNumber,
      TitleDeedNo: this.state.TitleDeedNo,
      IsExternalListing: this.props.IsExternalListing,
      ExternalListingLink: this.props.IsExternalListing ? this.state.ExternalListingLink : "",
    };

    data.append("LocationJson", JSON.stringify(locationData));
    //console.log("inside doSetFormData, locationData: ", locationData);
  };

  doCheckFormData = () => {
    var result = { status: "error", message: "Fields are required." };
    var resultSuccess = { status: "success", message: "" };

    if (!this.state.LocationLat || !this.state.LocationLong) {
      result.message = "Please enter location";
      return result;
    } else if (!this.state.Country || !this.state.StateOrProvince || !this.state.CityOrTown || !this.state.DistrictOrSuburb) {
      result.message = "Please enter area";
      /* console.log(
        "inside area issue, country: " +
          this.state.Country +
          ", state: " +
          this.state.StateOrProvince +
          ", city: " +
          this.state.CityOrTown +
          ", suburb: " +
          this.state.DistrictOrSuburb
      ); */
      return result;
    }
    // else if (this.props.IsExternalListing && !this.state.ExternalListingLink) {
    //   result.message = "Please enter external listing link by clicking the edit icon.";
    //   return result;
    //}
    // else if (this.props.IsExternalListing && !this.isValidLink(this.state.ExternalListingLink)) {
    //   result.message = "Please enter valid external listing link by clicking the edit icon.";
    //   return result;
    // }
    else if (!this.state.StreetNumber) {
      result.message = "Please enter street number";
      return result;
    } else if (!this.state.StreetName) {
      result.message = "Please enter street name";
      return result;
    }
    return resultSuccess;
  };

  doSetState = (data) => {
    this.setState(this.getStateFromData(data));
    if (data.loadMap) {
      if (this.state.LocationLong && this.state.LocationLong != "0" && this.state.LocationLat && this.state.LocationLat != "0")
        this.hereMapRef.current.handleLocationOnlyChange(this.state.LocationLat, this.state.LocationLong);
    }
  };

  doGetState = () => {
    return this.state;
  };

  getAreaText = (country, province, town, suburb) => {
    var areaText = suburb;
    if (town) areaText = areaText + (areaText == "" ? "" : ", ") + town;
    if (province) areaText = areaText + (areaText == "" ? "" : ", ") + province;
    if (country) areaText = areaText + (areaText == "" ? "" : ", ") + country;

    if (areaText == country) areaText = "";

    return areaText;
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  isValidLink = (link) => {
    try {
      const url = new URL(link);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (error) {
      return false;
    }
  };

  onSaveExternalLinkClick = () => {
    const externalListingLinkValue = document.getElementById("externalListingLink").value;
    const isLinkValid = this.isValidLink(externalListingLinkValue);

    // if (!isLinkValid) {
    //   toast.info("Please enter valid external listing link.");
    // } else {
    this.setState({
      ExternalListingLink: externalListingLinkValue,
      //IsExternalListing: true
    });
    this.props.handleIsExternalListingChange(true, () => {
      //console.log("inside onSaveExternalLinkClick, this.props.IsExternalListing: " + this.props.IsExternalListing);
      window.$("#ExternalListingLinkModal").modal("hide");
    });
    //}
  };

  openCloseExternalListingModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  onExternalListingClick = (e) => {    
    let title = "Mark Listing as External?";
    let body = "Do you want to mark this listing as External? External Listings are not synced with Portals.";
    let callback = this.executeSelectExternalListingClick;
    this.showPopupAndSelectOnConfirmation(title, body, callback);
  };

  executeSelectExternalListingClick = (e) => {    
    this.props.handleIsExternalListingChange(true, () => {});
  };

  showPopupAndSelectOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseExternalListingModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  handleIsExternalListingChange = (e) => {
    if(e.target.checked)
    {
      this.onExternalListingClick(e);
    }
    else{
      
      this.props.handleIsExternalListingChange(e.target.checked, () => {});
    }
    
  };

  handleAreaChange = (e) => {
    //console.log("inside handleAreaChange, e: " + e  );
    this.setState({
      Area: e,
    });
  };

  handleIsMakeLocationAvailableChange = (e) => {
    if (!this.state.IsMakeLocationAvailable) {
      if (this.state.LocationLong && this.state.LocationLong != 0 && this.state.LocationLat && this.state.LocationLat != 0)
        this.setState({ IsMakeLocationAvailable: !this.state.IsMakeLocationAvailable });
      else {
        toast.info("Latitude and longitude cannot be 0 - set the address");
        window.$("#IsMakeLocationAvailable").prop("checked", false);
      }
    } else this.setState({ IsMakeLocationAvailable: !this.state.IsMakeLocationAvailable });
  };

  onHereMapLocationSearchBoxChangeFunc = (locationid) => {
    //console.log("inside onHereMapLocationSearchBoxChangeFunc, locationid: " + locationid);
    this.hereMapRef.current.handleLocationIDChange(locationid);
  };

  onUseMyLocation = (e) => {
    if (navigator.geolocation) {
      // Use method getCurrentPosition to get coordinates
      navigator.geolocation.getCurrentPosition(
        function (position) {
          this.hereMapRef.current.handleLocationChange(position.coords.latitude, position.coords.longitude);
        }.bind(this)
      );
    }
  };

  handleLatLongChange = (name, value) => {
    this.setState({ [name]: value }, function () {
      if (this.state.LocationLong && this.state.LocationLong != "0" && this.state.LocationLat && this.state.LocationLat != "0")
        this.hereMapRef.current.handleLocationOnlyChange(this.state.LocationLat, this.state.LocationLong);
    });
  };

  onHereMapChangeFunc = (lat, long) => {
    //console.log("inside onHereMapChangeFunc, lat: " + lat + ", long: " + long);
    this.setState({ LocationLat: lat.toString(), LocationLong: long.toString() });
  };

  saveArea = (country, province, town, suburb, postalcode) => {
   // console.log("inside saveArea");
    var data = new FormData();
    data.append("Country", country);
    data.append("Province", province);
    data.append("Town", town);
    data.append("Suburb", suburb);
    data.append("Postalcode", postalcode);

    const formDataObj = Object.fromEntries(data.entries());

    // var xhr = new XMLHttpRequest();
    // xhr.open('post', '/Settings/SaveAreaAjax', true);
    // xhr.onload = function () {
    //     var result = JSON.parse(xhr.responseText);
    //     if (result.status == "success") {
    //         var item = result.result;
    //         var stateInfo = { Area: '', Country: item.Country, StateOrProvince: item.Province, CityOrTown: item.Town, DistrictOrSuburb: item.Suburb, PostalCode: item.Postalcode };
    //         stateInfo.Area = this.getAreaText(stateInfo.Country, stateInfo.StateOrProvince, stateInfo.CityOrTown, stateInfo.DistrictOrSuburb);
    //         this.setState(stateInfo);
    //     }
    // }.bind(this);
    // xhr.send(data);
    apiAgent.Setting.saveAreaAjax(formDataObj)
      .then((response) => {
        //console.log("inside location save area response=",response);
        var item = response.result;
        var stateInfo = {
          Area: "",
          Country: item.country,
          StateOrProvince: item.province,
          CityOrTown: item.town,
          DistrictOrSuburb: item.suburb,
          PostalCode: item.postalcode,
        };
        stateInfo.Area = this.getAreaText(stateInfo.Country, stateInfo.StateOrProvince, stateInfo.CityOrTown, stateInfo.DistrictOrSuburb);
        this.setState(stateInfo);
      })
      .catch((error) => {
        // console.log("inside location save area error=",error);
        // toast.info("Fail");
      });
  };

  onHereMapGetInfoFunc = (item) => {
        if (
      this.state.Country != item.country ||
      this.state.StateOrProvince != item.province ||
      this.state.CityOrTown != item.town ||
      this.state.DistrictOrSuburb != item.suburb
    ) {     
      this.saveArea(item.country, item.province, item.town, item.suburb, item.postalcode);
    }
    
      this.setState({ StreetName: item.streetName, StreetNumber: item.propertyNumber, PostalCode: item.postalcode });
    
  };
  
  handleExternalListingEditClick = (e) => {
    //e.preventDefault();
    window.$("#ExternalListingLinkModal").modal("show");
  };
  handleOpenExternalListingClick = (e) => {
    //window.open("https://www.google.com", "_blank");
    window.open(this.state.ExternalListingLink, "_blank");
  };

  onAreaChangeInfoFunc = (item) => {
    //console.log("inside onAreaChangeInfoFunc, item: " + item);
    var stateInfo = {
      Country: item.country,
      StateOrProvince: item.province,
      CityOrTown: item.town,
      DistrictOrSuburb: item.suburb,
      PostalCode: item.postalcode,
    };
    if (!stateInfo.DistrictOrSuburb) stateInfo.DistrictOrSuburb = stateInfo.CityOrTown;
    stateInfo.Area = this.getAreaText(stateInfo.Country, stateInfo.StateOrProvince, stateInfo.CityOrTown, stateInfo.DistrictOrSuburb);
    this.setState(stateInfo);
  };

  handleCountryDDChange = (e) => {
    this.setState({ CountrySelected: e.newValue });
  };

  state = this.getStateFromData(this.props.data);

  render() {
    return (
      <div>
      <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-10">
            <div className="row">
              <div className="form-group col-xs-3">
              
                <OMSDropdown
                  id="CountryAbbreviation"
                  name="CountryAbbreviation"
                  className="form-control"
                  options={this.props.CountryDD}
                  valueField="value"
                  labelField="text"
                  value={this.state.CountrySelected}
                  onChange={this.handleCountryDDChange}
                />
              </div>
              <div className="form-group col-xs-9">
                <OMSTwitterGeoAutocomplete
                  country={this.state.CountrySelected}
                  id="locationSearchBox"
                  name="locationSearchBox"
                  onChange={this.onHereMapLocationSearchBoxChangeFunc}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-2">
            <div className="form-group">
              <div className="ellipsis-container">
                <div className="ellipsis-div">
                  <a href="#" onClick={this.onUseMyLocation}>
                    {" "}
                    <i className="fa fa-map-marker" aria-hidden="false" />
                    &nbsp;use my location
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <OMSHereMapLocation
              ref={this.hereMapRef}
              loadMap={this.props.loadMap}
              style={{ margin: "5px", height: 400, border: "1px solid grey", maxWidth: "100% !important" }}
              onChange={this.onHereMapChangeFunc}
              onGetInfo={this.onHereMapGetInfoFunc}
            />
            <br />
          </div>
          <div className="col-md-12">
            <div className="form-horizontal">
              <div className="location-controls">
                <div className="location-controls_left">
                  <div className="form-inline">
                    <label style={{ paddingRight: 15, paddingTop: 0 }} className="control-label">
                      Lat/Long
                    </label>
                    <OMSNumericBox
                      isDecimal={true}
                      id="LocationLat"
                      name="LocationLat"
                      maxLength={20}
                      style={{ width: "90px", marginRight: "10px" }}
                      value={this.state.LocationLat}
                      placeholder="latitude"
                      onChange={this.handleLatLongChange}
                    />
                    <OMSNumericBox
                      isDecimal={true}
                      id="LocationLong"
                      name="LocationLong"
                      maxLength={20}
                      style={{ width: "90px" }}
                      value={this.state.LocationLong}
                      placeholder="longitude"
                      onChange={this.handleLatLongChange}
                    />
                  </div>
                  <div className="checkbox" style={{ paddingLeft: 15, paddingTop: 2 }}>
                    <label>
                      <input
                        id="IsMakeLocationAvailable"
                        name="IsMakeLocationAvailable"
                        type="checkbox"
                        defaultChecked={this.state.IsMakeLocationAvailable}
                        onChange={this.handleIsMakeLocationAvailableChange}
                        style={{ marginRight: 5, marginTop: 4, display: "inline-block", verticalAlign: "middle" }}
                      />
                      Make location available on website
                    </label>
                  </div>
                </div>
                <div className="location-controls_right">
                  <div className="checkbox d-flex align-items-center justify-content-end" style={{ marginBottom: 0, paddingTop: 2 }}>
                    <label>
                      <input
                        id="IsExternalListing"
                        name="IsExternalListing"
                        type="checkbox"
                        checked={this.props.IsExternalListing}
                        onChange={this.handleIsExternalListingChange}
                        //disabled={this.state.IsEditMode}
                      />
                      External Listing
                    </label>
                    <i
                      className="fa fa-edit"
                      //onClick={this.state.IsEditMode && !this.props.IsExternalListing ? undefined : this.handleExternalListingEditClick}
                      onClick={!this.props.IsExternalListing ? undefined : this.handleExternalListingEditClick}
                      style={{
                        marginLeft: 5,
                        cursor: this.state.IsEditMode && !this.props.IsExternalListing ? "default" : "pointer",
                      }}
                      aria-hidden="true"
                    ></i>

                    <i
                      className="fa fa-external-link"
                      //onClick={this.state.IsEditMode && !this.props.IsExternalListing ? undefined : this.handleOpenExternalListingClick}
                      onClick={!this.props.IsExternalListing ? undefined : this.handleOpenExternalListingClick}
                      style={{ marginLeft: 5, cursor: this.state.IsEditMode && !this.props.IsExternalListing ? "default" : "pointer" }}
                      aria-hidden="true"
                    ></i>

                    <a href="https://help.entegral.net/en/articles/9208143-capture-an-external-listing" target="_blank" style={{ marginLeft: 5 }}>
                      <i className="fa fa-question-circle" aria-hidden="true"></i>
                    </a>

                    {/* 
                <a href="https://www.google.com" target="_blank" style={{ marginLeft: 5 }}>
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                </a>*/}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-2 control-label">Area</label>
                <div className="col-md-10">
                  <OMSTwitterAutocomplete
                    id="area"
                    name="area"
                    value={this.state.Area}
                    Country={this.state.CountrySelected}
                    onChange={this.handleAreaChange}
                    onInfoChange={this.onAreaChangeInfoFunc}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-2 control-label">Street number</label>
                <div className="col-md-3">
                  <input
                    id="StreetNumber"
                    name="StreetNumber"
                    type="text"
                    className="form-control"
                    value={this.state.StreetNumber}
                    onChange={this.handleChange}
                  />
                </div>
                <label className="col-md-2 control-label">Street name</label>
                <div className="col-md-5">
                  <input id="StreetName" name="StreetName" type="text" className="form-control" value={this.state.StreetName} onChange={this.handleChange} />
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-2 control-label">Unit number</label>
                <div className="col-md-3">
                  <input id="UnitNumber" name="UnitNumber" type="text" className="form-control" value={this.state.UnitNumber} onChange={this.handleChange} />
                </div>
                <label className="col-md-2 control-label">Complex name</label>
                <div className="col-md-5">
                  <input id="ComplexName" name="ComplexName" type="text" className="form-control" value={this.state.ComplexName} onChange={this.handleChange} />
                </div>
              </div>
              <div className="form-group">
                {/*<label className="col-md-2 control-label">Block</label>
                                <div className="col-md-3">
                                    <input id="Block" name="Block" type="text" className="form-control" value={this.state.Block} onChange={this.handleChange} />
                                </div>*/}
                <div className="col-md-5"></div>
                <label className="col-md-2 control-label">Erf number</label>
                <div className="col-md-5">
                  <input id="ErfNumber" name="ErfNumber" type="text" className="form-control" value={this.state.ErfNumber} onChange={this.handleChange} />
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-5"></div>
                <label className="col-md-2 control-label">Title deed no</label>
                <div className="col-md-5">
                  <input id="TitleDeedNo" name="TitleDeedNo" type="text" className="form-control" value={this.state.TitleDeedNo} onChange={this.handleChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="ExternalListingLinkModal"
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="ExternalListingLinkModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="ExternalListingLinkModalLabel">
                  External Listing Link
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group form-inline col-md-12">
                    <input
                      id="externalListingLink"
                      name="ExternalListingLink"
                      type="text"
                      className="form-control"
                      value={this.state.ExternalListingLink}
                      onChange={this.handleChange}
                      style={{ width: "90%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <OMSButtonStyleL
                  type="button"
                  style={{ marginRight: 10 }}
                  ui_icon="fa-check"
                  ui_type="save"
                  ui_text="Save"
                  ui_processing_text="Saving.."
                  processing={false}
                  disabled={false}
                  onClick={this.onSaveExternalLinkClick}
                />
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
