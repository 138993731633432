const currentYear = new Date().getFullYear();

export const DEAL_VIEW_TYPE_LABEL = {
    PREVIOUS_YEAR: `Previous Year ${currentYear - 1}`,
    CURRENT_YEAR: `Current Year ${currentYear}`,
    NEXT_YEAR: `Next Year ${currentYear + 1}`,
    VIEW_LABEL: "View:",
    PIPELINE_LABEL: "pipeline:"
};
export const DEAL_VIEW_TYPE = {
    PREVIOUS_YEAR: 0,
    CURRENT_YEAR: 1,
    NEXT_YEAR: 2
};
export const DEAL_CLASS_NAME = {
    DEAL_VIEW_TYPE_ACTIVE: "deal-view-type-active"
};