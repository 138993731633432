import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";

//console.log("Base v2 - ©2024 Entegral (" + process.env.NODE_ENV + ")");

if(process.env.NODE_ENV != "development")
{
  Sentry.init({
    dsn: "https://5b98217e38f7441591da346c1f9e89bc:b9a49bbffbb845ba983f46560e754a50@sentry.io/180690",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation
      }),
      Sentry.replayIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/base\.entegral\.net/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}


