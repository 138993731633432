import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { UserModel } from "../../app/models/UserModel";
import UserBox from "./UserBox";
import Header from "../header/Header";
//import "../../assets/styles/components/_saveUser.scss";
import { useStore } from "../../app/stores/store";
import HeaderWrapper from "../header/HeaderWrapper";
import { ConfirmationModalProps } from "../../app/models/ModalControlProps/ConfirmationModalProps";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";

export default observer(function SaveUserPage() {
  let varConfirmModalProps: ConfirmationModalProps = {
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const { userId: userIdFromUrl } = useParams<{ userId: string }>();
  const [user, setUser] = useState<UserModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModalProps, setConfirmationModalProps] = useState<
    ConfirmationModalProps
  >(varConfirmModalProps);
  const query = useQuery();
  const userIdFromQuery = query.get("ID");
  const userId = userIdFromUrl || userIdFromQuery;
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    setIsLoading(true);

    //console.log("inside Setting's saveUser page useEffect", userId);
    if(isLoggedIn){
      apiAgent.Setting.getUserProfileByToken(userId!)
      .then((response: UserModel) => {
        let oUser: UserModel = response;
        //console.log("User : ", oUser);
        setUser(oUser);

        //console.log("data", oUser);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else{
      window.location.href = "/login";
    }
  
  }, []);

  document.title = "Base - Save User";

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading && user) {
    return (
      <>
        <HeaderWrapper />

        <div className="saveUser-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Manage Users", path: "/Settings/Users" },
              {
                text: !userId
                  ? "Create"
                  : user?.title + ". " + user?.firstName + " " + user?.lastName,
              },
            ]}
          />
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              {/* <UserBox user={user} userId={userId!} setConfirmationModalProps={setConfirmationModalProps} /> */}
              <UserBox
                data={user}
                userId={userId!}
                setConfirmationModalProps={setConfirmationModalProps}
              />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
