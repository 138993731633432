import { Field, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import DatePicker from "react-datepicker";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSNumericBox } from "../../../app/common/common-components/OMSComponent";
import { OMSSocialMediaBox } from "../../../app/common/common-components/OMSSocialBox";

// interface ContactTabCustomValues{
//   customMContact: any;
//   dialingCodeDD: any;
// }

//function ContactTab(props: any) {
function ContactTab({ contact, customMContact, setCustomMContact }: any) {
  const [emailAddress, setEmailAddress] = useState(contact.emailAddress);
  const [residentialAddress, setResidentialAddress] = useState(
    customMContact.residential_address
  );
  const [postalAddress, setPostalAddress] = useState(
    customMContact.postal_address
  );

  useEffect(() => {
    //console.log("inside ContactTab, useEffect, contact=", customMContact);
    //console.log("inside Contact Tab, useEffect, customMContact",customMContact);
  }, []);

  // useEffect(() => {
  //   console.log("inside ContactTab, useEffect, customMContact=", customMContact);
  // }, [customMContact]);

  const handleContactChange = (name: string, value: any) => {
    /* var Contact = contact;
        Contact[name] = value;
        setContact(Contact); */

    var tempContact = customMContact;
    switch (name) {
      case "facebook":
        tempContact!.facebook = value;
        break;
      case "home_dialing_code":
        tempContact!.home_dialing_code = value;
        break;
      case "home_phone":
        tempContact!.home_phone = value;
        break;
      case "instagram":
        tempContact!.instagram = value;
        break;
      case "linkedin":
        tempContact!.linkedin = value;
        break;
      case "office_dialing_code":
        tempContact!.office_dialing_code = value;
        break;
      case "office_phone":
        tempContact!.office_phone = value;
        break;
      case "phone":
        tempContact!.phone = value;
        break;
      case "phone_dialing_code":
        tempContact!.phone_dialing_code = value;
        break;
      case "postal_address":
        tempContact!.postal_address = value;
        setPostalAddress(value);
        break;
      case "residential_address":
        tempContact!.residential_address = value;
        setResidentialAddress(value);
        break;
      case "twitter":
        tempContact!.twitter = value;
        break;
      case "emailAddress":
        tempContact!.emailAddress = value;
        setEmailAddress(value);
        break;
    }
    //console.log("inside handleContactChange, tempContact=", tempContact);

    setCustomMContact(tempContact);
  };

  const handleDialingCodeChange = (e: any) => {
    //console.log("handleDialingCodeChange, e=", e);
    handleContactChange(e.name, e.newValue);
  };

  const handlePhoneChange = (name: any, value: any) => {
    //console.log("handlePhoneChange, name=", name, "value=", value);
    handleContactChange(name, value);
  };

  const handleInputTextChange = (e: any) => {
    //console.log("handleAddressChange, e.target.name=", e.target.name, e.target.value);
    // if (e.target.name === "residential_address") {
    //   handleContactChange("residential_address", e.target.value);
    // }
    // if (e.target.name === "postal_address") {
    //   handleContactChange("postal_address", e.target.value);
    // }

    handleContactChange(e.target.name, e.target.value);
  };
  const onClickWhatsApp = () => {
    const url = `https://wa.me/${customMContact.phone_dialing_code}${customMContact.phone}`;
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group cellphones">
            <label htmlFor="email">Mobile number</label>
            <br />
            <div className="dialingcode">
              <OMSDropdown
                id="phone_dialing_code"
                name="phone_dialing_code"
                options={contact!.dialingCodeDD!}
                valueField="value"
                labelField="text"
                value={customMContact.phone_dialing_code}
                onChange={handleDialingCodeChange}
              />
            </div>
            <div className="phonenumber">
              <OMSNumericBox
                id="phone"
                name="phone"
                maxLength={10}
                value={customMContact.phone}
                onChange={handlePhoneChange}
              />
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-sm-12"
          style={{ marginTop: "25px", marginBottom: "10px" }}
        >
          <div
            style={{ display: customMContact.phone === "" ? "none" : "block" }}
          >
            <div
              className="whatsapp d-flex align-items-center"
              onClick={onClickWhatsApp}
              style={{
                paddingLeft: "5px",
                cursor: "pointer",
              }}
            >
              <i
                className="fa fa-whatsapp"
                style={{
                  fontSize: "30px",
                }}
              ></i>
              <strong style={{ margin: "0px", paddingLeft: "5px" }}>
                Chat on WhatsApp
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group cellphones">
            <label htmlFor="email">Office number</label>
            <br />
            <div className="dialingcode">
              <OMSDropdown
                id="office_dialing_code"
                name="office_dialing_code"
                options={contact!.dialingCodeDD!}
                valueField="value"
                labelField="text"
                value={customMContact.office_dialing_code}
                onChange={handleDialingCodeChange}
              />
            </div>
            <div className="phonenumber">
              <OMSNumericBox
                id="office_phone"
                name="office_phone"
                value={customMContact.office_phone}
                onChange={handlePhoneChange}
              />
            </div>
          </div>
          <div className="form-group cellphones">
            <label htmlFor="email">Home number</label>
            <br />
            <div className="dialingcode">
              <OMSDropdown
                id="home_dialing_code"
                name="home_dialing_code"
                options={contact!.dialingCodeDD!}
                valueField="value"
                labelField="text"
                value={customMContact.home_dialing_code}
                onChange={handleDialingCodeChange}
              />
            </div>
            <div className="phonenumber">
              <OMSNumericBox
                id="home_phone"
                name="home_phone"
                value={customMContact.home_phone}
                onChange={handlePhoneChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="EmailAddress">Email address</label>
            <input
              type="email"
              className="form-control"
              id="EmailAddress"
              name="emailAddress"
              value={emailAddress}
              onChange={handleInputTextChange}
            />
            {/* <Field type="text" name="emailAddress" className="form-control" placeholder="Email Address" /> */}
          </div>
          <div className="form-group">
            <label htmlFor="social">Social media handles</label>
            <div className="form-inline">
              <OMSSocialMediaBox
                id="socialBoxItem"
                name="socialBoxItem"
                value={customMContact}
                onChange={handleContactChange}
              ></OMSSocialMediaBox>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="residential_address">Residential address</label>
            <textarea
              rows={2}
              className="form-control"
              id="residential_address"
              name="residential_address"
              value={residentialAddress}
              onChange={handleInputTextChange}
            />
            {/* <Field
            as="textarea"
            rows={2}
            name="mContact.residential_address"
            //value={customMContact.residential_address}
            className="form-control"
            placeholder="Residential Address"
          /> */}
          </div>
          <div className="form-group">
            <label htmlFor="postal_address">Postal Address</label>
            <textarea
              rows={2}
              className="form-control"
              id="postal_address"
              name="postal_address"
              value={postalAddress}
              onChange={handleInputTextChange}
            />
            {/* <Field as="textarea" rows={2} name="mContact.postal_address" className="form-control" placeholder="postal Address" /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactTab;
