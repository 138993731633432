import React from "react";
import PropTypes from "prop-types";
import { OMSTwitterAutocomplete } from "../../../../app/common/common-components/OMSAutocomplete";
import { OMSNumericBox } from "../../../../app/common/common-components/OMSComponent";
import { OMSHereMapLocation } from "../../../../app/common/common-components/OMSHereMapLocation";
import {OMSTwitterGeoAutocomplete}  from "../../../../app/common/common-components/OMSGeoAutocomplete";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";
import apiAgent from "../../../../app/api/apiAgent";

export class ImportLocationStepView extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        loadMap: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.hereMapRef = React.createRef();
    };

    getStateFromData = (data) => {
        return {
            Step: 'Listing capture: Location',
            PropertyID: data.propertyID,
            ImportID: data.importID,
            OfficeID: data.officeID,
            LocationLat: data.locationLat,
            LocationLong: data.locationLong,
            Country: data.country,
            StateOrProvince: data.stateOrProvince,
            CityOrTown: data.cityOrTown,
            DistrictOrSuburb: data.districtOrSuburb,
            PostalCode: data.postalCode,
            StreetNumber: data.streetNumber,
            StreetName: data.streetName,
            UnitNumber: data.unitNumber,
            ComplexName: data.complexName,
            Area: this.getAreaText(data.country, data.stateOrProvince, data.cityOrTown, data.districtOrSuburb),
            IsMakeLocationAvailable: data.isMakeLocationAvailable,
            ErfNumber: data.erfNumber,
            TitleDeedNo: data.titleDeedNo
        }
    };

    componentDidMount() {
        if (this.props.loadMap) {
            if (this.state.LocationLong && this.state.LocationLat)
                this.hereMapRef.current.handleLocationOnlyChange(this.state.LocationLat, this.state.LocationLong);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.loadMap) {
            if (this.state.LocationLong && this.state.LocationLat)
                this.hereMapRef.current.handleLocationOnlyChange(this.state.LocationLat, this.state.LocationLong);
        }
    }

    doSetFormData = (data) => {
        const locationData = {
            OfficeID: this.state.OfficeID,
            PropertyID: this.state.PropertyID,
            ImportID: this.state.ImportID,    
            LocationLat: this.state.LocationLat,
            LocationLong: this.state.LocationLong,
            Country: this.state.Country,
            StateOrProvince: this.state.StateOrProvince,
            CityOrTown: this.state.CityOrTown,
            DistrictOrSuburb: this.state.DistrictOrSuburb,
            PostalCode: this.state.PostalCode,
            StreetNumber: this.state.StreetNumber,
            StreetName: this.state.StreetName,
            UnitNumber: this.state.UnitNumber,
            ComplexName: this.state.ComplexName,
            Area: this.state.Area,
            IsMakeLocationAvailable: this.state.IsMakeLocationAvailable,
            Block: this.state.Block,
            ErfNumber: this.state.ErfNumber,
            TitleDeedNo: this.state.TitleDeedNo,
        };    
        data.append('LocationJson', JSON.stringify(locationData));
    };

    doCheckFormData = () => {
        var result = { status: 'error', message: 'Fields are required.' };
        var resultSuccess = { status: 'success', message: '' };

        if (!this.state.OfficeID) {
            result.message = "Please select office.";
            return result;
        }
        else if (!this.state.LocationLat || !this.state.LocationLong) {
            result.message = "Please enter location";
            return result;
        }
        else if (!this.state.Country || !this.state.StateOrProvince || !this.state.CityOrTown || !this.state.DistrictOrSuburb) {
            result.message = "Please enter area";
            return result;
        }
        return resultSuccess;
    };

    doSetState = (data) => {
        this.setState(this.getStateFromData(data));
        if (data.loadMap) {
            if (this.state.LocationLong && this.state.LocationLat)
                this.hereMapRef.current.handleLocationOnlyChange(this.state.LocationLat, this.state.LocationLong);
        }
    };

    doGetState = () => {
        return this.state;
    };

    getAreaText = (country, province, town, suburb) => {
        var areaText = suburb;
        if (town)
            areaText = areaText + (areaText == "" ? "" : ", ") + town;
        if (province)
            areaText = areaText + (areaText == "" ? "" : ", ") + province;
        if (country)
            areaText = areaText + (areaText == "" ? "" : ", ") + country;
        return areaText;
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleAreaChange = (e) => {
        console.log('handleAreaChange');
    };

    handleIsMakeLocationAvailableChange = (e) => {
        this.setState({ IsMakeLocationAvailable: !this.state.IsMakeLocationAvailable });
    };

    onHereMapLocationSearchBoxChangeFunc = (locationid) => {
        this.hereMapRef.current.handleLocationIDChange(locationid);
    };

    onUseMyLocation = (e) => {
        if (navigator.geolocation) {
            // Use method getCurrentPosition to get coordinates
            navigator.geolocation.getCurrentPosition(function (position) {
                this.hereMapRef.current.handleLocationChange(position.coords.latitude, position.coords.longitude)
            }.bind(this));
        }
    };

    handleLatLongChange = (name, value) => {
        this.setState({ [name]: value }, function () {
            if (this.state.LocationLong && this.state.LocationLat)
                this.hereMapRef.current.handleLocationOnlyChange(this.state.LocationLat, this.state.LocationLong);
        });
    };

    onHereMapChangeFunc = (lat, long) => {
        this.setState({ LocationLat: lat.toString(), LocationLong: long.toString() });
    };

    saveArea = (country, province, town, suburb, postalcode) => {
        var data = new FormData();
        data.append('Country', country);
        data.append('Province', province);
        data.append('Town', town);
        data.append('Suburb', suburb);
        data.append('Postalcode', postalcode)

        const formDataObj = Object.fromEntries(data.entries());

        /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Settings/SaveAreaAjax', true);
        xhr.onload = function () {
            var result = JSON.parse(xhr.responseText);
            if (result.status == "success") {
                var item = result.result;
                var stateInfo = { Area: '', Country: item.Country, StateOrProvince: item.Province, CityOrTown: item.Town, DistrictOrSuburb: item.Suburb, PostalCode: item.Postalcode };
                stateInfo.Area = this.getAreaText(stateInfo.Country, stateInfo.StateOrProvince, stateInfo.CityOrTown, stateInfo.DistrictOrSuburb);
                this.setState(stateInfo);
            }
        }.bind(this);
        xhr.send(data); */
        apiAgent.Setting.saveAreaAjax(formDataObj)
          .then((response) => {
            //console.log("inside location save area response=",response);
            var item = response.result;
                var stateInfo = { Area: '', Country: item.country, StateOrProvince: item.province, CityOrTown: item.town, DistrictOrSuburb: item.suburb, PostalCode: item.postalcode };
                stateInfo.Area = this.getAreaText(stateInfo.Country, stateInfo.StateOrProvince, stateInfo.CityOrTown, stateInfo.DistrictOrSuburb);
                this.setState(stateInfo);
          })
          .catch((error) => {
            // console.log("inside location save area error=",error);
            // toast.info("Fail");
          });
    };

    onHereMapGetInfoFunc = (item) => {
        if (this.state.Country != item.country || this.state.StateOrProvince != item.province || this.state.CityOrTown != item.town || this.state.DistrictOrSuburb != item.suburb) {
            /*var stateInfo = { Area: '', Country: item.country, StateOrProvince: item.province, CityOrTown: item.town, DistrictOrSuburb: item.suburb, StreetName: item.streetName, StreetNumber: item.propertyNumber, PostalCode: item.postalcode };
            if (!stateInfo.DistrictOrSuburb)
                stateInfo.DistrictOrSuburb = stateInfo.CityOrTown;
            stateInfo.Area = this.getAreaText(stateInfo.Country, stateInfo.StateOrProvince, stateInfo.CityOrTown, stateInfo.DistrictOrSuburb);
            this.setState(stateInfo); */
            this.setState({ StreetName: item.streetName, StreetNumber: item.propertyNumber, PostalCode: item.postalcode })
            this.saveArea(item.country, item.province, item.town, item.suburb, item.postalcode);
        }
    };

    onAreaChangeInfoFunc = (item) => {
        //console.log("onAreaChangeInfoFunc");
        //console.log(item);
        var stateInfo = {
            Country: item.country,
            StateOrProvince: item.province,
            CityOrTown: item.town,
            DistrictOrSuburb: item.suburb,
            PostalCode: item.postalcode,
          };
          if (!stateInfo.DistrictOrSuburb) stateInfo.DistrictOrSuburb = stateInfo.CityOrTown;
          stateInfo.Area = this.getAreaText(stateInfo.Country, stateInfo.StateOrProvince, stateInfo.CityOrTown, stateInfo.DistrictOrSuburb);
          this.setState(stateInfo);
    };

    handleOfficeIDChange = (e) => {
        this.setState({ OfficeID: e.newValue });
    };

    state = this.getStateFromData(this.props.data);

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-horizontal">
                            <div className="form-group">
                                <label className="col-md-2 control-label">Office</label>
                                <div className="col-md-10">
                                    <OMSDropdown id="OfficeID" options={this.props.officeDD} valueField="value" labelField="text" value={this.state.OfficeID} onChange={this.handleOfficeIDChange} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="col-md-10">
                                <OMSTwitterGeoAutocomplete id="locationSearchBox" name="locationSearchBox" onChange={this.onHereMapLocationSearchBoxChangeFunc} />
                            </div>
                            <div className="col-md-2">
                                <a href="#" onClick={this.onUseMyLocation}> <i className="fa fa-map-marker" aria-hidden="false" />&nbsp;use my location</a>
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>

                    <div className="col-md-12">
                        <OMSHereMapLocation ref={this.hereMapRef} loadMap={this.props.loadMap} style={{ margin: '5px', height: 400, border: '1px solid grey', maxWidth:'100%' }} onChange={this.onHereMapChangeFunc} onGetInfo={this.onHereMapGetInfoFunc} />
                        <br />
                    </div>
                    <div className="col-md-12">
                        <div className="form-horizontal">
                            <div className="form-group">
                                <div className="col-md-6">
                                    <div className="checkbox">
                                        <label><input id="IsMakeLocationAvailable" name="IsMakeLocationAvailable" type="checkbox" defaultChecked={this.state.IsMakeLocationAvailable} onChange={this.handleIsMakeLocationAvailableChange} />Make location available on website</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-inline pull-right">
                                        <OMSNumericBox isDecimal={true} id="LocationLat" name="LocationLat" maxLength={20} style={{ width: '150px' }} value={this.state.LocationLat} placeholder="latitude" onChange={this.handleLatLongChange} />
                                        <OMSNumericBox isDecimal={true} id="LocationLong" name="LocationLong" maxLength={20} style={{ width: '150px', marginLeft: '10px' }} value={this.state.LocationLong} placeholder="longitude" onChange={this.handleLatLongChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-2 control-label">Area</label>
                                <div className="col-md-10">
                                    <OMSTwitterAutocomplete id="area" name="area" value={this.state.Area} onChange={this.handleAreaChange} onInfoChange={this.onAreaChangeInfoFunc} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-2 control-label">Street number</label>
                                <div className="col-md-3">
                                    <input id="StreetNumber" data-validation="required" data-validation-error-msg="Please enter country name" name="StreetNumber" type="text" className="form-control" value={this.state.StreetNumber} onChange={this.handleChange} />
                                </div>
                                <label className="col-md-2 control-label">Street name</label>
                                <div className="col-md-5">
                                    <input id="StreetName" name="StreetName" type="text" className="form-control" value={this.state.StreetName} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-2 control-label">Unit number</label>
                                <div className="col-md-3">
                                    <input id="UnitNumber" name="UnitNumber" type="text" className="form-control" value={this.state.UnitNumber} onChange={this.handleChange} />
                                </div>
                                <label className="col-md-2 control-label">Complex name</label>
                                <div className="col-md-5">
                                    <input id="ComplexName" name="ComplexName" type="text" className="form-control" value={this.state.ComplexName} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                {/*<label className="col-md-2 control-label">Block</label>
                                <div className="col-md-3">
                                    <input id="Block" name="Block" type="text" className="form-control" value={this.state.Block} onChange={this.handleChange} />
                                </div>*/}
                                <div className="col-md-5">
                                </div>
                                <label className="col-md-2 control-label">Erf number</label>
                                <div className="col-md-5">
                                    <input id="ErfNumber" name="ErfNumber" type="text" className="form-control" value={this.state.ErfNumber} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-5">
                                </div>
                                <label className="col-md-2 control-label">Title deed no</label>
                                <div className="col-md-5">
                                    <input id="TitleDeedNo" name="TitleDeedNo" type="text" className="form-control" value={this.state.TitleDeedNo} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}