import React from "react";
import { Redirect, Switch, useHistory } from "react-router";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSImageUpload } from "../../app/common/common-components/OMSImageUpload";
import { OMSTabBox } from "../../app/common/common-components/OMSTabBox";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { UserModel } from "../../app/models/UserModel";

// import '../../assets/styles/styles.scss';
// import '../../assets/styles/custom.scss';
// import '../../assets/styles/shared.scss';
import { OMSFavourite } from "../../app/common/common-components/OMSFavourite";
import { OMSNumericBox } from "../../app/common/common-components/OMSComponent";
import { OMSSocialMediaBox } from "../../app/common/common-components/OMSSocialBox";
import { UserIntegrationBox } from "./UserIntegrationBox";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { UserListCountModel } from "../../app/models/UserListCountModel";
//import Personal from "./Personal";
//import Profile from "./Profile";
//import Integrations from "./Integrations";
//import PortalAccess from "./PortalAccess";
import { Link } from "react-router-dom";
import { OMSPurchaseCredit } from "../../app/common/common-components/OMSPurchaseCredit";
import bootbox from "bootbox";
import auth2 from "react-gapi-auth2";
import DatePicker from "react-datepicker";
//import "../../assets/styles/react-datepicker/react-datepicker-custom.css";

import { DatePickerFormikField } from "../../app/common/common-components/DatePickerFormikField";
//import { ConfirmationModalProps } from "../../app/models/ModalControlProps/ConfirmationModalProps";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { OMSMultiselectDropdown } from "../../app/common/common-components/OMSMultiselectDropdown";
import $ from "jquery";
import moment from "moment";
import { StoreContext } from "../../app/stores/store";
import { withRouter } from "react-router-dom";
import QRCode from "qrcode.react";




class TwoFAComponent extends React.Component {
  state = {
    TwoFAQRCodeValues: {},
    verificationCode: "",
    //TwoFASettings: JSON.parse(this.props.data.twoFASettingsJson),
  };

  // ...

  render() {
    const { TwoFASettings, handleTwoFactorChange } = this.props;

    //console.log("inside render, TwoFASettings: ", TwoFASettings);

    
    const isEnabled = TwoFASettings && TwoFASettings.is_enabled;
    const isConfigured = isEnabled && TwoFASettings.secret_key != null && TwoFASettings.secret_key != "" && TwoFASettings.secret_key != undefined;
    const TwoFAButtonText = isEnabled ? "Disable" : "Enable";

    const twoFAButtonAction = () => {
      // ...

      if (!isEnabled) {
        apiAgent.twoFactorAuth
          .get2FASetupInfo()
          .then((response) => {
            // ...

            this.setState({ TwoFAQRCodeValues: response });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        handleTwoFactorChange("secret_key", "");
        toast.info("Press Save to disable Two Factor Authentication.");
      }
      handleTwoFactorChange("is_enabled", !isEnabled);
    };

    const handleVerificationCodeChange = (event) => {
      this.setState({ verificationCode: event.target.value });
    };

    const verifyCode = () => {
      let base32Secret = this.state.TwoFAQRCodeValues.secretKeyBase32;
      let verificationCode = this.state.verificationCode;
      apiAgent.twoFactorAuth
        .verify2FACodeInitialSetup(verificationCode, base32Secret)
        .then((response) => {
          if (response) {
            //console.log("2FA Verification Successful, response: ", response);
            toast.success(response.message);
            // this.setState((prevState) => ({
            //   TwoFASettings: {
            //     ...prevState.TwoFASettings,
            //     secret_key: response.secret_key,
            //   },
            // }));
            handleTwoFactorChange("secret_key", response.secret_key);
          } else {
            toast.error("Two Factor Auth Code verification Failed");
          }
        })
        .catch((error) => {
          toast.error("Two Factor Auth Code verification Failed");
        });
    };

    return (
      <div>
        <div className="form-group">
          <label htmlFor="TwoFactorAuth">Two Factor Authentication</label>
          <br />
          {isConfigured && <label>Configured</label>}

          <OMSButtonStyleL
            type="button"
            ui_icon=""
            ui_type={isEnabled ? "delete" : "create"}
            ui_text={TwoFAButtonText}
            ui_processing_text=""
            processing={false}
            disabled={false}
            onClick={twoFAButtonAction}
          />
        </div>
        {isEnabled && !isConfigured && (
          <>
            <div className="form-group">
              Scan the QR code below with a free authenticator app like{" "}
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer">
                Google Authenticator
              </a>
              ,{" "}
              <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank" rel="noopener noreferrer">
                Microsoft Authenticator
              </a>
              ,{" "}
              <a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank" rel="noopener noreferrer">
                Authy
              </a>
              , or{" "}
              <a href="https://1password.com/downloads/" target="_blank" rel="noopener noreferrer">
                1Password
              </a>{" "}
              to add an extra layer of protection to your account.
              <div>
                <QRCode value={this.state.TwoFAQRCodeValues.authUrl} size={128} level={"H"} />
              </div>
              {/*<div>
          Need help? Learn more in our{" "}
          <a href="YourHelpCenterURL" target="_blank" rel="noopener noreferrer">
            Help Center
          </a>
          .
        </div>*/}
            </div>

            <div className="form-group">
              <label htmlFor="verificationCode">Verify the code from the app:</label>
              <div>
                <input
                  type="text"
                  id="verificationCode"
                  name="verificationCode"
                  value={this.state.verificationCode}
                  onChange={handleVerificationCodeChange}
                  style={{ marginRight: "10px" }} // Add right margin for space
                />
                <OMSButtonStyleL
                  type="button"
                  ui_icon=""
                  ui_type={"create"}
                  ui_text="Verify"
                  ui_processing_text=""
                  processing={false}
                  disabled={false}
                  onClick={verifyCode}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default TwoFAComponent;
