import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { LoginModel } from "../../app/models/LoginModel";
import { AuthCodeFormValues, UserFormValues, UserSession } from "../../app/models/User";
import { useStore } from "../../app/stores/store";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import ImageWithFallback from "../../app/common/common-components/ImageWithFallback";
//import { ImageWithFallback } from "mobx-react-lite";

function AuthCodeBox() {
  //const [isRememberState, setIsRememberState] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isResetTwoFA, setisResetTwoFA] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loginModel, setLoginModel] = useState<LoginModel>();
  const { userStore } = useStore();
  const [emailAddress, setEmailAddress] = useState(window.localStorage.getItem("userEmail"));
  const [strIsRemember, setStrIsRemember] = useState(window.localStorage.getItem("strIsRemember"));




  useEffect(() => {
    let userDetailsObject = window.localStorage.getItem("userDetails");
    let userDetails: any;
    if (userDetailsObject) {
      setIsLoading(true);
      userDetails = JSON.parse(userDetailsObject);
      //console.log("inside loginBox useEffect, userDetails=", userDetails);
      let oLoginModel: LoginModel = {
        emailAddress: userDetails.email,
        profilePic: userDetails.profilePic,
        isRemember: true,
        password: "",
        systemTheme: userDetails.systemTheme,
        systemLogo: "",
        name: userDetails.name,
      };
      setEmailAddress(userDetails.email);
      setLoginModel(oLoginModel);
      //setIsRememberState(true);
      setIsLoading(false);
    } else {
      setIsLoading(true);

      apiAgent.Account.getLoginModel()
        .then((response: LoginModel) => {
          let oLoginModel: LoginModel = response;
          setLoginModel(oLoginModel);
          //setIsLoading(false);
          //console.log("loginModel obtained from apiAgent", oLoginModel);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          //console.log("Login Model is", loginModel);

          setIsLoading(false);
        });
    }

    //console.log("inside loginBox useEffect, emailAddress=", emailAddress);
  }, []);

  

  const onResetTwoFAClick = (e: any) => {
    setisResetTwoFA(true);
  };

  const onBackToTwoFA = (e: any) => {
    setisResetTwoFA(false);
  };

  const handleCodeSubmit = (values: AuthCodeFormValues) => {
    //console.log("inside handleCodeSubmit, values:", values.authCode + "emailAddress:" + emailAddress + "isRemember:" + strIsRemember);
    apiAgent.Account.verify2FACode(values.authCode,emailAddress!,strIsRemember!)
    .then((response: any) => {
        //console.log("inside handleCodeSubmit, response:", response);
        if(response){
            // userStore.setTwoFAToken(response.token);
            // userStore.setTwoFASuccess(true);
            //console.log("inside handleCodeSubmit after response, response:", response);
        const session: UserSession = response.value;
          // Handle UserSession logic here
          //console.log("Login successful", response);
          userStore.login(session)
          .catch((error) => toast.error(error.response.data));
        }
        })
    .catch((error: any) => {
        console.log("inside handleCodeSubmit, error:", error);
        toast.error(error.response.data);
    });

    
  };

  const handleLostAccessToPhoneClick = () => {
    
    //console.log("inside handleResetTwoFASubmit, email:", emailAddress);
    //apiAgent.
    
    
      setProcessing(true);
      apiAgent.Account.lostAccessToPhone(emailAddress!)
      .then((response:any) => {
        
          //console.log("after submit in lostAccessToPhone: ", response);
          toast.info(response);
          
          
        
      })
      .catch((error:any) => toast.error(error.response))
        .finally(() => setProcessing(false));
    
    
  };

  const AuthCode = () => {
    return (
      <Formik
        initialValues={{
          authCode: "",
          
          //error: null,
        }}
        onSubmit={handleCodeSubmit}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form className="LoginForm" onSubmit={handleSubmit}>
            
              <div className="col-sm-12">
                <Field type="text" name="authCode" className="form-control" placeholder="Auth Code" />
                <div style={{ float: "right", marginTop: -10 }}>
                <a href="#" onClick={onResetTwoFAClick}>
                  <small>Lost Access to your phone?</small>
                </a>
              </div>
              </div>
            

            
            <div className="col-sm-12">
              {processing === true ? (
                <div className="btn submitButton">
                  <span className="fa fa-spin fa-spinner" /> Authenticating..
                </div>
              ) : (
                <button type="submit" className="btn submitButton">
                  Submit
                </button>
                
              )}

             
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const ResetTwoFA = () => {
    return (
      <Formik onSubmit={handleLostAccessToPhoneClick} initialValues={{ emailAddress: "", error: null }}>
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form className="ResetTwoFAForm" method="post" onSubmit={handleSubmit}>
            <div className="col-sm-12">
              <p style={{ marginLeft: -12 }}>Are you sure you want to disable Two-Factor Authentication? A confirmation link will be sent to your email address {loginModel?.emailAddress}</p>
              {/*You will receive an email with link to reset your password.*/}
            </div>

           

            <div className="col-sm-12">
              {processing === true ? (
                <div className="btn submitButton">
                  <span className="fa fa-spin fa-spinner" /> Processing..
                </div>
              ) : (
                <button type="submit" className="btn submitButton">
                  Yes, Send Email
                </button>
              )}

              <div className="col-sm-12">
                <small>
                  If you don't get an email from us within a few minutes please be sure to check your spam filter. You will receive an email from{" "}
                  <b>noreply@base.entegral.net</b>.
                </small>
              </div>

              <div className="text-center">
                <br />
                <a style={{ cursor: "pointer" }} href="#" onClick={onBackToTwoFA}>
                  Two-factor authentication
                </a>
                <br />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading && loginModel) {
    //console.log("Before return: Login Model is", loginModel);
    return (
      <div className="row loginbox">
        <div className="col-lg-12 text-center">
          <span className="signIntext">{isResetTwoFA ? "Lost Access to your phone?" : "Two-factor authentication"}</span>
          <br />
          <br />
        </div>
        <div id="content">{isResetTwoFA ? <ResetTwoFA /> : <AuthCode />}</div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default AuthCodeBox;
