export default class  PiplelineDashboardFilterModel {
  public WorkspaceAgentId: string;
  public WorkspaceAgentName: string;
  public WorkspacePipelineId: string;
  public WorkspacePipeline: string;
  public AgentId: string;
  public AgentName: string;
  public PipelineId: string;
  public Pipeline: string;
  public StageId: string;
  public Stage: string;
  public RegistrationEndTo: string;
  public RegistrationStartFrom: string;
  public RegistrationEndToDT?: Date;
  public RegistrationStartFromDT?: Date;
  public Areas: string[];
  public PropertyTypes: string[];
  public NeedUpdate: boolean;
  public InternalDealTags: string[];
  public View:number;

  public constructor() {
    this.WorkspaceAgentId = "";
    this.WorkspaceAgentName = "";
    this.WorkspacePipelineId = "";
    this.WorkspacePipeline = "";
    this.AgentId = "";
    this.AgentName = "";
    this.PipelineId = "";
    this.Pipeline = "";
    this.StageId = "";
    this.Stage = "";
    this.RegistrationEndTo = "";
    this.RegistrationStartFrom = "";
    this.RegistrationEndToDT = undefined;
    this.RegistrationStartFromDT = undefined;
    this.Areas = [];
    this.PropertyTypes = [];
    this.NeedUpdate = false;
    this.InternalDealTags = [];
    this.View = 1;
  }

  public getCopy(){

    var copyObj = new PiplelineDashboardFilterModel();
    copyObj.WorkspaceAgentId = this.WorkspaceAgentId;
    copyObj.WorkspaceAgentName = this.WorkspaceAgentName;
    copyObj.WorkspacePipelineId = this.WorkspacePipelineId;
    copyObj.WorkspacePipeline = this.WorkspacePipeline;
    copyObj.AgentId = this.AgentId;
    copyObj.AgentName = this.AgentName;
    copyObj.PipelineId = this.PipelineId;
    copyObj.Pipeline = this.Pipeline;
    copyObj.StageId = this.StageId;
    copyObj.Stage = this.Stage;
    copyObj.RegistrationEndTo = this.RegistrationEndTo;
    copyObj.RegistrationStartFrom = this.RegistrationStartFrom;
    copyObj.RegistrationEndToDT = undefined;
    copyObj.RegistrationStartFromDT = undefined;
    copyObj.Areas = [...this.Areas];
    copyObj.PropertyTypes = [...this.PropertyTypes];
    copyObj.NeedUpdate = this.NeedUpdate;
    copyObj.InternalDealTags = [...this.InternalDealTags];
    copyObj.View = this.View;


    return copyObj;
  }

  public getCopyWithData(dataObj: any){
   
    var copyObj = new PiplelineDashboardFilterModel();
    copyObj.WorkspaceAgentId = dataObj.WorkspaceAgentId;
    copyObj.WorkspaceAgentName = dataObj.WorkspaceAgentName;
    copyObj.WorkspacePipelineId = dataObj.WorkspacePipelineId;
    copyObj.WorkspacePipeline = dataObj.WorkspacePipeline;
    copyObj.AgentId = dataObj.AgentId;
    copyObj.AgentName = dataObj.AgentName;
    copyObj.PipelineId = dataObj.PipelineId;
    copyObj.Pipeline = dataObj.Pipeline;
    copyObj.StageId = dataObj.StageId;
    copyObj.Stage = dataObj.Stage;
    copyObj.RegistrationEndTo = dataObj.RegistrationEndTo;
    copyObj.RegistrationStartFrom = dataObj.RegistrationStartFrom;
    copyObj.RegistrationEndToDT = undefined;
    copyObj.RegistrationStartFromDT = undefined;
    copyObj.Areas = [...dataObj.Areas];
    copyObj.PropertyTypes = [...dataObj.PropertyTypes];
    copyObj.NeedUpdate = dataObj.NeedUpdate;
    copyObj.InternalDealTags = Array.isArray(dataObj.DealTags) 
    ? [...dataObj.DealTags] 
    : [];
    copyObj.View = dataObj.DashboardView;
    return copyObj;
  }

  public setData(workspaceAgentId: string, workspaceAgentName: string, workspacePipelineId: string, workspacePipeline: string, agentId: string, agentName: string, pipelineId: string, pipeline: string, registrationStartFrom: string, registrationEndTo: string, areas: string[], propertyTypes: string[], needUpdate: boolean, stageId: string, stage: string, InternalDealTags: string[],View:number){
    this.WorkspaceAgentId = workspaceAgentId;
    this.WorkspaceAgentName = workspaceAgentName;
    this.WorkspacePipelineId = workspacePipelineId;
    this.WorkspacePipeline = workspacePipeline;
    this.AgentId = agentId;
    this.AgentName = agentName;
    this.PipelineId = pipelineId;
    this.Pipeline = pipeline;
    this.StageId = stageId;
    this.Stage = stage;
    this.RegistrationEndTo = registrationEndTo;
    this.RegistrationStartFrom = registrationStartFrom;
    this.RegistrationEndToDT = undefined;
    this.RegistrationStartFromDT = undefined;
    this.Areas = areas;
    this.PropertyTypes = propertyTypes;
    this.NeedUpdate = needUpdate;
    this.InternalDealTags = InternalDealTags;
    this.View = View;
  }

  public getFilterString()
  {
    var filterText = "";

    if (this.Pipeline && this.PipelineId) {
      filterText = this.Pipeline + " | ";
    }

    if (this.Stage && this.StageId) {
      filterText += this.Stage + " | ";
    }    

    if (this.PropertyTypes !== undefined && this.PropertyTypes.length != 0) filterText = filterText + this.PropertyTypes.toString() + " | ";

    if (this.AgentId) filterText = filterText + this.AgentName + " | ";
    if (this.InternalDealTags !== undefined && this.InternalDealTags.length != 0) 
    {
      if(this.InternalDealTags.length>=1 && this.InternalDealTags.length<=2){
        filterText = filterText + this.InternalDealTags.toString() + " | ";
      
      }
      else{
        filterText = filterText + "Deal Tag(s)" + " | ";

      }      
    }

    if (this.Areas !== undefined && this.Areas.length > 0) {
      if (this.Areas.length == 1) filterText = filterText + this.Areas[0] + " | ";
      else filterText = filterText + "Area(s) | ";
    }
    if (this.RegistrationStartFrom && this.RegistrationEndTo) {
      filterText = filterText + "Expected between " + this.RegistrationStartFrom + " to " + this.RegistrationEndTo + " | ";
    } else if (this.RegistrationStartFrom) {
      filterText = filterText + "Expected from " + this.RegistrationStartFrom + " | ";
    } else if (this.RegistrationEndTo) {
      filterText = filterText + "Expected to " + this.RegistrationEndTo + " | ";
    }
    if (filterText != "") filterText = filterText.slice(0, filterText.length - 3);

    return filterText;
  }

  public getFilterStringFull()
  {
    var filterText = this.getFilterString();

    if(filterText == "")
      filterText = "Select filter";
     
    return filterText;
  }

  public getPipelineFilterString()
  {
    var filterText = "";

    if (this.PropertyTypes !== undefined && this.PropertyTypes.length != 0) filterText = filterText + this.PropertyTypes.toString() + " | ";

    if (this.Areas !== undefined && this.Areas.length > 0) {
      if (this.Areas.length == 1) filterText = filterText + this.Areas[0] + " | ";
      else filterText = filterText + "Area(s) | ";
    }
    
    if(this.InternalDealTags!==undefined && this.InternalDealTags.length>0)
    {
      if(this.InternalDealTags.length>=1 && this.InternalDealTags.length<=2)
      {
        filterText = filterText + this.InternalDealTags.toString()+" | "
      }
      else
      {
        filterText = filterText + "Deal Tag(s)"+" | "
        
      }
      
    }
    if (this.RegistrationStartFrom && this.RegistrationEndTo) {
      filterText = filterText + "Expected between " + this.RegistrationStartFrom + " to " + this.RegistrationEndTo + " | ";
    } else if (this.RegistrationStartFrom) {
      filterText = filterText + "Expected from " + this.RegistrationStartFrom + " | ";
    } else if (this.RegistrationEndTo) {
      filterText = filterText + "Expected to " + this.RegistrationEndTo + " | ";
    }
    if (filterText != "") filterText = filterText.slice(0, filterText.length - 3);

    return filterText;
  }

  public getPipelineFilterStringFull()
  {
    var filterText = this.getPipelineFilterString();

    if(filterText == "")
      filterText = "Select filter";
     
    return filterText;
  }

}