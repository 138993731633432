import React, { useEffect, useMemo, useState, useRef } from "react";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ClipLoader } from "react-spinners";

type GroupBy = "Day" | "Week" | "Month" | "Year";

interface PortalLeadsView {
  date: string;
  portalUrl: string;
  leads: number;
  views: number;
}

interface SummaryLeadsView {
  date: string;
  leads: number;
  views: number;
}

interface PortalState {
  name: string;
  color: string;
  visible: boolean;
  totalViews: number;
  totalLeads: number;
}

interface TransformedData {
  date: string;
  totalLeads?: number;
  leads?: number;
  views?: number;
  [key: string]: string | number | undefined;
}

interface TrendsTabContentProps {
  portalLeadsViews: PortalLeadsView[];
  summaryLeadsViews: SummaryLeadsView[];
  webRef?: string;
  officeName?: string;
  loading?: boolean;
}

interface TooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const TrendsTabContent: React.FC<TrendsTabContentProps> = ({ portalLeadsViews, summaryLeadsViews, webRef, officeName, loading = false }) => {
  const [isPerPortal, setIsPerPortal] = useState<boolean>(true);
  const [portalsState, setPortalsState] = useState<PortalState[]>([]);
  const [groupBy, setGroupBy] = useState<GroupBy>("Day");
  const [showLeadsLine, setShowLeadsLine] = useState<boolean>(true);
  const chartRef = useRef<HTMLDivElement>(null);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState<boolean>(false);

  const getWeek = (date: string): string => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const firstDay = new Date(d);
    firstDay.setDate(d.getDate() - d.getDay());
    const day = firstDay.getDate().toString().padStart(2, "0");
    const month = (firstDay.getMonth() + 1).toString().padStart(2, "0");
    const year = firstDay.getFullYear();
    return `Week of ${day}-${month}-${year}`;
  };

  const getMonth = (date: string): string => new Date(date).toLocaleString("default", { month: "short" });

  const getYear = (date: string): string => new Date(date).getFullYear().toString();

  const groupData = (data: TransformedData[]): TransformedData[] => {
    if (!data.length) return [];
    const groupMap = new Map<string, TransformedData>();
    data.forEach((item) => {
      let groupKey: string;
      switch (groupBy) {
        case "Week":
          groupKey = getWeek(item.date);
          break;
        case "Month":
          groupKey = getMonth(item.date);
          break;
        case "Year":
          groupKey = getYear(item.date);
          break;
        default:
          groupKey = item.date;
      }
      if (!groupMap.has(groupKey)) {
        groupMap.set(groupKey, { date: groupKey });
      }
      const group = groupMap.get(groupKey)!;
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "date" && typeof value === "number") {
          group[key] = ((group[key] as number) || 0) + value;
        }
      });
    });
    return Array.from(groupMap.values());
  };

  const getColors = (count: number, portals: string[]): string[] => {
    const portalColors = new Map([
      ["ananzi", "#c4be00"],
      ["flowliving", "#b5c7a3"],
      ["findmy", "#146fc9"],
      ["gumtree", "#33a900"],
      ["housefindernam", "#c7c789"],
      ["immoafrica", "#ba005a"],
      ["iolproperty", "#ccaa76"],
      ["jamesedition", "#afacb3"],
      ["junkmail", "#d18a7b"],
      ["myproperty", "#f28d00"],
      ["pocketproperty", "#970ec4"],
      ["privateproperty", "#c70028"],
      ["property24", "#0054a6"],
      ["propertycentral", "#c758bc"],
      ["propertymatcher", "#6fb6c6"],
      ["qwengo", "#9bcf67"],
      ["rightmove", "#48c7c3"],
    ]);

    const colors: string[] = [];
    portals.forEach((portal) => {
      const portalLower = portal.toLowerCase();
      let colorAssigned = false;
      
      const entries = Array.from(portalColors.entries());
      for (const [key, color] of entries) {
        if (portalLower.includes(key)) {
          colors.push(color);
          colorAssigned = true;
          break;
        }
      }
      if (!colorAssigned) {
        colors.push("#abb0b3");
      }
    });

    return colors;
  };

  useEffect(() => {
    if (Array.isArray(portalLeadsViews) && portalLeadsViews.length > 0) {
      const uniquePortals = Array.from(new Set(portalLeadsViews.map((item) => item.portalUrl)));
      const colors = getColors(uniquePortals.length, uniquePortals);
      const portalStats = uniquePortals.map((portal, index) => ({
        name: portal,
        color: colors[index],
        visible: true,
        totalViews: portalLeadsViews.filter((it) => it.portalUrl === portal).reduce((sum, it) => sum + it.views, 0),
        totalLeads: portalLeadsViews.filter((it) => it.portalUrl === portal).reduce((sum, it) => sum + it.leads, 0),
      }));
      setPortalsState(portalStats);
    }
  }, [portalLeadsViews]);

  const transformedData = useMemo<TransformedData[]>(() => {
    if (!Array.isArray(portalLeadsViews) || !Array.isArray(summaryLeadsViews)) {
      return [];
    }

    let data: TransformedData[];
    if (isPerPortal) {
      const dateMap = new Map<string, TransformedData>();
      portalLeadsViews.forEach((item) => {
        if (!dateMap.has(item.date)) {
          dateMap.set(item.date, { date: item.date, totalLeads: 0 });
        }
        const dateEntry = dateMap.get(item.date)!;
        dateEntry[`${item.portalUrl}_views`] = item.views;
        dateEntry.totalLeads = (dateEntry.totalLeads || 0) + item.leads;
      });
      data = Array.from(dateMap.values());
    } else {
      data = summaryLeadsViews.map((item) => ({
        date: item.date,
        views: item.views,
        leads: item.leads,
      }));
    }

    return groupData(data);
  }, [portalLeadsViews, summaryLeadsViews, isPerPortal, groupBy]);

  const hasData = (): boolean => {
    if (isPerPortal) {
      return Array.isArray(portalLeadsViews) && portalLeadsViews.length > 0;
    }
    return Array.isArray(summaryLeadsViews) && summaryLeadsViews.length > 0;
  };

  const handleLeadsPerPortalsChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsPerPortal(e.target.checked);
  };

  const togglePortalVisibility = (portalName: string): void => {
    setPortalsState((prevState) => prevState.map((portal) => (portal.name === portalName ? { ...portal, visible: !portal.visible } : portal)));
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDownloadPDF = async (): Promise<void> => {
    if (!chartRef.current) return;
  
    setIsDownloadingPdf(true);
  
    setTimeout(async () => {
      try {
        if (!chartRef.current) {
          setIsDownloadingPdf(false);
          return;
        }
  
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: "a4",
          compress: true,
        });
  
        pdf.setFontSize(16);
        pdf.setFont("helvetica", "bold");
        pdf.text(officeName || "", 15, 15);
  
        pdf.setFontSize(11);
        pdf.setFont("helvetica", "normal");
  
        const dates = isPerPortal ? portalLeadsViews.map((item) => item.date) : summaryLeadsViews.map((item) => item.date);
        const startDate = dates.length ? formatDate(new Date(Math.min(...dates.map((date) => new Date(date).getTime()))).toISOString()) : "N/A";
        const endDate = dates.length ? formatDate(new Date(Math.max(...dates.map((date) => new Date(date).getTime()))).toISOString()) : "N/A";
  
        const headerText = `Listing views & leads generated on portals for #${webRef} between ${startDate} and ${endDate}`;
        pdf.text(headerText, 15, 25);
  
        const tempContainer = document.createElement("div");
        tempContainer.style.backgroundColor = "white";
        tempContainer.style.display = "flex";
        tempContainer.style.flexDirection = "column";
        tempContainer.style.alignItems = "center";
        tempContainer.style.justifyContent = "space-between";
        tempContainer.style.padding = "10px";
        tempContainer.style.width = "1400px";
        tempContainer.style.height = "900px";
  
        if (isPerPortal) {
          const legendsDiv = document.createElement("div");
          legendsDiv.style.display = "flex";
          legendsDiv.style.flexWrap = "wrap";
          legendsDiv.style.justifyContent = "center";
          legendsDiv.style.gap = "10px";
          legendsDiv.style.marginBottom = "10px";
  
          portalsState.forEach((portal) => {
            const legendItem = document.createElement("div");
            legendItem.style.display = "flex";
            legendItem.style.alignItems = "center";
            legendItem.style.opacity = portal.visible ? "1" : "0.5";
  
            const colorBox = document.createElement("div");
            colorBox.style.width = "14px";
            colorBox.style.height = "14px";
            colorBox.style.backgroundColor = portal.color;
            colorBox.style.marginRight = "8px";
            colorBox.style.borderRadius = "2px";
  
            const text = document.createElement("span");
            text.style.fontSize = "12px";
            text.style.color = "#333";
            text.textContent = `${portal.name} (Views: ${portal.totalViews}${showLeadsLine ? `, Leads: ${portal.totalLeads}` : ""})`;
  
            legendItem.appendChild(colorBox);
            legendItem.appendChild(text);
            legendsDiv.appendChild(legendItem);
          });
  
          tempContainer.appendChild(legendsDiv);
        }
  
        const chartWrapper = document.createElement("div");
        chartWrapper.style.display = "flex";
        chartWrapper.style.justifyContent = "center";
        chartWrapper.style.alignItems = "center";
        chartWrapper.style.width = "100%";
        chartWrapper.style.flexGrow = "1";
  
        const chartClone = chartRef.current.cloneNode(true) as HTMLElement;
        chartClone.style.width = "1200px";
        chartClone.style.height = "700px";
  
        chartWrapper.appendChild(chartClone);
        tempContainer.appendChild(chartWrapper);
  
        document.body.appendChild(tempContainer);
  
        const canvas = await html2canvas(tempContainer, {
          backgroundColor: "white",
          scale: 2.5,
          logging: false,
          useCORS: true,
        });
  
        document.body.removeChild(tempContainer);
  
        const chartImage = canvas.toDataURL("image/png", 1.0);
        pdf.addImage(chartImage, "PNG", 10, 30, 277, 190);
        pdf.save(`listing_statistics_${webRef}.pdf`);
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        setIsDownloadingPdf(false);
      }
    }, 0);
  };
    
  const CustomLegend: React.FC = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 0",
        gap: "24px",
        flexWrap: "wrap",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      {portalsState.map((portal) => (
        <div
          key={portal.name}
          onClick={() => togglePortalVisibility(portal.name)}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            userSelect: "none",
            opacity: portal.visible ? 1 : 0.5,
            textDecoration: portal.visible ? "none" : "line-through",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <div
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: portal.color,
                marginRight: "4px",
                borderRadius: "2px",
              }}
            />
            <span style={{ fontSize: "14px", color: "#333" }}>
              {portal.name} (Views: {portal.totalViews}
              {showLeadsLine && `, Leads: ${portal.totalLeads}`})
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <p style={{ margin: "0 0 4px 0", fontWeight: 500 }}>{label}</p>
        {payload.map((entry) => {
          const isLeads = entry.dataKey === "totalLeads" || entry.dataKey === "leads";
          const portalName = isPerPortal ? (isLeads ? "Total Leads" : entry.dataKey.replace("_views", "")) : isLeads ? "Leads" : "Views";

          return (
            <p
              key={entry.dataKey}
              style={{
                color: entry.color,
                margin: "2px 0",
              }}
            >
              {`${portalName} ${isLeads ? "Leads" : "Views"}: ${entry.value}`}
            </p>
          );
        })}
      </div>
    );
  };

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 600;
  const chartMargins = isMobile
    ? { top: 20, right: 10, left: 10, bottom: 40 }
    : { top: 20, right: 60, left: 30, bottom: 40 };

  const renderChartContent = (): JSX.Element => {
    if (loading) {
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f9f9f9",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div style={{ marginTop: "10px", color: "#666", fontSize: "16px" }}>Loading data...</div>
          </div>
        </div>
      );
    }

    if (!hasData()) {
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f9f9f9",
            border: "1px solid #ddd",
            borderRadius: "4px",
            color: "#666",
            fontSize: "16px",
          }}
        >
          No Leads & Views available for the selected date range
        </div>
      );
    }

    return (
      <ResponsiveContainer>
        <ComposedChart data={transformedData} margin={chartMargins}>
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
          <XAxis dataKey="date" angle={-45} textAnchor="end" height={60} tickMargin={10} tick={{ fontSize: 12 }} />
          <YAxis yAxisId="left" tickFormatter={(value: number) => Math.round(value).toString()} allowDecimals={false} tick={{ fontSize: 12 }} />
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={(value: number) => Math.round(value).toString()}
            allowDecimals={false}
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          {isPerPortal ? (
            <>
              {portalsState
                .filter((portal) => portal.visible)
                .map((portal) => (
                  <Bar key={portal.name} yAxisId="left" dataKey={`${portal.name}_views`} fill={portal.color} name={`${portal.name} Views`} stackId="views" />
                ))}
              {showLeadsLine && (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="totalLeads"
                  stroke="#5F6278"
                  name="Total Leads"
                  dot={{ fill: "#5F6278", r: 6 }}
                  strokeWidth={3}
                />
              )}
            </>
          ) : (
            <>
              <Bar yAxisId="left" dataKey="views" fill="#4169E1" name="Views" barSize={40} />
              {showLeadsLine && (
                <Line yAxisId="right" type="monotone" dataKey="leads" stroke="#5F6278" name="Leads" dot={{ fill: "#5F6278", r: 6 }} strokeWidth={3} />
              )}
            </>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div style={{ padding: "10px 0 0 0" }}>
      <div style={{ marginBottom: "10px" }}>
        <div style={{ position: "relative", zIndex: 2 }}>
          <div className="control-box">
            <div className="control-box--left">
              <label style={{ display: "flex", alignItems: "center" }}>
                <div className="switch" style={{ position: "relative", marginRight: "10px" }}>
                  <input type="checkbox" id="LeadsPerPortals" name="LeadsPerPortals" checked={isPerPortal} onChange={handleLeadsPerPortalsChanged} />
                  <div className="slider round" />
                </div>
                <span style={{ fontSize: "14px" }}>{isPerPortal ? "Per Portal View" : "Summary view"}</span>
              </label>

              <label style={{ display: "flex", alignItems: "center" }}>
                <div className="switch" style={{ position: "relative", marginRight: "10px" }}>
                  <input type="checkbox" id="ShowLeadsLine" name="ShowLeadsLine" checked={showLeadsLine} onChange={(e) => setShowLeadsLine(e.target.checked)} />
                  <div className="slider round" />
                </div>
                <span style={{ fontSize: "14px" }}>Show Leads</span>
              </label>
            </div>
            <div className="control-box--right">
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <label htmlFor="groupBy" style={{ fontSize: "14px" }}>
                  Group Data By:
                </label>
                <select
                  id="groupBy"
                  value={groupBy}
                  onChange={(e) => setGroupBy(e.target.value as GroupBy)}
                  style={{
                    padding: "4px 8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    minWidth: "120px",
                  }}
                >
                  <option value="Day">Day</option>
                  <option value="Week">Week</option>
                  <option value="Month">Month</option>
                  <option value="Year">Year</option>
                </select>
              </div>

              <button
                onClick={handleDownloadPDF}
                disabled={isDownloadingPdf}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#2196F3",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: isDownloadingPdf ? "default" : "pointer",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  whiteSpace: "nowrap",
                }}
                onMouseOver={(e) => !isDownloadingPdf && (e.currentTarget.style.backgroundColor = "#1976D2")}
                onMouseOut={(e) => !isDownloadingPdf && (e.currentTarget.style.backgroundColor = "#2196F3")}
              >
                {isDownloadingPdf ? (
                  <>
                    <ClipLoader size={14} color="#ffffff" />
                    <span>Downloading...</span>
                  </>
                ) : (
                  "Download PDF"
                )}
              </button>
            </div>
          </div>
        </div>

        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <div style={{ fontSize: "16px", color: "#333" }}>Property Views & Leads Statistics #{webRef}</div>
        </div>

        {isPerPortal && hasData() && <CustomLegend />}

        <div className="chart-container" ref={chartRef} style={{ width: "100%", height: 400, marginBottom: 0 }}>
          {renderChartContent()}
        </div>
      </div>

      <style>
        {`
          .control-box { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; }
          .control-box--left { display: flex; align-items: center; gap: 20px; }
          .control-box--right { display: flex; align-items: center; gap: 20px; }
          @media (max-width: 991px) {
            .control-box { flex-direction: column; gap: 10px; }
            .control-box--left { justify-content: space-between; gap: 50px; }
            .control-box--right { margin-bottom: 15px; justify-content: space-between; gap: 20px; }
          }
          .switch {
            width: 60px;
            min-width: 60px;
            height: 34px;
            display: inline-block;
          }
          
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
            border-radius: 34px;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: .4s;
            border-radius: 50%;
          }

          input:checked + .slider {
            background-color: #2196F3;
          }

          input:checked + .slider:before {
            transform: translateX(26px);
          }

          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }

          .spinner-border {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            vertical-align: text-bottom;
            border: 0.25em solid currentColor;
            border-right-color: transparent;
            border-radius: 50%;
            animation: spinner-border .75s linear infinite;
          }

          @keyframes spinner-border {
            to { transform: rotate(360deg); }
          }

          .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0,0,0,0);
            border: 0;
          }

          @media (max-width: 600px) {
            .chart-container {
              padding: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default TrendsTabContent;
