import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Header from "../header/Header";
import logo from "../../assets/images/base-login.png";
import Dashboard from "./Dashboard";
import HeaderWrapper from "../header/HeaderWrapper";
// import "../../assets/styles/components/_property.scss";
//import "../../assets/styles/components/_home.scss";

import { ContactsResponseModel } from "../../app/models/ContactsResponseModel";
import apiAgent from "../../app/api/apiAgent";
import { HomeResponseModel } from "../../app/models/HomeResponseModel";
import $ from "jquery";
import { BeatLoader } from "react-spinners";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";

interface Props { }


export default observer(function DashboardPage(props: any) {
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<HomeResponseModel>();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  document.title = "Base - Home";

  useEffect(() => {
    if (isLoggedIn) {
      const params = new URLSearchParams(window.location.search);
      if (params.has("ref") && params.get("ref")) {
        const refParams = params.get("ref");
        let ref = refParams !== null ? refParams : "";
        apiAgent.Home.getPropertyIDFromWebRefAjax(ref)
          .then((res) => {
            //console.log(res);
            if (res.propertyID) {
              props.history.push(`/Property/SaveProperty?ID=${res.propertyID}`);
            }
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
          });
      }
    }
  }, []);

  useEffect(() => {
    // window.$(function () {
    //   window.$("[rel='tooltip']").tooltip();
    // });
    //console.log("Inside Home: is logged in", isLoggedIn);

    if (isLoggedIn) {
      setIsLoading(true);
      apiAgent.Home.getHomePage()
        .then((response: HomeResponseModel) => {
          let oData: HomeResponseModel = response;
          setDashboardData(oData);
          //console.log("home page data obtained from API", oData);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

  }, [userSession, isLoggedIn]);

  useEffect(() => {
    //console.log("Inside Home: is logged in", isLoggedIn);
    const redirectIfNotLoggedIn = async () => {
      if (!isLoggedIn) {
        return <Redirect to="/login" />;
      }
    };
    redirectIfNotLoggedIn();

  }, [userSession, isLoggedIn]);

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  } else if (isLoading) {
    return (

      <AjaxLoader />
    );
  } else if (dashboardData !== undefined) {
    return (
      <>
        <HeaderWrapper />

        <div className="dashboard-page container-fluid">
          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              <Dashboard dashboardData={dashboardData} />
            </div>
          </div>
        </div>
      </>
    );
  } else return <></>;
});
