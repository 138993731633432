import React from "react";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import apiAgent from "../../../app/api/apiAgent";

export class DocumentFileComponent extends React.Component {
  state = {
    dcFileID: "",
    dcFiles: [],
    Documents: this.props.data,
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => { },
      title: "",
      body: "",
    },
  };

  componentDidMount() { }

  doSetState = (data) => {
    this.setState({ Documents: data });
  };

  onDownloadFile = (fileName) => {
    apiAgent.Property.DownloadMandateFile(fileName, this.props.officeSyncID)
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = downloadUrl;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Failed to download file:", error);
      });
  };

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  handleAddDocumentClick = () => {
    window.$("#dcUploadPhoto").click();
  };

  handleUploadDocumentChange = (e) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files === undefined || files.length == 0) return;

    const validFiles = Array.from(files).filter((file) => {
      const fileicon = this.getFileIcon(file.name);
      return fileicon !== "";
    });

    if (validFiles.length !== files.length) {
      toast.info("Some files were skipped. Only jpg/jpeg, png, pdf, txt, xls/xlsx, doc/docx and ppt/pptx files are allowed!");
    }

    if (validFiles.length > 0) {
      const dcFiles = validFiles.map((file) => ({
        file: file,
        description: file.name,
        isRestrictAccess: true,
        fileicon: this.getFileIcon(file.name),
      }));

      this.setState({ dcFiles });
      window.$("#DCModal").modal("show");
    }

    window.$("#dcUploadPhoto").val("");
  };

  getFileIcon = (fileName) => {
    var fileicon = "";
    if (fileName.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) fileicon = "fa-file-photo-o";
    else if (fileName.match(/\.(pdf|PDF)$/)) fileicon = "fa-file-pdf-o";
    else if (fileName.match(/\.(txt|TXT)$/)) fileicon = "fa-file-text-o";
    else if (fileName.match(/\.(csv|xls|xlsx|CSV|XLS|XLSX)$/)) fileicon = "fa-file-excel-o";
    else if (fileName.match(/\.(ppt|pptx|PPT|PPTX)$/)) fileicon = "fa-file-powerpoint-o";
    else if (fileName.match(/\.(doc|docx|DOC|DOCX)$/)) fileicon = "fa-file-word-o";
    else fileicon = "";

    return fileicon;
  };

  formatBytes = (bytes, decimals) => {
    if (bytes == 0) return "0 Bytes";
    var k = 1024,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleUpdateDocumentLinkClick = (FileID) => {
    var index = -1;
    var files = this.state.Documents;
    for (var i = 0; i < files.length; i++) {
      if (files[i].fileID == FileID) {
        index = i;
        break;
      }
    }

    this.setState({
      dcFileID: files[index].fileID,
      dcFiles: [
        {
          file: files[index].file,
          description: files[index].description,
          isRestrictAccess: files[index].isRestrictAccess,
          fileicon: files[index].fileicon,
        },
      ],
    });

    window.$("#DCModal").modal("show");
  };

  handleFileChange = (index, field, value) => {
    this.setState((prevState) => {
      const newDcFiles = [...prevState.dcFiles];
      newDcFiles[index] = { ...newDcFiles[index], [field]: value };
      return { dcFiles: newDcFiles };
    });
  };

  onSaveDocumentClick = () => {
    const { dcFileID = "", dcFiles = [], Documents = [] } = this.state;

    const mapFileToDocument = (file, overrides = {}) => ({
      fileID: overrides.fileID || "NAP" + new Date().getTime(),
      description: file.description,
      size: overrides.size || this.formatBytes(file.file.size),
      fileName: overrides.fileName || file.file.name,
      file: file.file || "",
      fileicon: overrides.fileicon || file.fileicon,
      order: 1,
      isRestrictAccess: file.isRestrictAccess,
      uploadedTime: overrides.uploadedTime || "-",
      url: overrides.url || "",
    });



    if (dcFileID) {
      const SelectedDocument = Documents.find((doc) => doc.fileID === dcFileID);


      //console.log("SelectedDocument :: ", SelectedDocument);


      const updateFiles = this.state.dcFiles.map((file) => {


        //console.log("File ::: ", file);

        return (
          {
            fileID: this.state.dcFileID,
            description: file.description,
            size: SelectedDocument.size,
            fileName: SelectedDocument.fileName,
            fileicon: SelectedDocument.fileicon,
            order: 1,
            isRestrictAccess: file.isRestrictAccess,
            uploadedTime: SelectedDocument.uploadedTime,
            url: SelectedDocument.url
          }
        )
      });

      // Update existing document
      this.setState((prevState) => ({
        Documents: prevState.Documents.map((doc) =>
          doc.fileID === this.state.dcFileID ? updateFiles[0] : doc
        ),
        dcFiles: [],
        dcFileID: "",
      }),
        () => {
          //console.log("Call Back this.state.Documents ", this.state.Documents);

          window.$("#DCModal").modal("hide");

          if (this.props.onChange)
            this.props.onChange(this.state.Documents);
        });


    } else {

      const newFiles = dcFiles.map((file) => ({
        fileID: this.state.dcFileID || "NAP" + new Date().getTime(),
        description: file.description,
        size: this.formatBytes(file.file.size),
        fileName: file.file.name,
        file: file.file,
        fileicon: file.fileicon,
        order: 1,
        isRestrictAccess: file.isRestrictAccess,
        uploadedTime: "-",
      }));


      // Check if the document already exists by its fileName
      const updatedDocuments = [...Documents];

      newFiles.forEach((newFile) => {
        if (!updatedDocuments.some((doc) => doc.fileName === newFile.fileName)) {
          updatedDocuments.push(newFile);  // Only push if it's a new file
        }
      });

      this.setState({
        Documents: updatedDocuments,
        dcFiles: [],
      });
      //console.log(" this.state.dcFileID ", updatedDocuments);


      window.$("#DCModal").modal("hide");

      if (this.props.onChange)
        this.props.onChange(this.state.Documents);
      if (this.props.onUpload && !this.state.dcFileID)
        this.props.onUpload(newFiles);
    }


  };

  executeDeleteDocumentClick = () => {
    const updatedDocuments = this.state.Documents.filter((file) => file.fileID !== this.state.dcFileID);
    const fileToDelete = this.state.Documents.find((file) => file.fileID === this.state.dcFileID);
    if (fileToDelete) {
      if (this.props.propertyID) {
        apiAgent.S3Helper.deleteListingMandateFilesFromS3(fileToDelete.fileName, this.props.propertyID, this.props.officeSyncID);
      }
    }

    this.setState({
      Documents: updatedDocuments,
      dcFileID: "",
      dcFiles: [],
    });

    window.$("#DCModal").modal("hide");

    if (this.props.onChange !== undefined) this.props.onChange(updatedDocuments);
  };

  onDeleteDocumentClick = () => {
    let title = "Delete document?";
    let body = "Do you want to delete this document now?";
    let callback = this.executeDeleteDocumentClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  render() {
    return (
      <div style={{ marginLeft: 5, marginRight: 5 }}>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div className="col-md-12 col-xs-12">
          <div className="pull-right">
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.txt,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
              id="dcUploadPhoto"
              name="dcUploadPhoto"
              style={{ display: "none" }}
              onChange={this.handleUploadDocumentChange}
              multiple={true}
            />
            {this.props.save_access ? (
              <button style={{ marginTop: 5, marginBottom: 5 }} className="btn btn-success-oms" onClick={this.handleAddDocumentClick}>
                Add Documents
              </button>
            ) : null}
          </div>
        </div>
        <div className="col-md-12 col-xs-12">
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "60%" }}>File</th>
                <th style={{ width: "30%" }}>Uploaded On</th>
                <th style={{ textAlign: "right", width: "10%", minWidth: "25px" }}>Size</th>
              </tr>
            </thead>
            <tbody>
              {this.state.Documents.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="library_grid_title">
                      {item.uploadedTime != "-" && item.url ? (
                        <a
                          style={{ cursor: "pointer", marginRight: 15 }}
                          onClick={() => this.onDownloadFile(item.fileName)}
                          target="_blank"
                          title="open/download file"
                        >
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </a>
                      ) : (
                        <span style={{ marginRight: 15, color: "grey" }}>
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </span>
                      )}
                      <a style={{ cursor: "pointer" }} title="update description" onClick={() => this.handleUpdateDocumentLinkClick(item.fileID)}>
                        <i className={"fa " + item.fileicon} aria-hidden="true"></i>&nbsp;{item.description ? item.description : "No description added."}
                      </a>
                    </div>
                  </td>
                  <td>
                    <span>{item.uploadedTime}</span>
                  </td>
                  <td>
                    <span className="label label-default">{item.size}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div id="DCModal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="DCModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="DCModalLabel">
                  {this.state.dcFileID ? "Update document" : "Add documents"}
                </h4>
              </div>
              <div className="modal-body">
                {this.state.dcFiles.map((file, index) => (
                  <div key={index} className="row" style={{ marginBottom: "15px" }}>
                    <div className="form-group col-md-2 text-center">
                      <i className={"fa " + file.fileicon} aria-hidden="true" style={{ fontSize: 30 }}></i>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor={`dcDescription-${index}`}>Document Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id={`dcDescription-${index}`}
                        value={file.description}
                        onChange={(e) => this.handleFileChange(index, "description", e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <div className="checkbox">
                        <label style={{ fontWeight: "bold" }}>
                          <input
                            type="checkbox"
                            checked={file.isRestrictAccess}
                            onChange={(e) => this.handleFileChange(index, "isRestrictAccess", e.target.checked)}
                          />
                          Restrict Access
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="modal-footer">
                {this.state.dcFileID && this.props.save_access ? (
                  <div className="pull-left">
                    <a style={{ cursor: "pointer", display: "inline-block", paddingTop: 5 }} onClick={this.onDeleteDocumentClick}>
                      Delete
                    </a>
                  </div>
                ) : null}
                <a data-dismiss="modal" style={{ cursor: "pointer" }}>
                  Cancel
                </a>
                {this.props.save_access ? (
                  <button type="button" style={{ marginLeft: 10 }} className="btn btn-success-oms" onClick={this.onSaveDocumentClick}>
                    Save
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
