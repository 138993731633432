import React, { useCallback, useState } from 'react';
import AutoSuggestInput from '../../../../app/common/common-components/AutoSuggestInput';


interface SuburbAutosuggestProps {
    value: string;
    onChange: (value: string) => void;
    provinceId: string;
    provinceSuburbsMap: Record<number, string>;
    className?: string;
}

const SuburbAutosuggest: React.FC<SuburbAutosuggestProps> = ({ 
    value, 
    onChange, 
    provinceId, 
    provinceSuburbsMap,
    className = 'form-control'
}) => {
    const [suggestions, setSuggestions] = useState<string[]>([]);

    const cleanSuburbText = (text: string): string => {
        const match = text.match(/"suburb":"([^"]+)"/);
        if (match) {
            return match[1];
        }
        return text;
    };

    const toTitleCase = (str: string): string => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const getSuburbsForProvince = useCallback(() => {
        if (!provinceId || !provinceSuburbsMap[parseInt(provinceId)]) return [];
        return provinceSuburbsMap[parseInt(provinceId)]
            .split(',')
            .map(s => s.trim())
            .map(cleanSuburbText)
            .map(toTitleCase);
    }, [provinceId, provinceSuburbsMap]);

    const handleSuggestionsFetchRequested = (inputValue: string) => {
        const suburbs = getSuburbsForProvince();
        const inputValueLower = inputValue.toLowerCase();
        const filteredSuggestions = suburbs.filter(suburb => 
            suburb.toLowerCase().includes(inputValueLower)
        );
        setSuggestions(filteredSuggestions);
    };

    return (
        <AutoSuggestInput
            value={value}
            onChange={onChange}
            suggestions={suggestions}
            placeholder="Enter Area"
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            renderSuggestion={(suggestion: string) => <div>{suggestion}</div>}
            className={className}
        />
    );
};

export default SuburbAutosuggest;