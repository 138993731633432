import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import TrendsTabContent from "./ListingLeadsComponents/TrendsTabContent";
import { OMSButtonStyleL } from "../../../../app/common/common-components/OMSButton";
import { OMSTabBox } from "../../../../app/common/common-components/OMSTabBox";
import { DataTableComp } from "../../../../app/common/common-components/OMSDatatables";
import DetailModalPopup from "../../../../app/common/common-components//DetailModalPopup";
import $ from 'jquery';

const processLeadData = (leadData) => {
  const processedData = {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  Object.entries(leadData).forEach(([key, value]) => {
    if (!key.endsWith('ID') && !key.endsWith('IDs') && value) {
      if (key === 'messageDetails') {
        try {
          const messageData = JSON.parse(value);
          Object.entries(messageData).forEach(([msgKey, msgValue]) => {
            if (!msgKey.endsWith('ID') && !msgKey.endsWith('IDs') && msgValue) {
              processedData[msgKey] = msgValue.toString();
            }
          });
        } catch (e) {
          console.error('Error parsing messageDetails:', e);
        }
      } else if (key === 'receivedDate') {
        processedData[key] = formatDate(value);
      } else {
        processedData[key] = value.toString();
      }
    }
  });

  return processedData;
};

const ListingLeadsModal = ({
  mViewReportFrom,
  mViewReportTo,
  mProcessing,
  handleListingLeadsRunClick,
  setViewReportFrom,
  setViewReportTo,
  handleLeadsCloseModal,
  portalLeadsViews,
  summaryLeadsViews,
  leadsDetails,
  webRef,
  officeName,
}) => {
  const [activeTabID, setActiveTabID] = useState("trends");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showDetailPopup, setShowDetailPopup] = useState(false);

  useEffect(() => {
    const tableId = "#leadsDetailsTable";
    
    const handleRowClick = function() {
      const rowData = $(tableId).DataTable().row(this).data();
      if (rowData) {
        const processedData = processLeadData(rowData);
        setSelectedLead(processedData);
        setShowDetailPopup(true);
      }
    };

    $(tableId).on('click', 'tbody tr', handleRowClick);

    return () => {
      $(tableId).off('click', 'tbody tr', handleRowClick);
    };
  }, [leadsDetails]);

  useEffect(() => {
    const modal = document.getElementById("listingLeadReportModal");

    const handleModalShow = () => {
      setIsModalOpen(true);
      if (mViewReportFrom && mViewReportTo) {
        handleListingLeadsRunClick();
      }
    };
    
    const handleModalHide = () => setIsModalOpen(false);

    modal?.addEventListener("shown.bs.modal", handleModalShow);
    modal?.addEventListener("hidden.bs.modal", handleModalHide);

    return () => {
      modal?.removeEventListener("shown.bs.modal", handleModalShow);
      modal?.removeEventListener("hidden.bs.modal", handleModalHide);
    };
  }, [mViewReportFrom, mViewReportTo, handleListingLeadsRunClick]);

  const columns = [
    { 
      title: "Date",
      data: "receivedDate",
      render: (data) => {
        const date = new Date(data);
        return `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`;
      }
    },
    { 
      title: "Referer",
      data: "source",
      render: (data) => data || ''
    },
    { 
      title: "Agents",
      data: "agents",
      defaultContent: ""
    },
    { 
      title: "Contact Name",
      data: "messageDetails",
      render: (data) => {
        try {
          const parsedData = JSON.parse(data);
          return parsedData.contactname || "";
        } catch {
          return "";
        }
      }
    }
  ];

  const handleClose = () => {
    setIsModalOpen(false);
    handleLeadsCloseModal();
  };

  const handleDateChange = (date, isFromDate) => {
    if (isFromDate) {
      setViewReportFrom(date || new Date());
    } else {
      setViewReportTo(date || new Date());
    }
  };

  const tabContent = [
    {
      link: "#trends",
      title: "Trends",
    },
    {
      link: "#leadsDetails",
      title: "Leads Details",
    },
  ];

  const renderLeadsDetailsContent = () => {
    return (
      <>
        <div style={{
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f9f9f9",
          border: "1px solid #ddd",
          borderRadius: "4px",
          visibility: mProcessing ? "visible" : "hidden",
          position: mProcessing ? "relative" : "absolute",
        }}>
          <div style={{ textAlign: "center" }}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div style={{ marginTop: "10px", color: "#666", fontSize: "16px" }}>
              Loading data...
            </div>
          </div>
        </div>
  
        <div style={{ visibility: mProcessing ? "hidden" : "visible" }}>
          <DataTableComp 
            id="leadsDetailsTable" 
            data={leadsDetails || []} 
            columns={columns} 
            columnDefs={[]} 
            showSearchBox={false}
          />
        </div>

        <DetailModalPopup
          show={showDetailPopup}
          onClose={() => setShowDetailPopup(false)}
          title="Lead Details"
          data={selectedLead}
        />
      </>
    );
  };

  return (
    <div className="modal fade" id="listingLeadReportModal" tabIndex={-1} aria-labelledby="listingLeadReportModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={handleClose}>
              ×
            </button>
            <h4 className="modal-title" id="listingLeadReportModalLabel">
              Listing Views & Leads
            </h4>
          </div>
          <div className="modal-body">
            <div className="row" style={{ marginTop: 10, position: "relative", zIndex: 3 }}>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <label>Date range</label>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <DatePicker
                  selected={mViewReportFrom}
                  onChange={(date) => handleDateChange(date, true)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="From Date"
                  name="mViewReportFromLeads"
                  id="mViewReportFromLeads"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control MandateDatePicker3"
                  isClearable={true}
                  popperProps={{
                    positionFixed: true,
                  }}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  popperClassName="datepicker-popper"
                />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <DatePicker
                  selected={mViewReportTo}
                  onChange={(date) => handleDateChange(date, false)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="To Date"
                  id="mViewReportToLeads"
                  name="mViewReportToLeads"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control MandateDatePicker3"
                  isClearable={true}
                  popperProps={{
                    positionFixed: true,
                  }}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  popperClassName="datepicker-popper"
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: 15, marginBottom: 0 }}>
              <div className="col-md-12">
                <OMSTabBox id="listingModalTabs" value={tabContent} setActiveTabId={setActiveTabID} />
                <div className="tab-content" style={{ marginBottom: 0 }}>
                  <div id="trends" className={activeTabID === "trends" ? "tab-pane fade in active" : "tab-pane fade"} style={{ padding: 0 }}>
                    <TrendsTabContent
                      portalLeadsViews={portalLeadsViews}
                      summaryLeadsViews={summaryLeadsViews}
                      webRef={webRef}
                      officeName={officeName}
                      loading={mProcessing}
                    />
                  </div>
                  <div id="leadsDetails" className={activeTabID === "leadsDetails" ? "tab-pane fade in active" : "tab-pane fade"} style={{ padding: "15px" }}>
                    {renderLeadsDetailsContent()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="pull-left">
              <a href="https://help.entegral.net/en/articles/5631079-listing-lead-reports" target="_blank" rel="noopener noreferrer">
                Help with listing views & leads
              </a>
            </div>
            <a style={{ marginRight: 20, cursor: "pointer" }} onClick={handleClose} data-dismiss="modal">
              Close
            </a>
            <OMSButtonStyleL
              type="button"
              ui_icon="fa-refresh"
              ui_type="save"
              ui_text="Refresh"
              ui_processing_text="Refreshing.."
              processing={mProcessing}
              disabled={mProcessing}
              onClick={handleListingLeadsRunClick}
            />
          </div>
        </div>
      </div>

      <style>{`
        .modal-xl {
          width: 93%;
          max-width: 1400px;
          margin: 10px auto;
        }
        
        .modal-body {
          padding: 15px;
          max-height: 80vh;
          overflow-y: auto;
        }
        
        .tab-content {
          margin-bottom: 0 !important;
        }
        
        .tab-pane {
          padding: 0 !important;
        }
        
        .row {
          margin-bottom: 0;
        }
        
        .datepicker-popper {
          z-index: 1000 !important;
        }
        
        .react-datepicker {
          font-size: 14px;
        }
        
        .react-datepicker__header {
          background-color: #f8f9fa;
          border-bottom: 1px solid #dee2e6;
        }
        
        .react-datepicker__day--selected {
          background-color: #2196F3;
        }
        
        .react-datepicker__day:hover {
          background-color: #e9ecef;
        }

        .spinner-border {
          display: inline-block;
          width: 2rem;
          height: 2rem;
          vertical-align: text-bottom;
          border: 0.25em solid currentColor;
          border-right-color: transparent;
          border-radius: 50%;
          animation: spinner-border .75s linear infinite;
        }

        @keyframes spinner-border {
          to { transform: rotate(360deg); }
        }

        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0,0,0,0);
          border: 0;
        }
        
        #leadsDetailsTable tbody tr {
          cursor: pointer;
        }
        
        #leadsDetailsTable tbody tr:hover {
          background-color: #f5f5f5;
        }
      `}</style>
    </div>
  );
};

ListingLeadsModal.propTypes = {
  mViewReportFrom: PropTypes.instanceOf(Date),
  mViewReportTo: PropTypes.instanceOf(Date),
  mProcessing: PropTypes.bool.isRequired,
  handleListingLeadsRunClick: PropTypes.func.isRequired,
  setViewReportFrom: PropTypes.func.isRequired,
  setViewReportTo: PropTypes.func.isRequired,
  handleLeadsCloseModal: PropTypes.func.isRequired,
  portalLeadsViews: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      portalUrl: PropTypes.string.isRequired,
      leads: PropTypes.number.isRequired,
      views: PropTypes.number.isRequired,
    })
  ),
  summaryLeadsViews: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      leads: PropTypes.number.isRequired,
      views: PropTypes.number.isRequired,
    })
  ),
  leadsDetails: PropTypes.arrayOf(
    PropTypes.shape({
      leadID: PropTypes.string,
      receivedDate: PropTypes.string,
      messageDetails: PropTypes.string,
      userIDs: PropTypes.array,
      propertyID: PropTypes.string,
      officeID: PropTypes.string,
      source: PropTypes.string,
      note: PropTypes.string,
      contactID: PropTypes.string,
      address: PropTypes.string,
      agents: PropTypes.string,
    })
  ),
  webRef: PropTypes.string,
  officeName: PropTypes.string,
};

export default ListingLeadsModal;