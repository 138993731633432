import "./OMSButtonDropdown.scss";
const OMSDropDownCard = ({ data, onClick, toggleDropdown }) => {
  const handleClick = (type) => {
    onClick(type);
    // toggleDropdown(false);
  };
  return (
    <>
      <div class="dropdown-container">
        <ul className="drop-down">
          {data.map((item, i) => (
            item.isSelected && (
              <li key={i}>
                <a onClick={() => handleClick(item)}>{item.text}</a>
              </li>
            )
          ))}
        </ul>
      </div>
    </>
  );
};

export default OMSDropDownCard;
