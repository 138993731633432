import React from "react";
import { OMSFavourite } from "../../app/common/common-components/OMSFavourite";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
import { AuditTrailBox } from "../contact/AuditTrailBox";
import { OverviewStepView } from "./SavePropertyTabs/OverviewStepView";
import { DiaryStepView } from "./SavePropertyTabs/DiaryStepView";
import { LocationStepView } from "./SavePropertyTabs/LocationStepView";
import { FeaturesStepView } from "./SavePropertyTabs/FeaturesStepView";
import { DescriptionStepView } from "./SavePropertyTabs/DescriptionStepView";
import { MediaStepView } from "./SavePropertyTabs/MediaStepView";
import { MandateStepView } from "./SavePropertyTabs/MandateStepView";
import { ContactsStepView } from "./SavePropertyTabs/ContactsStepView";
import { MarketingStepView } from "./SavePropertyTabs/MarketingStepView";
import ReportStepView from "./SavePropertyTabs/ReportStepView";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from "react-router-dom";
import { isEntegralSuperUser } from "../../app/common/common-functions/functions";

class ListingBox extends React.Component {


  handleIsExternalListingChange = (newValue, callback) => {
    this.setState({ IsExternalListing: newValue }, () => {
      callback();
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      PropertyID: props.data?.propertyID ?? "",
      UserID: props.data?.userID ?? "",
      OfficeID: props.data?.officeID ?? "",
      OfficeSyncID: props.data?.officeSyncID ?? "",
      WebRef: props.data?.webRef ?? "",
      IsUpdate: props.data?.isUpdate ?? false,
      Step: props.data?.step ?? 1,
      CompletedStep: props.data?.step ?? 1,
      PropertyType: props.data?.features?.type ?? "",
      SaleType: props.data?.mandate?.saleType ?? "",
      Status: props.data?.mandate?.status ?? "",
      Mandate_StartDate: props.data?.mandate?.startDate ?? null,
      Mandate_ExpiryDate: props.data?.mandate?.expiryDate ?? null,
      data: props.data ?? {},
      IsShowAuditTrail: props.bool ?? false,
      update: 1,
      IsExternalListing: props.data?.location?.isExternalListing ?? false,
      ButtonStatus: {
        disabled: false,
        processing_save: false,
        processing_delete: false,
      },
      confirmationModalProps: {
        showModal: false,
        isConfirmed: () => { },
        title: "",
        body: "",
      },
      isUploadInProgressMP: false,
      isUploadInProgressMD: false
    };

    this.overviewStepRef = React.createRef();
    this.diaryStepViewRef = React.createRef();
    this.locationStepRef = React.createRef();
    this.featuresStepRef = React.createRef();
    this.descriptionStepRef = React.createRef();
    this.mediaStepRef = React.createRef();
    this.mandateStepRef = React.createRef();
    this.contactsStepRef = React.createRef();
    this.marketingStepRef = React.createRef();
  }
  componentDidMount() {
    this.setState((prev) => ({
      internalListingDefaultTags: this.getInternalDefaultTags(this.props?.ListingTags || [])
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.update < this.state.update) {
      var data = this.state.data;

      this.overviewStepRef.current.doSetState(data);
      this.locationStepRef.current.doSetState(data.location);
      this.featuresStepRef.current.doSetState(data.features);
      this.descriptionStepRef.current.doSetState(data.description);
      this.mandateStepRef.current.doSetState(data.mandate);
      this.contactsStepRef.current.doSetState(data.contacts);
      this.marketingStepRef.current.doSetState(data.marketing);
    }
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  openCloseRestoreModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  mandateStatusChanged = (status) => {
    if (this.props.data.propertyID === "") {
      let contactType = "";
      let data = this.state.data;
      if (status >= 5 && status <= 7) {
        contactType = "selling agent";
      } else {
        contactType = "listing agent";
      }

      if (data.contacts.contacts[0] && data.contacts.contacts.length === 1) {
        data.contacts.contacts[0].contactType = contactType;
      }
      this.setState({ data });
    }
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  showPopupAndRestoreOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          callback();
        }
        this.openCloseRestoreModal(false);
      },
    };
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  onCancelClick = (e) => {
    this.props.history.push("/Property");
  };

  executeDeleteClick = (e) => {
    this.setState({ ButtonStatus: { disabled: true, processing_save: true, processing_delete: true } });
    apiAgent.Property.deletePropertyAjax(this.state.PropertyID, this.props.data.officeSyncID)
      .then((response) => {
        this.props.history.push("/Property");
      })
      .catch((error) => {
        toast.info("Fail");
        this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
      });
  };
  executeRestoreClick = (e) => {
    apiAgent.Property.RestorePropertyAjax(this.state.PropertyID)
      .then((response) => {
        this.props.history.push("/Property");
      })
      .catch((error) => {
        toast.info("Fail");
        this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
      });
  };

  onDeleteClick = (e) => {
    let title = "Delete property?";
    let body = "Do you want to delete property now? This cannot be undone.";
    let callback = this.executeDeleteClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onRestoreClick = (e) => {
    let title = "Restore property?";
    let body = "Do you want to Restore property now? This cannot be undone.";
    let callback = this.executeRestoreClick;
    this.showPopupAndRestoreOnConfirmation(title, body, callback);
  };

  getFormData = async (e) => {
    const selectedTags = [...this.getSelectedTagNames(this.state.internalListingDefaultTags)];
    var data = new FormData();

    var Step = this.state.Step;

    if (Step < this.state.CompletedStep) Step = this.state.CompletedStep;

    if (this.state.IsUpdate) Step = 8;

    data.append("PropertyID", this.state.PropertyID);
    data.append("UserID", this.state.UserID);
    data.append("WebRef", this.state.WebRef);
    data.append("OfficeID", this.state.OfficeID);
    data.append("OfficeSyncID", this.state.OfficeSyncID);
    data.append("Step", Step);
    data.append("internalListingTags", JSON.stringify(selectedTags));

    this.locationStepRef.current.doSetFormData(data);
    this.featuresStepRef.current.doSetFormData(data);
    this.descriptionStepRef.current.doSetFormData(data);
    await this.mediaStepRef.current.doSetFormData(data);
    await this.mandateStepRef.current.doSetFormData(data);
    this.contactsStepRef.current.doSetFormData(data);
    this.marketingStepRef.current.doSetFormData(data);
    return data;
  };


  setUploadPhotoStatus = (isProgress) => {
    this.setState({ isUploadInProgressMP: isProgress });
  }
  setMandateFileStatus = (isProgress) => {
    this.setState({ isUploadInProgressMD: isProgress });
  }


  doSave = async (IsDraft) => {
    var validate = this.doValidateStep(this.state.Step);

    let intervalId = undefined;

    if (validate.status == "success") {
      this.setState({ ButtonStatus: { disabled: true, processing_save: true, processing_delete: false } });
      switch (`${this.state.isUploadInProgressMP}-${this.state.isUploadInProgressMD}`) {
        case 'true-true':
          // Both UploadInProgress and isMandateFileUploading are true
          intervalId = setInterval(() => {
            if (!this.state.isUploadInProgressMP && !this.state.isUploadInProgressMD) {
              //console.log("Save API Call 01");
              this.savePropertyAsnc(IsDraft);
              clearInterval(intervalId);

            }
          }, 1000 * 2);
          break;

        case 'true-false':
          // UploadInProgress is true, but isMandateFileUploading is false
          intervalId = setInterval(() => {
            if (!this.state.isUploadInProgressMP) {
              //console.log("Save API Call 02");
              this.savePropertyAsnc(IsDraft);
              clearInterval(intervalId);
            }
          }, 1000 * 2);

          //console.log("Uploading in progress, but no mandate file is uploading.");
          break;

        case 'false-true':
          // UploadInProgress is false, but isMandateFileUploading is true
          intervalId = setInterval(() => {
            if (!this.state.isUploadInProgressMD) {
              //console.log("Save API Call 03");
              this.savePropertyAsnc(IsDraft);
              clearInterval(intervalId);
            }
          }, 1000 * 2);
          //console.log("Mandate file is uploading but the main upload is not in progress.");
          break;

        case 'false-false':
          // Neither UploadInProgress nor isMandateFileUploading are true
          //console.log("Save API Call 03");
          this.savePropertyAsnc(IsDraft);
          clearInterval(intervalId);
          //console.log("No upload is in progress.");
          break;

        default:
          //console.log("Default.");
      }
    }
    else toast.info(validate.message);
  };

  savePropertyAsnc = async (IsDraft) => {
    var data = await this.getFormData();
    data.append("StrIsDraft", IsDraft);
    this.savePropertyAPI(data, IsDraft);
  }

  savePropertyAPI = (data, IsDraft) => {
    apiAgent.Property.savePropertyAsync2(data)
      .then((response) => {

        this.setState({ isUploadInProgressMD: false });
        this.setState({ isUploadInProgressMP: false });

        if (IsDraft) {
          toast.info("Property data saved successfully");
          var update = this.state.update + 1;
          this.setState({
            update: update,
            data: response.result,
            ButtonStatus: { disabled: false, processing_save: false, processing_delete: false },
            PropertyID: response.result.propertyID,
            UserID: response.result.userID,
            WebRef: response.result.webRef,
          });
        } else this.onCancelClick();
      })
      .catch((error) => {
        toast.info("Fail");
        this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
      });
  }
  doChangeStepClick = (Step) => {
    var CurrentStep = this.state.Step;
    var validate = this.doValidateStep(CurrentStep);
    if (validate.status == "success") this.doChangeStepClick2(Step);
    else toast.info(validate.message);
  };

  doChangeStepClick2 = (Step) => {
    var CurrentStep = this.state.Step;
    var CompletedStep = this.state.CompletedStep;
    if (CurrentStep > CompletedStep) CompletedStep = CurrentStep;
    this.setState({ Step: Step, CompletedStep: CompletedStep });
  };

  doValidateStep = (Step) => {
    if (Step == 1) {
      return this.locationStepRef.current.doCheckFormData();
    } else if (Step == 2) {
      return this.featuresStepRef.current.doCheckFormData();
    } else if (Step == 3) {
      return this.descriptionStepRef.current.doCheckFormData();
    } else if (Step == 4) {
      return this.mediaStepRef.current.doCheckFormData();
    } else if (Step == 5) {
      return this.mandateStepRef.current.doCheckFormData();
    } else if (Step == 6) {
      return this.contactsStepRef.current.doCheckFormData();
    } else if (Step == 7) {
      return this.marketingStepRef.current.doCheckFormData();
    } else {
      return { status: "success", message: "" };
    }
  };

  onDependantPropertyChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleAuditTrailClose = () => {
    this.setState({ IsShowAuditTrail: false });
  };

  handleAuditTrailShow = () => {
    this.setState({ IsShowAuditTrail: true });
  };

  togglePinNote = (noteItem) => {
    if (this.overviewStepRef.current !== undefined && this.overviewStepRef.current != null) this.overviewStepRef.current.togglePinNote(noteItem);
  };

  togglePinNoteFromOverview = () => {
    if (this.diaryStepViewRef.current !== undefined && this.diaryStepViewRef.current != null) this.diaryStepViewRef.current.onDiaryItemSubmitCompleteFunc();
  };
  getInternalDefaultTags = (tags) => {
    var items = [];
    for (const tagElement of tags) {
      var item = {
        name: tagElement,
        isSelected: this.props.data.internalListingTags ? this.props.data.internalListingTags.includes(tagElement) : false
      }
      items.push(item);
    }
    return items;
  }
  getSelectedTagNames = (tags) => {
    return tags
      .filter(tag => tag.isSelected)
      .map(tag => tag.name);
  };
  handleTagClick = (index) => {
    const defaultTagList = [...this.state.internalListingDefaultTags];
    defaultTagList[index].isSelected = !defaultTagList[index].isSelected;
    this.setState({
      internalListingDefaultTags: defaultTagList
    });
  };

  renderStepName = () => {
    if (this.state.IsUpdate) return this.props.data.displayName;
    else {
      if (this.state.Step == 1) return "Listing capture: Location";
      else if (this.state.Step == 2) return "Listing capture: Features";
      else if (this.state.Step == 3) return "Listing capture: Description";
      else if (this.state.Step == 4) return "Listing capture: Media";
      else if (this.state.Step == 5) return "Listing capture: Mandate";
      else if (this.state.Step == 6) return "Listing capture: Contacts";
      else if (this.state.Step == 7) return "Listing capture: Marketing";
      else return "Listing capture";
    }
  };

  render() {
    var super_access = this.props.IsSuperAdmin;
    return (
      <form className="SavePropertyForm" onSubmit={this.handleSubmit}>
        <div>
          <ConfirmationModalPopup {...this.state.confirmationModalProps} />
          <div className="col-sm-12">
            <div className={this.state.IsUpdate ? "panel panel-default oms-box color2 color2-border-right" : "panel panel-default oms-box"}>
              <div className="wrap">
                <div className="left">
                  <div className="panel-heading">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-xs-12 mt-1">
                            <OMSFavourite favourite={this.props.favourite} /> &nbsp;&nbsp;&nbsp;&nbsp; {this.renderStepName()} &nbsp;{" "}
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12 mt-1">
                            <div className="tag-list-container">
                              {this.state.internalListingDefaultTags &&
                                <div className="tag-list">
                                  <span
                                    data-toggle="modal"
                                    className="listingTag"
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "transparent",
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                    }}
                                    data-target="#select-listing-tag"
                                    title="Listing Tags"
                                  >
                                    <i className="fa fa-tags"
                                      style={{ fontSize: 15, color: '#333000' }} aria-hidden="true"></i>
                                  </span>
                                      {this.state.internalListingDefaultTags.map((tagElement, index) => {
                                        return (
                                          <>
                                            {
                                              tagElement.isSelected &&
                                              <span
                                                key={`internal-listing-tags-${index}`}
                                                className="listingTag"
                                              >
                                                {tagElement.name}
                                              </span>
                                            }
                                          </>)
                                      })
                                  }
                                </div>
                              }
                            </div>

                          </div>
                        </div>
                        <div className="property-header-container">
                          {this.props.data.isDeleted && <span className="property_deleted_tag">Deleted</span>}                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body" style={{ backgroundColor: "white", minHeight: 680 }}>
                    <div className="col-md-12">
                      <div id="overview" style={{ display: this.state.Step == 0 ? "" : "none" }}>
                        {this.state.data.propertyID && (
                          <OverviewStepView
                            ref={this.overviewStepRef}
                            togglePinNoteFromOverview={this.togglePinNoteFromOverview}
                            CurrencyDisplay={this.props.CurrencyDisplay}
                            data={this.state.data}
                            super_access={super_access}
                          />
                        )}
                      </div>
                      <div id="diary" style={{ display: this.state.Step == 8 ? "" : "none" }}>
                        {this.state.data.propertyID && (
                          <DiaryStepView
                            ref={this.diaryStepViewRef}
                            togglePinNote={this.togglePinNote}
                            super_access={super_access}
                            actions={this.props.actions}
                            PropertyID={this.state.data.propertyID}
                            UserID={this.state.data.userID}
                            DiaryActionTags={this.props.DiaryActionTags}
                            OnShowStart={this.props.OnShowStart}
                            OnShowEnd={this.props.OnShowEnd}
                          />
                        )}
                      </div>
                      <div id="location" className={this.state.Step == 1 ? "tab1" : "tab1_hidden"}>
                        <LocationStepView
                          ref={this.locationStepRef}
                          id="locationStep"
                          data={this.state.data.location}
                          loadMap={this.state.Step == 1}
                          CountryDD={this.props.CountryDD}
                          IsExternalListing={this.state.IsExternalListing}
                          handleIsExternalListingChange={this.handleIsExternalListingChange}
                        />
                      </div>
                      <div id="features" style={{ display: this.state.Step == 2 ? "" : "none" }}>
                        <FeaturesStepView
                          onDependantPropertyChange={this.onDependantPropertyChange}
                          super_access={super_access}
                          ref={this.featuresStepRef}
                          id="featuresStep"
                          data={this.props.data.features}
                          OwnershipTypes={this.props.OwnershipTypes}
                          PropertyTypes={this.props.PropertyTypes}
                        />
                      </div>
                      <div id="description" style={{ display: this.state.Step == 3 ? "" : "none" }}>
                        <DescriptionStepView
                          ref={this.descriptionStepRef}
                          id="descriptionStep"
                          super_access={super_access}
                          data={this.state.data.description}
                          ExtraDescriptionTagFields={this.props.ExtraDescriptionTagFields}
                          ExtraDescriptionFieldsDD={this.props.ExtraDescriptionFieldsDD}
                          GeneralListingTags={this.props.GeneralListingTags}
                          IsExternalListing={this.state.IsExternalListing}
                        />
                      </div>
                      <div id="media" style={{ display: this.state.Step == 4 ? "" : "none" }}>
                        <MediaStepView
                          ref={this.mediaStepRef}
                          id="mediaStep"
                          super_access={super_access}
                          data={this.state.data.media}
                          officeSyncID={this.state.data.officeSyncID}
                          doPhotoUploadProgressChange={this.setUploadPhotoStatus}
                        />
                      </div>
                      <div id="mandate" style={{ display: this.state.Step == 5 ? "" : "none" }}>
                        <MandateStepView
                          onDependantPropertyChange={this.onDependantPropertyChange}
                          super_access={super_access}
                          ref={this.mandateStepRef}
                          id="mandateStep"
                          data={this.state.data.mandate}
                          save_access={this.props.actions.indexOf("save") >= 0}
                          MandateTypes={this.props.MandateTypes}
                          officeSyncID={this.state.data.officeSyncID}
                          officeID={this.props.data.officeID}
                          CurrencyDisplay={this.props.CurrencyDisplay}
                          userSession={this.props.userSession}
                          mandateStatusChanged={this.mandateStatusChanged}
                          doFileDocumentUploadProgress={this.setMandateFileStatus}

                        />
                      </div>
                      <div id="contacts" style={{ display: this.state.Step == 6 ? "" : "none" }}>
                        <ContactsStepView
                          ref={this.contactsStepRef}
                          id="contactsStep"
                          super_access={super_access}
                          officeid={this.state.data.officeID}
                          data={this.state.data.contacts}
                          UserID={this.state.data.userID}
                          OfficeContactTags={this.props.OfficeContactTags}
                          UserContactTags={this.props.UserContactTags}
                          AgentTypes={this.props.AgentTypes}
                          ContactSourceTypes={this.props.ContactSourceTypes}
                          DialingCode={this.props.DialingCode}
                          DialingCodeDD={this.props.DialingCodeDD}
                          ListingSellerRequired={this.props.ListingSellerRequired}
                          IsExternalListing={this.state.IsExternalListing}
                        />
                      </div>
                      <div id="marketing" style={{ display: this.state.Step == 7 ? "" : "none" }}>
                        <MarketingStepView
                          ref={this.marketingStepRef}
                          id="marketingStep"
                          data={this.state.data.marketing}
                          PropertyType={this.state.PropertyType}
                          SaleType={this.state.SaleType}
                          Status={this.state.Status}
                          isDeleted={this.props.data.isDeleted}
                        />
                      </div>
                      <div id="reports" style={{ display: this.state.Step == 9 ? "" : "none" }}>
                        {this.state.data.propertyID && (
                          <ReportStepView
                            ref={this.reportStepRef}
                            id="reportStep"
                            ShowChart={this.props.ShowChart}
                            WebRef={this.state.WebRef}
                            super_access={super_access}
                            SelectedAgentID={this.props.SelectedAgentID}
                            AgentDD={this.props.AgentDD}
                            PropertyID={this.state.data.propertyID}
                            UserID={this.state.data.userID}
                            Brochures={this.props.Brochures}
                            Mandate_ExpiryDate={this.state.Mandate_ExpiryDate}
                            Mandate_StartDate={this.state.Mandate_StartDate}
                            userSession={this.props.userSession}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="panel-footer">
                    <div className="row">
                      {this.state.IsUpdate ? (
                        <div className="col-md-12">
                          <div className="pull-left">
                            {this.props.actions.indexOf("save") < 0 ? null : (
                              <div className="dropup">
                                <button className="btn btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                                  More..
                                  <span className="caret" />
                                </button>
                                <ul className="dropdown-menu">
                                  {this.props.data.isDeleted ? (
                                    <li key="Restore">
                                      <OMSButtonStyleL
                                        style={{ marginRight: 10 }}
                                        type="button"
                                        ui_icon="fa-trash-o"
                                        ui_type="cancel"
                                        ui_text="Restore property"
                                        ui_processing_text="Restore.."
                                        processing={this.state.ButtonStatus.processing_delete}
                                        disabled={this.state.ButtonStatus.disabled}
                                        onClick={this.onRestoreClick}
                                      />
                                    </li>
                                  ) : (
                                    <li key="delete">
                                      <OMSButtonStyleL
                                        style={{ marginRight: 10 }}
                                        type="button"
                                        ui_icon="fa-trash-o"
                                        ui_type="cancel"
                                        ui_text="Delete property"
                                        ui_processing_text="Deleting.."
                                        processing={this.state.ButtonStatus.processing_delete}
                                        disabled={this.state.ButtonStatus.disabled}
                                        onClick={this.onDeleteClick}
                                      />
                                    </li>
                                  )}
                                  <li key="history">
                                    <OMSButtonStyleL
                                      style={{ marginRight: 10 }}
                                      type="button"
                                      ui_icon="fa-history"
                                      ui_type="cancel"
                                      ui_text="History"
                                      ui_processing_text=""
                                      processing={false}
                                      disabled={this.state.ButtonStatus.disabled}
                                      onClick={this.handleAuditTrailShow}
                                    />
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="pull-right visible-xs">
                            <OMSButtonStyleL
                              style={{ marginRight: 10 }}
                              type="button"
                              ui_icon="fa-times"
                              ui_type="cancel"
                              ui_text="Cancel"
                              ui_processing_text=""
                              processing={false}
                              disabled={this.state.ButtonStatus.disabled}
                              onClick={this.onCancelClick}
                            />
                          </div>
                          {this.props.actions.indexOf("save") < 0 ? null : (
                            <div className="pull-right">
                              <OMSButtonStyleL
                                style={{ marginRight: 10 }}
                                type="button"
                                ui_icon="fa-check"
                                ui_type="save"
                                ui_text="Save"
                                ui_processing_text="Saving.."
                                processing={this.state.ButtonStatus.processing_save}
                                disabled={this.state.ButtonStatus.disabled}
                                onClick={() => this.doSave(true)}
                              />
                              <OMSButtonStyleL
                                type="button"
                                ui_icon="fa-check"
                                ui_type="save"
                                ui_text="Save & Close"
                                ui_processing_text="Saving.."
                                processing={this.state.ButtonStatus.processing_save}
                                disabled={this.state.ButtonStatus.disabled}
                                onClick={() => this.doSave(false)}
                              />
                            </div>
                          )}
                          <div className="pull-right hidden-xs">
                            <OMSButtonStyleL
                              style={{ marginRight: 10 }}
                              type="button"
                              ui_icon="fa-times"
                              ui_type="cancel"
                              ui_text="Cancel"
                              ui_processing_text=""
                              processing={false}
                              disabled={this.state.ButtonStatus.disabled}
                              onClick={this.onCancelClick}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <div className="col-md-4">
                            {this.state.Step == 7 ? (
                              this.state.ButtonStatus.disabled ? (
                                <div style={{ padding: "6px 12px" }}>
                                  <span style={{ color: "#337ab7", cursor: "pointer" }}>Cancel</span>
                                </div>
                              ) : (
                                <div style={{ padding: "6px 12px" }}>
                                  <a onClick={this.onCancelClick} style={{ color: "#337ab7", cursor: "pointer" }}>
                                    Cancel
                                  </a>
                                </div>
                              )
                            ) : this.state.ButtonStatus.disabled ? (
                              <div style={{ padding: "6px 12px" }}>
                                <span style={{ color: "#337ab7", cursor: "pointer" }}>Save draft</span> &nbsp; | &nbsp;
                                <span style={{ color: "#337ab7", cursor: "pointer" }}>Cancel</span>
                              </div>
                            ) : (
                              <div style={{ padding: "6px 12px" }}>
                                <a onClick={() => this.doSave(true)} style={{ color: "#337ab7", cursor: "pointer" }}>
                                  Save draft
                                </a>{" "}
                                &nbsp; | &nbsp;
                                <a onClick={this.onCancelClick} style={{ color: "#337ab7", cursor: "pointer" }}>
                                  Cancel
                                </a>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label style={{ padding: "6px 12px" }}>Step {this.state.Step} of {this.state.IsExternalListing ? 6 : 7}  </label>
                          </div>
                          <div className="col-md-4">
                            <div className="pull-right">
                              {this.state.Step === 7 || (this.state.IsExternalListing && this.state.Step === 6) ? (
                                <OMSButtonStyleL
                                  type="button"
                                  onClick={() => this.doSave(false)}
                                  ui_icon="fa-check"
                                  ui_type="save"
                                  ui_text={"Save & Activate"}
                                  ui_processing_text="Saving.."
                                  processing={this.state.ButtonStatus.processing_save}
                                  disabled={this.state.ButtonStatus.disabled}
                                />
                              ) : (
                                <div>
                                  {this.state.Step <= 1 ? null : (
                                    <OMSButtonStyleL
                                      type="button"
                                      onClick={() => this.doChangeStepClick2(this.state.Step - 1)}
                                      ui_icon="fa-arrow-left"
                                      ui_type="save"
                                      ui_text="Previous"
                                      ui_processing_text="Saving.."
                                      style={{ marginRight: 5 }}
                                      processing={this.state.ButtonStatus.processing_save}
                                      disabled={this.state.ButtonStatus.disabled}
                                    />
                                  )}
                                  <OMSButtonStyleL
                                    type="button"
                                    onClick={() => this.doChangeStepClick(this.state.Step + 1)}
                                    ui_icon="fa-arrow-right"
                                    ui_type="save"
                                    ui_text="Next"
                                    ui_processing_text="Saving.."
                                    processing={this.state.ButtonStatus.processing_save}
                                    disabled={this.state.ButtonStatus.disabled}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.IsUpdate == false ? null : (
                  <div className="right color2 hidden-xs hidden-sm">
                    <div className="row">
                      <div className="col-md-12">
                        <a
                          className={this.state.Step == 0 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(0)}
                        >
                          <span>
                            <i className="fa fa-tachometer fa-2x"></i>
                            <br />
                            Overview
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 8 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(8)}
                        >
                          <span>
                            <i className="fa fa-clock-o fa-2x"></i>
                            <br />
                            Diary
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 5 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(5)}
                        >
                          <span>
                            <i className="fa fa-key fa-2x"></i>
                            <br />
                            Mandate
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 6 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(6)}
                        >
                          <span>
                            <i className="fa fa-address-book fa-2x"></i>
                            <br />
                            Contact
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 1 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(1)}
                        >
                          <span>
                            <i className="fa fa-map-marker fa-2x"></i>
                            <br />
                            Location
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 2 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(2)}
                        >
                          <span>
                            <i className="fa fa-bed fa-2x"></i>
                            <br />
                            Features
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 3 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(3)}
                        >
                          <span>
                            <i className="fa fa-file-text-o fa-2x"></i>
                            <br />
                            Desc
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 4 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(4)}
                        >
                          <span>
                            <i className="fa fa-camera fa-2x"></i>
                            <br />
                            Media
                          </span>
                        </a>
                        {!this.state.IsExternalListing && (
                          <a
                            className={this.state.Step == 7 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => this.doChangeStepClick(7)}
                          >
                            <span>
                              <i className="fa fa-share-alt fa-2x"></i>
                              <br />
                              Feeds
                            </span>
                          </a>
                        )}
                        <a
                          className={this.state.Step == 9 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(9)}
                        >
                          <span>
                            <i className="fa fa-print fa-2x"></i>
                            <br />
                            Reports
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.IsUpdate == false ? null : (
                  <div className="rightS color2 hidden-md hidden-lg">
                    <div className="row">
                      <div className="col-md-12">
                        {/*<a className="btn text-center user2">
                                                        <span className="vertical-text">more..</span>
                                                    </a>*/}
                        <a
                          className={this.state.Step == 0 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(0)}
                        >
                          <i className="fa fa-tachometer fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 8 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(8)}
                        >
                          <i className="fa fa-clock-o fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 5 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(5)}
                        >
                          <i className="fa fa-key fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 6 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(6)}
                        >
                          <i className="fa fa-address-book fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 1 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(1)}
                        >
                          <i className="fa fa-map-marker fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 2 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(2)}
                        >
                          <i className="fa fa-bed fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 3 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(3)}
                        >
                          <i className="fa fa-file-text-o fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 4 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(4)}
                        >
                          <i className="fa fa-camera fa-2x"></i>
                        </a>
                        {!this.state.IsExternalListing && (
                          <a
                            className={this.state.Step == 7 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => this.doChangeStepClick(7)}
                          >
                            <i className="fa fa-share-alt fa-2x"></i>
                          </a>
                        )}
                        <a
                          className={this.state.Step == 9 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(9)}
                        >
                          <i className="fa fa-print fa-2x"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.IsShowAuditTrail === true && (
            <AuditTrailBox
              id="auditProperty"
              objectID={this.state.PropertyID}
              officeID={this.state.OfficeID}
              onClose={this.handleAuditTrailClose}
            ></AuditTrailBox>
          )}
        </div>

        <div className="modal fade" id="select-listing-tag" tabIndex={-1} aria-labelledby="selectTagModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.handleSendData}>
                  &times;
                </button>
                <h4 className="modal-title" id="selectTagModelLabel" style={{ display: "contents" }}>
                  Internal Tags
                </h4>
                &nbsp;
                <a href="https://help.entegral.net/en/articles/10026006-internal-listing-and-deal-tags" target="_blank">
                  <i
                    className="fa fa-question-circle"
                    title="Help"
                    style={{ cursor: "pointer" }}
                    aria-hidden="true"
                  ></i>
                </a>

              </div>
              <div className="modal-body">
                {
                  this.state.internalListingDefaultTags &&
                  <>
                    <div className="internal-listing-tags">
                      {this.state.internalListingDefaultTags.map((tagElement, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => this.handleTagClick(index)}
                            className={tagElement.isSelected ? "internal-tags-item active" : "internal-tags-item"}
                          >

                            {tagElement.name}
                          </span>
                        )
                      })}
                    </div>
                  </>
                }


              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>

              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(ListingBox);
