import React from "react";
import SuburbAutosuggest from "./SuburbAutosuggest";
import PropertyReportsStore from "../../../../app/stores/propertyReportsStore";

interface PropertySearchControlsProps {
  searchParams: {
    provinceId: string;
    suburb: string;
    street: string;
    streetNumber: string;
    unitNumber: string;
    sectionalTitle: string;
  };
  onSearchParamsChange: (key: string, value: string) => void;
  propertyReportsStore: PropertyReportsStore;
  selectedSearchType: string;
}

const PropertySearchControls: React.FC<PropertySearchControlsProps> = ({
  searchParams,
  onSearchParamsChange,
  propertyReportsStore,
  selectedSearchType
}) => {
  const provinces = [
    {
      provinceId: 1,
      province: "Eastern Cape",
      capital: "Bisho",
    },
    {
      provinceId: 2,
      province: "Free State",
      capital: "Bloemfontein",
    },
    {
      provinceId: 3,
      province: "Gauteng",
      capital: "Johannesburg",
    },
    {
      provinceId: 4,
      province: "Kwazulu-Natal",
      capital: "Pietermaritzburg",
    },
    {
      provinceId: 5,
      province: "Limpopo",
      capital: "Polokwane",
    },
    {
      provinceId: 6,
      province: "Mpumalanga",
      capital: "Nelspruit",
    },
    {
      provinceId: 7,
      province: "North-West",
      capital: "Mafikeng",
    },
    {
      provinceId: 8,
      province: "Northern Cape",
      capital: "Kimberley",
    },
    {
      provinceId: 9,
      province: "Western Cape",
      capital: "Cape Town",
    },
  ];

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="province">Province</label>
          <select
            className="form-control"
            id="province"
            value={searchParams.provinceId}
            onChange={(e) => onSearchParamsChange("provinceId", e.target.value)}
          >
            {provinces.map((province) => (
              <option key={province.provinceId} value={province.provinceId}>
                {province.province}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="suburb">Area {selectedSearchType !== "complex" && "*"}</label>
          <SuburbAutosuggest
            className={`form-control ${selectedSearchType !== "complex" ? "required-control" : ""}`}
            value={searchParams.suburb}
            onChange={(value) => onSearchParamsChange("suburb", value)}
            provinceId={searchParams.provinceId}
            provinceSuburbsMap={propertyReportsStore.provinceSuburbsMap}
          />
        </div>
      </div>
      {selectedSearchType === "complex" && (
        <div className="row">
          <div className="form-group col-md-4">
            <label htmlFor="sectionalTitle">Name of Complex / Estate / Sectional Title *</label>
            <input
              type="text"
              className="form-control required-control"
              id="sectionalTitle"
              placeholder="Complex Name"
              value={searchParams.sectionalTitle}
              onChange={(e) => onSearchParamsChange("sectionalTitle", e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="street">Street Name (Exclude type: street, road, avenue, close, etc.){selectedSearchType !== "complex" && " *"}</label>
          <input
            type="text"
            className={`form-control ${selectedSearchType === "propertySearch" ? "required-control" : ""}`}
            id="street"
            placeholder="Street Name"
            value={searchParams.street}
            onChange={(e) => onSearchParamsChange("street", e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="streetNumber">Street Number</label>
          <input
            type="text"
            className="form-control"
            id="streetNumber"
            placeholder="Street Number"
            value={searchParams.streetNumber}
            onChange={(e) => onSearchParamsChange("streetNumber", e.target.value)}
          />
        </div>
      </div>
      {selectedSearchType === "complex" && (
        <div className="row">
          <div className="form-group col-md-4">
            <label htmlFor="unitNumber">Unit Number</label>
            <input
              type="text"
              className="form-control"
              id="unitNumber"
              placeholder="Unit Number"
              value={searchParams.unitNumber}
              onChange={(e) => onSearchParamsChange("unitNumber", e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertySearchControls;